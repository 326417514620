export const waitlistConfig = [
  {
    title: "waitlists.list_0_title",
    content: [
      "waitlists.list_0_content_0"
    ]
  },
  {
    title: "waitlists.list_1_title",
    content: [
      "waitlists.list_1_content_0"
    ]
  },
  {
    title: "waitlists.list_2_title",
    content: [
      "waitlists.list_2_content_0",
      "waitlists.list_2_content_1"
    ]
  },
  {
    title: "waitlists.list_3_title",
    content: [
      "waitlists.list_3_content_0"
    ]
  },
  {
    title: "waitlists.list_4_title",
    content: [
      "waitlists.list_4_content_0"
    ]
  },
  {
    title: "waitlists.list_5_title",
    content: [
      "waitlists.list_5_content_0",
      "waitlists.list_5_content_1"
    ]
  },
  {
    title: "waitlists.list_6_title",
    content: [
      "waitlists.list_6_content_0",
      "waitlists.list_6_content_1"
    ]
  },
  {
    title: "waitlists.list_7_title",
    content: [
      "waitlists.list_7_content_0"
    ]
  },
  {
    title: "waitlists.list_8_title",
    content: [
      "waitlists.list_8_content_0"
    ]
  },
  {
    title: "waitlists.list_9_title",
    content: [
      "waitlists.list_9_content_0"
    ]
  },
  {
    title: "waitlists.list_10_title",
    content: [
      "waitlists.list_10_content_0"
    ]
  },
  {
    title: "waitlists.list_11_title",
    content: [
      "waitlists.list_11_content_0",
      "waitlists.list_11_content_1",
      "waitlists.list_11_content_2"
    ]
  }
];
