import React, { Component } from 'react';
import { Translation } from 'react-i18next';
import styles from './style.module.scss';


interface HelpSectionProps {
  row: number,
  title: string,
  content: Array<string>
}

class HelpSection extends Component<HelpSectionProps> {
  render() {
    return (
      <Translation>
        {t => (
          <div className={styles.HelpSection} id={'q' + this.props.row}>
            <a href={window.location.pathname + '#top'} className={styles.btt}>{t('link_top')}</a>
            <h2>{t(this.props.title)}</h2>
            {
              this.props.content && this.props.content.map((p, i) => {
                return (<p key={i} dangerouslySetInnerHTML={{ __html: t(p) }}></p>);
              })
            }
          </div>
        )}
      </Translation>
    );
  }
}

export default HelpSection;
