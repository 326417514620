import data from './address.json';
import { userNavItemMapping } from './constants';

export const getLastURLPath = (url: string) => url.substring(url.lastIndexOf('/') + 1);

export const createNavItem = ({ url, title, org_id, count, appKey, t }) => {
  let prefix = t(`common:switcher_menu.${appKey}`);
  // fallback for non translated menu items
  if (prefix === `switcher_menu.${appKey}`) {
    prefix = userNavItemMapping[appKey]
      ? userNavItemMapping[appKey]
      : appKey.charAt(0).toUpperCase() + appKey.slice(1);
  }
  return {
    name: count > 1 ? `${prefix}: ${title}` : title,
    url: `https://${url}`,
    org_id
  };
};

export const getAddressLabelsByCountry = (field: string, countryCode?: string) => {
  const code: string = Boolean(countryCode) ? countryCode.toLowerCase() : '';
  if (data[code] && data[code].labels) {
    return data[code].labels[field] || field;
  }
  return data.default.labels[field] || field;
};

export const addressFormatter = (countryCode?: string) => {
  const code: string = Boolean(countryCode) ? countryCode.toLowerCase() : '';
  if (code && data[code]) {
    return data[code].address;
  }
  return data.default.address;
};

export const checkIfAdminAccount = (url: string) => {
  if (!url) {
    return false;
  }
  return url.toLowerCase().startsWith('/r/admin/account');
};
