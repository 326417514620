import React, { FC, ComponentClass, ReactNode, ReactElement } from 'react';
import { Input, FieldContainer, FieldRow, LabelText } from './styled';
import { useField } from '../hooks';

export interface FieldProps {
  name: string;
  label: string | ReactElement;
  children?: ReactNode;
  disabled?: boolean;
  isDisabled?: boolean;
  type?: string;
  inputClass?: string;
  labelClass?: string;
  as?: FC | ComponentClass;
  onChange?: any;
}

export function Field(props: FieldProps) {
  const {
    name,
    label,
    as: Component = Input,
    type = 'text',
    inputClass = '',
    labelClass = '',
    ...rest
  } = props;
  const { input } = useField({
    name,
    type
  });

  return (
    <FieldContainer>
      <FieldRow className={labelClass}>
        <LabelText>{label}</LabelText>
        <Component {...input} {...rest} type={type} className={inputClass}/>
      </FieldRow>
    </FieldContainer>
  );
}
