import { of, timer } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { concatMap, catchError, first } from 'rxjs/operators';

interface Response {
  Items: any[];
}

export function pollMetadata(url: string, timeStamp?: number) {
  return timer(0, 1000).pipe(
    concatMap(() =>
      ajax.getJSON<Response>(url).pipe(catchError(() => of(null)))
    ),
    first(
      value =>
        value !== null &&
        value.Items.length !== 0 &&
        value.Items[0].registeredAt !== timeStamp
    )
  );
}

export function formatNumber(number: any, locale: string) {
  return new Intl.NumberFormat(locale).format(parseFloat(number));
}
