import React from 'react';
import PaymentMethods from '../../organizations/payment-methods/PaymentMethods';
import styled from 'styled-components';
import Page from '../../components/Page';

const Container = styled.div`
  margin: 24px;
`;

const PaymentMethodsPage: React.FC = () => {
  return (
    <Page>
      <Container>
        <PaymentMethods />
      </Container>
    </Page>
  );
};

export default PaymentMethodsPage;
