import React, { useState, createContext } from 'react';

const RouteLayoutContext = createContext({
  showAppNavBar: false,
  updateShowAppNavBar: (navBarValue: boolean) => {}
});

const RouteLayoutContextProvider = function(props) {
  const [showAppNavBar, updateShowAppNavBar] = useState(false);

  return (
    <RouteLayoutContext.Provider
      value={{
        showAppNavBar: showAppNavBar,
        updateShowAppNavBar: updateShowAppNavBar
      }}
    >
      {props.children}
    </RouteLayoutContext.Provider>
  );
};

export { RouteLayoutContext, RouteLayoutContextProvider };
