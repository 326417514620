import {
  KitModal,
  KitForm,
  KitButton,
  KitButtonBar,
  KitModalSize,
} from '@chargepoint/cp-toolkit';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AdminReceiptPagelet from '../../receipts/AdminReceiptPagelet';

const StyledKitFormGroup = styled(KitForm.Group)`
  padding: 16px;
`

const DeveloperReceiptWrapper = () => {
  const [vehicleChargeId, setVehicleChargeId] = useState('');
  const [showReceipt, setShowReceipt] = useState(false);
  const { t } = useTranslation();
  return (
    <div>
      <p>Receipt based on a Vehicle Charge ID</p>
      <StyledKitFormGroup>
      <KitForm.Input
        id="vehicleChargeID"
        label="Vehicle Charge ID"
        value={vehicleChargeId}
        onChange={(e) => setVehicleChargeId(e.target.value)}
      />
      </StyledKitFormGroup>
      <KitButtonBar
        primary={
          <KitButton onClick={() => setShowReceipt(true)}>
            Show Receipt
          </KitButton>
        }
        secondary={''}
      />
      <KitModal
        show={showReceipt}
        onHide={() => setShowReceipt(false)}
        size={KitModalSize.md}
        closeOnClickOutside
      >
        <KitModal.Header closeButton t={t}>
          <KitModal.Title>Receipt</KitModal.Title>
        </KitModal.Header>
        <KitModal.Body>
          <AdminReceiptPagelet vehicleChargeId={vehicleChargeId} />
        </KitModal.Body>
      </KitModal>
    </div>
  );
};

export default DeveloperReceiptWrapper;
