import styled from 'styled-components';
import { ThemeColors} from '@chargepoint/cp-toolkit';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 50%;

  & p:first-child {
    color: ${ThemeColors.gray_50};
    margin: 0;
  }

  & p {
    padding-bottom: 5px;
  }
`;
