import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ThemeConstants, ThemeColors } from '@chargepoint/cp-toolkit';
import { SessionContext } from '@chargepoint/common/hooks/SessionContext';

const GET_SELECTED_NODES = 'getSelectedNodes';

const GlobalFilterWrapper = styled.div`
  padding-right: ${ThemeConstants.spacing.m}rem;
  margin-right: 0 !important;
`;
const Text = styled.div`
  color: ${({ theme }) => theme.page.body.text};
`;
const BlueText = styled.div`
  color: ${ThemeColors.blue_gray_50};
  cursor: pointer;
  white-space: nowrap;
`;

const GlobalFilter: React.FC<any> = () => {
  const { t } = useTranslation();
  const { session } = useContext(SessionContext);
  const [filterState, setFilterState] = useState('');
  const init = () => {
    document.addEventListener('global-filter-reload', handleFilterReload);
    setFilterState(t('globalFiler.allStations'));
    handleFilterReload();

    return () => {
      window.removeEventListener('global-filter-reload', handleFilterReload);
    };
  };

  useEffect(init, []);

  useEffect(handleFilterReload, [session.prefLangLocale]);

  function handleFilterReload() {
    let filterData;
    try {
      filterData = JSON.parse(window.sessionStorage.getItem(GET_SELECTED_NODES));
    } catch (e) {
      filterData = null;
    }

    let selections = [];
    if (filterData && filterData.content && filterData.content.select) {
      selections = filterData.content.select;
    }

    if (selections.length === 0) {
      setFilterState(t('globalFiler.allStations'));
      return;
    }

    const devices = [];
    const groupTypes = [];
    const groups = [];
    const organisations = [];
    selections.forEach((selection: string) => {
      if (selection.indexOf('_d') !== -1) {
        devices.push(selection);
      } else if (
        selection.indexOf('_gt') !== -1 ||
        selection.indexOf('_acg') !== -1 ||
        selection.indexOf('_arg') !== -1
      ) {
        if (selection.indexOf('_cg') !== -1) {
          groups.push(selection);
        } else {
          groupTypes.push(selection);
        }
      } else {
        organisations.push(selection);
      }
    });

    const resultArr = [];
    if (organisations.length > 0) {
      resultArr.push(parseSelection(organisations, t('globalFiler.organization')));
    }
    if (groupTypes.length > 0) {
      resultArr.push(parseSelection(groupTypes, t('globalFiler.groupType')));
    }
    if (groups.length > 0) {
      resultArr.push(parseSelection(groups, t('globalFiler.group')));
    }
    if (devices.length > 0) {
      resultArr.push(parseSelection(devices, t('globalFiler.station')));
    }

    setFilterState(resultArr.join(', '));
  }

  function parseSelection(dataArr: Array<any>, identifier: string, addKey = false) {
    if (addKey) {
      let resultStr = '';
      dataArr.forEach(data => {
        if (typeof data === 'string') {
          resultStr += `${identifier}:${data};`;
        } else {
          resultStr += `${identifier}:${data.key};`;
        }
      });
      return resultStr;
    }

    switch (dataArr.length) {
      case 0:
        return null;
      default:
        if (dataArr.length > 1) {
          return `${dataArr.length} ${identifier}s`;
        }
        return `${dataArr.length} ${identifier}`;
    }
  }

  function openGlobalFilter() {
    document.dispatchEvent(new CustomEvent('react-open-global-filter'));
  }

  return (
    <GlobalFilterWrapper>
      <Text>{t('select')}</Text>
      <BlueText onClick={openGlobalFilter}>{filterState}</BlueText>
    </GlobalFilterWrapper>
  );
};

export default GlobalFilter;
