import { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  CPLogo,
  KitButton,
  KitFlexCol,
  KitFlexRowCentered,
  ThemeConstants as constants,
  KitUtilCommon,
} from '@chargepoint/cp-toolkit';


export const AnonymousContainer = styled(KitFlexRowCentered)`
  margin: ${constants.spacing.absolute.l}px;
`;

const Container = styled(KitFlexCol)`
  max-width: 500px;
  @media all and (max-width: ${constants.breakpoints.md}px) {
    min-width: 300px;
  }
`;

const Logo = styled(CPLogo)`
  margin-bottom: ${constants.spacing.absolute.m}px;
`;

const Banner = styled.img`
  margin-bottom: ${constants.spacing.absolute.m}px;
  width: 100%;
`;

const Paragraph = styled.p`
  padding: ${constants.spacing.absolute.m}px;
`;

export const getDomain = (): string => {
  return 'ev-chargepoint.com';
};

export const getSSOUrl = (): string => {
  const ssoDomain = 'sso';
  return `https://${ssoDomain}.${getDomain()}`;
};

export const getSSOLoginUrl = (): string => {
  const encodedRedirect = encodeURIComponent(window.location.href);
  return `${getSSOUrl()}/?redirect=${encodedRedirect}&migrate=true`;
};

export interface AnonymousBlurbProps {
  className?: string;
}

const AnonymousBlurb: FC<AnonymousBlurbProps> = ({ className }: AnonymousBlurbProps) => {
  const { t } = useTranslation();

  const onLogIn = () => {
    window.location.href = getSSOLoginUrl();
  };

  const onKeyDown = (e: unknown) => {
    if (KitUtilCommon.isKey(['ENTER', 'SPACE'], e as KeyboardEvent)) {
      onLogIn();
    }
  };

  return (
    <Container className={className}>
      <KitFlexRowCentered>
        <Logo width='176px' t={t} />
      </KitFlexRowCentered>
      <Banner src={`https://${process.env.REACT_APP_DOMAIN}/images/sso-happy-car-skyline.svg`} alt='' />
      <Paragraph>Login to view components</Paragraph>
      <KitFlexRowCentered>
        <KitButton as='a' role='link' tabIndex={0} onClick={onLogIn} onKeyDown={onKeyDown}>
          Log in
        </KitButton>
      </KitFlexRowCentered>
    </Container>
  );
};

export default AnonymousBlurb;
