import { FlexFormIntent } from '../../Main/main';

export interface IntroProps {
  isWelcome: boolean;
  isNoc?: boolean;
  canEditPrice: boolean;
  postPaidPlanActive: boolean;
  deviceGroupId?: string;
  activateFlexBilling: ActivateFlexBilling;
  holdMyFeesPolicyUrl: string;
  standaloneFlexBillingTermsUrl: string;
  feeOffsetPolicyUrl: string;
  formIntent: FlexFormIntent
  onChange: Function;
  resetForm: Function;
}

export enum FlexBillingOption {
  HOLD_MY_FEES = 'hold_my_fees',
  MAIL_A_CHECK = 'check',
  OPT_OUT = 'opt-out',
  NOW = 'achWire'
}

export interface ActivateFlexBilling {
  setUpFlexLater: boolean;
  isActivate: boolean;
  canEditVendorId: boolean;
  orgName: string;
  cpOrgId: string;
  companyOrganizationId: string;
  groupName: string;
  remittanceId: string;
  oldRemittanceId: string;
  fixedFee: string | null;
  oldFixedFee: string | null;
  variableFee: string | null;
  oldVariableFee: string | null;
  description: string | null;
  paymentOption: FlexBillingOption;
  optOutReason: string | null;
  applyDues?: boolean;
}
