import {FlxBillDetailsBackendObj} from './FlexbillingDetails';
import {FlexFormIntent, MainState} from './main';
import {flexbillingCacheFactory} from '../Actions/flexbilling-cache';
import {InviteUserDetailsBackendObj} from './InviteUserDetails';
import {ActivateFlexBilling, FlexBillingOption} from '../Sections/Intro/intro';

export class Address {
  line1 = '';

  line2 = '';

  countryId: number;

  countryOriginalName = '';

  countryTranslatedName: string;

  stateProvinceId = -1;

  statePovinceDbName = '';

  stateProvinceTranslatedName: string;

  city = '';

  postalCode = '';

  constructor(opts?: any) {
    if (opts) {
      Object.assign(this, opts);
    }
  }
}

export interface SubscriberAgreement {
  initials: string;
  title?: string;
  name: string;
  date: string;
}

export enum RemitAccountType {
  Checking,
  ACH
}

export enum AccountType {
  Checking,
  Savings
}

export class FlexCountryConfig {
  has_swift: boolean;

  has_accepts_check: boolean;

  bank_account_label: string | null;

  tax_id_label: string | null;

  has_bank_routing_number: boolean;

  has_bank_transit_id: boolean;

  bank_transit_id_label: string | null;

  bank_routing_label: string;

  name: string;

  constructor(backendObj) {
    Object.assign(this, backendObj);
    this.has_swift = Boolean(Number(this.has_swift));
    this.has_accepts_check = Boolean(Number(this.has_accepts_check));
    this.has_bank_routing_number = Boolean(Number(this.has_bank_routing_number));
    this.has_bank_transit_id = Boolean(Number(this.has_bank_transit_id));
  }
}

export interface CountryCurrency {
  code: string;
  label: string;
}

export interface FlexBillingData {
  remitOption: string;
  task: string;
  flexBillingId: string;
  flexBillingGroupId: string;
  groupName: string;
  createNewApprovedCustomGroup: string;
  orgCountry: string;
}

export interface CloudPlanData {
  postPaidPlanActive: boolean;
}

export async function userDetailsToMainStateMapper(
  mainState: MainState,
  userDetails: InviteUserDetailsBackendObj
) {
  const {
    paymentInfo,
    activateFlexBilling,
    ACHInfo,
    subscriberAgreement,
    subscriberInfo
  } = mainState;

  const flexbillingCache = flexbillingCacheFactory.subscribe();
  activateFlexBilling.cpOrgId = userDetails.companyId;
  activateFlexBilling.orgName = userDetails.companyName;
  paymentInfo.currencyCode = userDetails.flex_default_currency;

  ACHInfo.remitAccountType = RemitAccountType.ACH;
  ACHInfo.achAggreement = userDetails.achAggreement;
  mainState.companyOrganizationId = userDetails.companyOrganizationId;
  mainState.userId = userDetails.userId;

  if (mainState.countries.length === 0) {
    mainState.countries = await flexbillingCache.getSupportedCountries();
  }

  const countryId = userDetails.companyCountryId
    ? userDetails.companyCountryId
    : userDetails.company_country_id;

  mainState.countries.forEach(country => {
    if (country.id === countryId) {
      subscriberInfo.address.countryId = country.id;
      subscriberInfo.address.countryTranslatedName = country.name;
      subscriberInfo.address.countryOriginalName = country.originalName;
    }
  });

  const date = new Date();
  subscriberAgreement.date = date.toISOString().split('T')[0];

  mainState.holdMyFeesPolicyUrl = userDetails.holdMyFeesPolicyUrl;
  mainState.standaloneFlexBillingTermsUrl = userDetails.standaloneFlexBillingTermsUrl;
  mainState.feeOffsetPolicyUrl = userDetails.feeOffsetPolicyUrl;

  return mainState;
}

export async function flxDetailsToMainStateMapper(
  mainState: MainState,
  flxDetails: FlxBillDetailsBackendObj
) {
  const {
    ACHInfo,
    subscriberInfo,
    flexBillingProps,
    paymentInfo,
    subscriberAgreement,
    activateFlexBilling
  } = mainState;
  const flexbillingCache = flexbillingCacheFactory.subscribe();
  ACHInfo.accountNumber = flxDetails.flexBillingAcNum;
  ACHInfo.bankRoutingId = flxDetails.flexBillingAbaNum;

  ACHInfo.accountType =
    flxDetails.flexBillingAcType === 'checked' ? AccountType.Checking : AccountType.Savings;
  // remittance
  // use check as the flag since ach is default and payment mode could be empty.
  ACHInfo.remitAccountType =
    flxDetails.flexBillingPaymentMode !== FlexBillingOption.MAIL_A_CHECK
      ? RemitAccountType.ACH
      : RemitAccountType.Checking;

  ACHInfo.bankAddress.city = flxDetails.flexBillingBankCity;
  ACHInfo.bankAddress.line1 = flxDetails.flexBillingBankAddressLine1;
  ACHInfo.bankAddress.line2 = flxDetails.flexBillingBankAddressLine2;
  ACHInfo.bankAddress.postalCode = flxDetails.flexBillingBankPostalCode;
  ACHInfo.bankAddress.countryOriginalName = flxDetails.flexBillingBankCountry;
  ACHInfo.bankAddress.statePovinceDbName = flxDetails.flexBillingBankState;

  if (flxDetails.flexBillingChequeAddress) {
    ACHInfo.remittanceAddress = flxDetails.flexBillingChequeAddress;
  }

  if (mainState.countries.length === 0) {
    mainState.countries = await flexbillingCache.getSupportedCountries();
  }

  const subscriberCountry = flxDetails.flexBillingCountry
    ? flxDetails.flexBillingCountry
    : flxDetails.companyCountry;
  mainState.countries.forEach(country => {
    if (country.originalName === flxDetails.flexBillingBankCountry) {
      ACHInfo.bankAddress.countryId = country.id;
      ACHInfo.bankAddress.countryTranslatedName = country.name;
      ACHInfo.bankAddress.countryOriginalName = country.originalName;
    }
    if (country.originalName === subscriberCountry) {
      subscriberInfo.address.countryId = country.id;
      subscriberInfo.address.countryTranslatedName = country.name;
      subscriberInfo.address.countryOriginalName = country.originalName;
    }
  });

  if (subscriberInfo.address.countryId) {
    const subscriberStates = await flexbillingCache.getStates(
      subscriberInfo.address.countryId.toString()
    );
    subscriberStates.forEach(state => {
      if (state.originalName === flxDetails.flexBillingState) {
        subscriberInfo.address.stateProvinceId = state.id;
        subscriberInfo.address.stateProvinceTranslatedName = state.name;
        subscriberInfo.address.statePovinceDbName = state.originalName;
      }
    });
  }

  if (ACHInfo.bankAddress.countryId) {
    const bankStates = await flexbillingCache.getStates(ACHInfo.bankAddress.countryId.toString());
    bankStates.forEach(state => {
      if (state.originalName === flxDetails.flexBillingBankState) {
        ACHInfo.bankAddress.stateProvinceId = state.id;
        ACHInfo.bankAddress.stateProvinceTranslatedName = state.name;
        ACHInfo.bankAddress.statePovinceDbName = state.originalName;
      }
    });
  }

  ACHInfo.bankName = flxDetails.flexBillingBankName;
  ACHInfo.bankSwiftCode = flxDetails.flex_billing_bank_swift_code;
  ACHInfo.bankTransitId = flxDetails.flex_billing_bank_transit_id;
  ACHInfo.achAggreement = flxDetails.achAggreement;
  // subscriber mapping
  subscriberInfo.legalName = flxDetails.flexBillingLegalName;
  subscriberInfo.address.line1 = flxDetails.flexBillingAddressLine1;
  subscriberInfo.address.line2 = flxDetails.flexBillingAddressLine2;
  subscriberInfo.address.city = flxDetails.flexBillingCity;
  subscriberInfo.address.postalCode = flxDetails.flexBillingPostalCode;
  subscriberInfo.taxId = flxDetails.flexBillingSsn;
  subscriberInfo.vatId = flxDetails.vat_ids_vat_id;

  // payment info
  paymentInfo.currency = flxDetails.currencyLabel;
  paymentInfo.currencyCode = flxDetails.flexBillingCurrencyIsoCode
    ? flxDetails.flexBillingCurrencyIsoCode
    : flxDetails.companyCurrencyIsoCode;
  paymentInfo.accountReceivableContact.name = flxDetails.flexBillingContactName;
  paymentInfo.accountReceivableContact.email = flxDetails.flexBillingContactEmail;
  paymentInfo.accountReceivableContact.phone = flxDetails.flexBillingContactPhone;
  paymentInfo.accountReceivableContact.countryDialCode =
    flxDetails.flexBillingContactPhoneDialingCode;

  subscriberAgreement.initials = flxDetails.flexBillingSignature;
  subscriberAgreement.name = flxDetails.flexBillingPrintName;
  subscriberAgreement.date = flxDetails.flexBillingDate
    ? flxDetails.flexBillingDate.split(' ')[0]
    : '';
  subscriberAgreement.title = flxDetails.flexBillingTitle;

  activateFlexBilling.cpOrgId = flxDetails.companyId;
  activateFlexBilling.companyOrganizationId = flxDetails.companyOrganizationId
    ? flxDetails.companyOrganizationId
    : `ORG${flxDetails.companyId}`;
  activateFlexBilling.orgName = flxDetails.companyName;
  activateFlexBilling.remittanceId = flxDetails.companyVendorId;
  activateFlexBilling.oldRemittanceId = flxDetails.companyVendorId;
  activateFlexBilling.canEditVendorId = flxDetails.canEditVendorID;

  activateFlexBilling.fixedFee = flxDetails.fb_fixed_default ? flxDetails.fb_fixed_default : '0.00';
  activateFlexBilling.variableFee = flxDetails.fb_variable_default
    ? flxDetails.fb_variable_default
    : '0.00';
  activateFlexBilling.oldFixedFee = flxDetails.flexBillingMappingFixedFee;
  activateFlexBilling.oldVariableFee = flxDetails.flexBillingMappingVariableFee;
  flexBillingProps.canEditPriceData = flxDetails.canEditPriceData;

  if (flxDetails.isNoc) {
    activateFlexBilling.isActivate = true;
  } else {
    activateFlexBilling.isActivate = Boolean(flxDetails.companyVendorId);
  }

  if (mainState.flexBillingProps.remitOption !== '2') {
    activateFlexBilling.groupName = flxDetails.deviceGroupName;
    flexBillingProps.deviceGroupId = flxDetails.deviceGroupId;
    flexBillingProps.deviceGroupName = flxDetails.deviceGroupName;
  }

  activateFlexBilling.paymentOption = flxDetails.flexBillingPaymentMode
    ? flxDetails.flexBillingPaymentMode
    : FlexBillingOption.HOLD_MY_FEES;

  activateFlexBilling.applyDues = flxDetails.flex_billing_apply_fb_for_dues === null
    || parseInt(flxDetails.flex_billing_apply_fb_for_dues) === 1;

  flexBillingProps.deviceGroupVendorId = flxDetails.deviceGroupVendorId;

  flexBillingProps.isMFH = flxDetails.mfhs_flag;
  mainState.isNoc = flxDetails.isNoc;
  flexbillingCacheFactory.unsubscribe();

  if (mainState.flexBillingProps.remitOption === '2') {
    const mainStateStr = JSON.stringify(mainState, function(key, value) {
      return value === undefined || value === null ? '' : value;
    });
    mainState = JSON.parse(mainStateStr);
  }
  mainState.companyCountryId = flxDetails.company_country_id;
  mainState.holdMyFeesPolicyUrl = flxDetails.holdMyFeesPolicyUrl;
  mainState.standaloneFlexBillingTermsUrl = flxDetails.standaloneFlexBillingTermsUrl;
  mainState.feeOffsetPolicyUrl = flxDetails.feeOffsetPolicyUrl;

  return mainState;
}

export function getFlexFormFields(mainState: MainState): FormData {
  const formData = new FormData();
  const mainStateStr = JSON.stringify(mainState, function(key, value) {
    return value === undefined || value === null ? '' : value;
  });
  mainState = JSON.parse(mainStateStr);

  if (mainState.flexBillingProps.flexbillingId) {
    formData.set('txtFbID', mainState.flexBillingProps.flexbillingId.toString());
  }

  const {
    activateFlexBilling,
    subscriberInfo,
    paymentInfo,
    ACHInfo,
    flexBillingProps,
    subscriberAgreement
  } = mainState;
  let task;

  switch (flexBillingProps.formIntent) {
    case FlexFormIntent.Creation:
      task = 'new';
      break;
    case FlexFormIntent.Activate:
      task = 'activate';
      break;
    case FlexFormIntent.Update:
      task = 'update';
      break;
  }

  flexBillingProps.formIntent = FlexFormIntent.Update;
  task = 'update';

  formData.set('txtRemitOption', '2'); // always weekly. legacy implementation 2 = weekly
  formData.set('vendorId', activateFlexBilling.remittanceId); // maps to companyVendorId. If set, FB is activated.
  formData.set('oldVendorId', activateFlexBilling.oldRemittanceId); // used on update/deactivate FB
  formData.set('txtFbStatus', activateFlexBilling.isActivate ? '1' : '0');
  formData.set('isPricingApplied', ''); // drop this legacy implementation
  formData.set('iskWhPricingApplied', ''); // drop this. legacy implementation
  formData.set('txtlegalbussname', activateFlexBilling.orgName);
  formData.set('txtOrgId', activateFlexBilling.cpOrgId);
  formData.set('paymentOption', activateFlexBilling.paymentOption);
  formData.set('optOutReason', activateFlexBilling.optOutReason);

  if (activateFlexBilling.applyDues !== null) {
    formData.set('apply_fb_for_dues', activateFlexBilling.applyDues ? '1' : '0');
  }

  // if there are multiple remit ids for one org, these should be the value of the selected remit id. Usually -1 and only in the case of NM
  formData.set('remit_to', '-1');
  formData.set('txtLegalName', subscriberInfo.legalName);
  // activate, update = 1; new = 0
  formData.set(
    'flexBillingFormId',
    flexBillingProps.formIntent ? flexBillingProps.formIntent.toString() : ''
  );
  formData.set('txtCompanyAddress', subscriberInfo.address.line1);
  formData.set('txtCompanyAddress2', subscriberInfo.address.line2);
  formData.set('txtCountry', subscriberInfo.address.countryOriginalName);
  formData.set(
    'country_id',
    subscriberInfo.address.countryId ? subscriberInfo.address.countryId.toString() : ''
  );
  formData.set('txtState', subscriberInfo.address.statePovinceDbName);
  formData.set('txtCity', subscriberInfo.address.city);
  formData.set('txtZip', subscriberInfo.address.postalCode);
  formData.set('txtFederalSsn', subscriberInfo.taxId ? subscriberInfo.taxId : subscriberInfo.vatId);
  formData.set('txtCurrency', paymentInfo.currencyCode);
  formData.set('txtDefaultCurrency', paymentInfo.currency); // currency label TODO check to make sure this is still required.
  formData.set('txtContactName', paymentInfo.accountReceivableContact.name);
  formData.set('txtEmailContact', paymentInfo.accountReceivableContact.email);
  formData.set('txtContactPhoneDialingCode', paymentInfo.accountReceivableContact.countryDialCode);
  formData.set('txtContactPhone', paymentInfo.accountReceivableContact.phone);
  formData.set(
    'chkRemitTypeFs',
    (ACHInfo.remitAccountType === RemitAccountType.ACH
      || activateFlexBilling.paymentOption === FlexBillingOption.NOW) ? 'achWire' : 'check'
  );
  formData.set('txtCheckAddress', ACHInfo.remittanceAddress);

  if (ACHInfo.remitAccountType === RemitAccountType.ACH
    || activateFlexBilling.paymentOption === FlexBillingOption.NOW) {
    formData.set('txtBankBranchName', ACHInfo.branchName);
    formData.set('txtBankName', ACHInfo.bankName);
    formData.set('bankaddressline1', ACHInfo.bankAddress.line1);
    formData.set('bankaddressline2', ACHInfo.bankAddress.line2);
    formData.set('bank_country_org', ACHInfo.bankAddress.countryOriginalName);
    formData.set(
      'bank_country_id',
      ACHInfo.bankAddress.countryId ? ACHInfo.bankAddress.countryId.toString() : ''
    );
    formData.set('bank_state_org', ACHInfo.bankAddress.statePovinceDbName);
    formData.set('bank_city', ACHInfo.bankAddress.city);
    formData.set('bank_zip', ACHInfo.bankAddress.postalCode);
    formData.set('chkAcctype', ACHInfo.accountType === AccountType.Checking ? 'checked' : 'saving'); // checking or savings
  }

  formData.set('txtBankRoutingNumber', ACHInfo.bankRoutingId);
  formData.set('txtAccountNumber', ACHInfo.accountNumber);
  formData.set('txtBankSwiftCode', ACHInfo.bankSwiftCode);
  formData.set('txtBankTransitId', ACHInfo.bankTransitId);
  formData.set('txtSignature', subscriberAgreement.initials);

  formData.set('txtDate', subscriberAgreement.date);
  formData.set('txtPrint', subscriberAgreement.name);
  formData.set('txtTitle', subscriberAgreement.title);
  if (flexBillingProps.flexbillingId) {
    formData.set('txtFbID', flexBillingProps.flexbillingId.toString());
  }
  // device group name used on update
  formData.set('cgGroupSpan1', flexBillingProps.deviceGroupName);
  formData.set('txtGroup', flexBillingProps.deviceGroupId);
  formData.set('groupVendorId', flexBillingProps.deviceGroupVendorId);

  formData.set('txtCompanyId', activateFlexBilling.cpOrgId);
  formData.set('txtTask', task);
  formData.set('flexBillingStatus', '1'); // TODO look into why this is always 1 and if it is still needed.
  formData.set('isCurrencyAlreadySet', '1'); // TODO look into why this is always 1 and if it is still needed.
  formData.set('oldFlexBillingStatus', ''); // TODO look into why this is always empty and if it is still needed.
  formData.set('mfhs_flag', flexBillingProps.isMFH.toString());

  formData.set('create_new_approved_custom_group', flexBillingProps.createNewApprovedCustomGroup);
  formData.set('orgCountry', flexBillingProps.orgCountry)

  if (flexBillingProps.canEditPriceData) {
    if (shouldUpdatePrice(activateFlexBilling)) {
      formData.set('fixedFee', activateFlexBilling.fixedFee);
      formData.set('variableFee', activateFlexBilling.variableFee);
      formData.set('description', activateFlexBilling.description);
      formData.set('oldFixedFee', activateFlexBilling.oldFixedFee);
      formData.set('oldVariableFee', activateFlexBilling.oldVariableFee);
    }
  }

  return formData;
}

const shouldUpdatePrice = (activateFlexBilling: ActivateFlexBilling) => {
  if (
    activateFlexBilling.variableFee !== null ||
    typeof activateFlexBilling.variableFee !== 'undefined'
  ) {
    if (Number(activateFlexBilling.variableFee) !== Number(activateFlexBilling.oldVariableFee)) {
      return true;
    }
  }
  if (
    activateFlexBilling.fixedFee !== null ||
    typeof activateFlexBilling.fixedFee !== 'undefined'
  ) {
    if (Number(activateFlexBilling.fixedFee) !== Number(activateFlexBilling.oldVariableFee)) {
      return true;
    }
  }
};

export function getWizardFlexFormFields(
  mainState: MainState,
  isSaveAndContinue: boolean
): FormData {
  const formData = new FormData();
  const {
    activateFlexBilling,
    subscriberInfo,
    paymentInfo,
    ACHInfo,
    subscriberAgreement
  } = mainState;

  formData.set('txtlegalname', subscriberInfo.legalName);
  formData.set('txtlegalbussname', activateFlexBilling.orgName);
  formData.set('txtCompanyId', activateFlexBilling.cpOrgId);
  formData.set('txtfederalname', subscriberInfo.taxId);
  formData.set('txtlegaladdress', subscriberInfo.address.line1);
  formData.set('txtlegaladdress2', subscriberInfo.address.line1);
  formData.set('txtorgid', mainState.companyOrganizationId);
  // todo check AU
  // formData.set('regForGST', '0');
  formData.set('setup_fb', activateFlexBilling.setUpFlexLater ? '0' : '1');
  formData.set('paymentOption', activateFlexBilling.paymentOption);
  formData.set('optOutReason', activateFlexBilling.optOutReason);

  if (activateFlexBilling.applyDues !== null) {
    formData.set('apply_fb_for_dues', activateFlexBilling.applyDues ? '1' : '0');
  }

  formData.set('txtcontactname', paymentInfo.accountReceivableContact.name);
  formData.set('txtemailcontact', paymentInfo.accountReceivableContact.email);
  formData.set('txtcontactphone', paymentInfo.accountReceivableContact.phone);
  formData.set('txtcontactphoneDialingCode', paymentInfo.accountReceivableContact.countryDialCode);

  formData.set(
    'paymentTypeHidden',
    (ACHInfo.remitAccountType === RemitAccountType.ACH
      || activateFlexBilling.paymentOption === FlexBillingOption.NOW) ? 'achWire' : 'check'
  );
  formData.set('txtremittanceaddress', ACHInfo.remittanceAddress);
  formData.set('currency', paymentInfo.currencyCode);
  formData.set('txtbankname', ACHInfo.bankName);
  formData.set('bankaddressline1', ACHInfo.bankAddress.line1);
  formData.set('bankaddressline2', ACHInfo.bankAddress.line2);
  formData.set('bankCountry', ACHInfo.bankAddress.countryOriginalName);
  formData.set(
    'bankCountryId',
    ACHInfo.bankAddress.countryId ? ACHInfo.bankAddress.countryId.toString() : ''
  );
  formData.set('bankState', ACHInfo.bankAddress.statePovinceDbName);
  formData.set('bank_city', ACHInfo.bankAddress.city);
  formData.set('bank_zip', ACHInfo.bankAddress.postalCode);

  formData.set('txtbankroutingnumber', ACHInfo.bankRoutingId);
  formData.set('txtaccountnumber', ACHInfo.accountNumber);
  formData.set('chkAcctype', ACHInfo.accountType === AccountType.Checking ? 'checked' : 'saving');

  formData.set('txtsignature', subscriberAgreement.initials);
  formData.set('txtdate', subscriberAgreement.date);
  formData.set('txtprint', subscriberAgreement.name);
  formData.set('txttitle', subscriberAgreement.title);

  formData.set('flexCountry', subscriberInfo.address.countryOriginalName);
  formData.set('flexState', subscriberInfo.address.statePovinceDbName);
  formData.set('txtaddressline1', subscriberInfo.address.line1);
  formData.set('txtaddressline2', subscriberInfo.address.line2);
  formData.set('txtcity', subscriberInfo.address.city);
  formData.set('txtzip', subscriberInfo.address.postalCode);
  formData.set('email', paymentInfo.accountReceivableContact.email);
  formData.set('isSaveAndContinue', isSaveAndContinue ? '1' : '0');
  formData.set('userid', mainState.userId);

  const countryOrg = document.getElementById('country_org') as HTMLSelectElement;
  formData.set('country_id', countryOrg.value);
  formData.set(
    'org_country',
    countryOrg.options[countryOrg.selectedIndex].getAttribute('originalName')
  );

  formData.set('txtBankSwiftCode', ACHInfo.bankSwiftCode);
  formData.set('txtBankTransitId', ACHInfo.bankTransitId);
  formData.set('txtBankBranchName', ACHInfo.branchName);

  return formData;
}
