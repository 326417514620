import { register, setup } from 'rox-browser';
import featureFlags from './flag-definitions';

export class RolloutFlags {
  private flags: Map<string, boolean> = new Map();

  private static instance: RolloutFlags;

  isInitialized = false;

  initialize(featureFlags, context): void {
    for (const configKey in featureFlags) {
      if (featureFlags.hasOwnProperty(configKey)) {
        this.flags.set(configKey, featureFlags[configKey].isEnabled(context));
      }
    }
  }

  hasFlag(flagName: string): boolean {
    return this.flags.has(flagName);
  }

  getFlag(flagName: string): boolean {
    return this.flags.get(flagName);
  }

  public static getInstance(): RolloutFlags {
    if (!RolloutFlags.instance) {
      RolloutFlags.instance = new RolloutFlags();
    }
    return RolloutFlags.instance;
  }
}

async function initCloudBees(appId: string) {
  const options = {};
  // Register the flags with Rollout
  register('', featureFlags);
  await setup(appId, options);
}

export function getFeatureFlags(
  appId?: string,
  role?: string,
  isNOC?: string,
  userID?: string,
  companyId?: string,
  instanceName?: string,
): RolloutFlags {
  if (!appId) {
    return RolloutFlags.getInstance(); // This should return an empty Map
  }

  if (!RolloutFlags.getInstance().isInitialized) {
    initCloudBees(appId);
    RolloutFlags.getInstance().isInitialized = true;
  }
  const context = { role, isNOC, userID, companyId, instanceName };
  RolloutFlags.getInstance().initialize(featureFlags, context);

  return RolloutFlags.getInstance();
}
