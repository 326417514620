import React from 'react';
import { StyledDiv, Styledlabel } from '../../pages/Account/styled-elements';

interface FieldProps {
  field: string;
  label: string;
  value: any;
}

const Field = ({ field, label, value }: FieldProps) => {
  return (
    <div key={field}>
      <Styledlabel key={`label_${field}`}>{label}</Styledlabel>
      <StyledDiv key={`div_${field}`}>{value}</StyledDiv>
    </div>
  );
};

export default Field;
