import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  KitCheck,
  KitIcon,
  KitFlexRowSpaced,
  KitFlexColSpaced,
  KitSpinner,
  useToast,
  KitToastTypeOptions,
  ThemeConstants,
  ThemeColors,
  KitUtilData,
  KitEditPanel
} from '@chargepoint/cp-toolkit';
import styled from 'styled-components';
import { TranslateFunc } from '@chargepoint/cp-toolkit/src/common/types';
import { useTranslation } from 'react-i18next';
import { AccountService } from '@chargepoint/common/services/AccountService';
import { EditMode, NOTIFICATION_TYPE } from '../../common/constants';

interface AccountNotificationProps {
  userId: string | number;
  type: NOTIFICATION_TYPE;
}

const NotificationTimeField = styled.input`
  padding: ${ThemeConstants.spacing.xxl}px;
  border: 0;
  border-bottom: 1px solid ${ThemeColors.gray_50};
  &:focus {
    background-color: ${ThemeColors.gray_10};
  }
`;
const NotificationSpan = styled.span`
  margin-right: ${ThemeConstants.spacing.absolute.sm}px;
`;

const SpanSpaced = styled.span`
  display: inline-flex;
  margin-bottom: ${ThemeConstants.spacing.absolute.s}px;
`;

const KitEditPanelStyled = styled(KitEditPanel)`
  align-items: center;
`;

const KitFlexRowSpacedStyled = styled(KitFlexRowSpaced)`
  min-height: 35px;
`;

const AccountNotifications: React.FC<AccountNotificationProps> = ({ userId, type }) => {
  const { t } = useTranslation();
  const initialData = useRef([]);
  const [inEditMode, setInEditMode] = useState(false);
  const [data, setData] = useState([]);
  const [changes, setChanges] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [access, setAccess] = useState(true);

  const getNotifications = useCallback(async () => {
    if (!userId) {
      return;
    }
    setShowSpinner(true);
    const response = await AccountService.getNotifications(userId, type);
    setShowSpinner(false);
    if (response.error) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useToast({
        t,
        toastType: KitToastTypeOptions.ERROR,
        message: response.error.message
      });
      return;
    }
    if (response[`${type}_access`] === false) {
      setAccess(false);
    } else {
      setData(response[type]);
    }
    initialData.current = response[type].map(item => ({ ...item }));
  }, [userId, type, t]);

  const updateNotifications = async (type: NOTIFICATION_TYPE, options) => {
    if (!options || !options.length) {
      setInEditMode(false);
      return;
    }
    setShowSpinner(true);
    const response = await AccountService.updateNotifications(
      {
        id: userId,
        notifications: options
      },
      type
    );
    setShowSpinner(false);
    if (response.error) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useToast({
        t,
        toastType: KitToastTypeOptions.ERROR,
        message: response.error.message
      });
      return;
    }
    initialData.current = data.map(item => ({ ...item }));
    setInEditMode(false);
  };

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const handleSubmit = async () => {
    await updateNotifications(type, changes);
  };

  const handleCancel = () => {
    setInEditMode(false);
    setData(initialData.current);
  };

  // This method runs when user changes any field in the form. It updates item in data array
  // and makes copy of data array to changes array.
  // If form wasn't touched, changes array are empty and we don't sent any information to the server.
  const handleOnChange = e => {
    const key = e.target.id.split('-')[0];
    // TODO review this logic at run time to see if it is correct. PP
    const updatedArr = data.map(item => {
      if (item.notify_code === key) {
        item[e.target.name] =  (e.target.type === 'checkbox') ? (item[e.target.name] === e.target.checked) ? !e.target.checked : e.target.checked : e.target.value;
      }
      return item;
    });
    setChanges(updatedArr);
  };

  if (!access) {
    return null;
  }
  return (
    <KitEditPanelStyled
      t={t as TranslateFunc}
      title={t(`account_notifications.${type}`) as string}
      mode={inEditMode ? EditMode.EDIT : EditMode.READ_ONLY}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onToggle={() => {
        setInEditMode(!inEditMode);
      }}
    >
      {showSpinner && <KitSpinner size='s' />}
      <form>
        <KitFlexRowSpacedStyled>
          <KitFlexColSpaced>
            {data.map(fieldName => (
              <SpanSpaced
                key={`account_notifications.${type}_notifications.${fieldName.notify_code}`}
              >
                {t(`account_notifications.${type}_notifications.${fieldName.notify_code}`)}
              </SpanSpaced>
            ))}
          </KitFlexColSpaced>
          <KitFlexColSpaced>
            {data.map((data, i) => (
              <KitFlexRowSpaced key={`row-${i}`}>
                {!('time' in data) && (
                  <>
                    {inEditMode ? (
                      <SpanSpaced>
                        <NotificationSpan key={`${data.notify_code}-email`}>
                          <KitCheck
                            label={t('account_notifications.email')}
                            name='email'
                            id={`${data.notify_code}-email-${i}`}
                            checked={KitUtilData.toBoolean(data.email)}
                            onChange={handleOnChange}
                          />
                        </NotificationSpan>
                        {'sms' in data && (
                          <NotificationSpan key={`${data.notify_code}-sms`}>
                            <KitCheck
                              label={t('account_notifications.sms')}
                              name='sms'
                              id={`${data.notify_code}-sms-${i}`}
                              checked={KitUtilData.toBoolean(data.sms)}
                              onChange={handleOnChange}
                            />
                          </NotificationSpan>
                        )}
                      </SpanSpaced>
                    ) : (
                      <SpanSpaced>
                        <NotificationSpan key={`${data.notify_code}-email-no-edit`}>
                          <KitIcon
                            icon={KitUtilData.toBoolean(data.email) ? 'check-bold' : 'close'}
                            fill={
                              KitUtilData.toBoolean(data.email)
                                ? ThemeColors.green_50
                                : ThemeColors.gray_50
                            }
                          />{' '}
                          <span>{t('account_notifications.email')}</span>
                        </NotificationSpan>
                        {'sms' in data && (
                          <NotificationSpan key={`${data.notify_code}-sms-no-edit`}>
                            <KitIcon
                              icon={KitUtilData.toBoolean(data.sms) ? 'check-bold' : 'close'}
                              fill={
                                KitUtilData.toBoolean(data.sms)
                                  ? ThemeColors.green_50
                                  : ThemeColors.gray_50
                              }
                            />{' '}
                            <span>{t('account_notifications.sms')}</span>
                          </NotificationSpan>
                        )}
                      </SpanSpaced>
                    )}
                  </>
                )}
                {type === 'fleet' && data.time && (
                  <KitFlexRowSpaced>
                    <NotificationSpan>
                      <NotificationTimeField
                        disabled={!inEditMode}
                        defaultValue={data.time}
                        onChange={handleOnChange}
                        type='time'
                        name='time'
                        id={`${data.notify_code}-time`}
                      />
                    </NotificationSpan>
                  </KitFlexRowSpaced>
                )}
              </KitFlexRowSpaced>
            ))}
          </KitFlexColSpaced>
        </KitFlexRowSpacedStyled>
      </form>
    </KitEditPanelStyled>
  );
};

export default AccountNotifications;
