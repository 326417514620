import styled from 'styled-components';
import { ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit';

const { fontWeight, spacing } = ThemeConstants;

export const Styledlabel = styled.label`
  display: block;
  margin-left: ${spacing.absolute.s}px;
  color: ${ThemeColors.gray_50};
  margin-bottom: 0;
  line-height: 1;
  font-size: ${ThemeConstants.fontSize.text_14}rem;
  padding: ${spacing.absolute.xs}px 0 ${spacing.absolute.xxs}px 0;
`;

export const StyledDiv = styled.div`
  margin-left: ${spacing.absolute.s}px;
  margin-bottom: ${spacing.absolute.s}px;
  font-weight: ${fontWeight.bold};
  padding-top: ${spacing.absolute.xxs}px;
  padding-bottom: ${spacing.absolute.sm}px;
`;
