import { KitCard, KitEditPanel, KitTextField } from '@chargepoint/cp-toolkit';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { EditMode } from '../../common/constants';
import { useForm } from 'react-hook-form';
import { AccountService } from '@chargepoint/common/services/AccountService';

interface PasswordManagementProps {
  userId: string | number;
  disableEdit: boolean;
}

const PasswordManagement = ({ userId, disableEdit }: PasswordManagementProps) => {
  const [mode, setMode] = useState(EditMode.READ_ONLY);
  const { t } = useTranslation();

  let { register, errors, trigger, setError } = useForm({
    mode: 'onBlur'
  });

  const [pass, setPass] = useState({
    oldPass: '',
    newPass: '',
    newPassRepeated: ''
  });

  const clearPass = () => {
    setPass({
      oldPass: '',
      newPass: '',
      newPassRepeated: ''
    });
  }

  const onSubmit = async () => {
    const result = await trigger();
    if (result) {
      try {
        const response = await AccountService.updatePassword({ ...pass, userId });
        if (response.status === true) {
          setMode(EditMode.READ_ONLY);
          clearPass();
        } else {
          if (response.error && response.error.message) {
            const message = response.error.message;
            if (typeof message === 'string') {
              setError('oldPass', {
                type: 'server',
                message
              });
              return;
            }
            const { current_password, new_password, confirm_password } = message;
            if (current_password) {
              setError('oldPass', { type: 'server', message: current_password });
            }
            if (new_password) {
              setError('newPass', { type: 'server', message: new_password });
            }
            if (confirm_password) {
              setError('newPassRepeated', { type: 'server', message: confirm_password });
            }
          }
        }
      } catch (e) {
        setError('oldPass', {
          type: 'server',
          message: (e && e.message) || t('common:accountPage.serverError')
        });
      }
    }
  };

  const onCancel = () => {
    setMode(EditMode.READ_ONLY);
    clearPass();
  };

  const changeField = (field, value) => {
    setPass({
      ...pass,
      [field]: value
    })
  };

  const onToggle = () => setMode(mode === EditMode.READ_ONLY ? EditMode.EDIT : EditMode.READ_ONLY);
  const onChangeOldPass = (e) => changeField('oldPass', e.target.value);
  const onChangeNewPass = (e) => changeField('newPass', e.target.value);
  const onChangeNewPassRepeated = (e) => changeField('newPassRepeated', e.target.value);

  return mode === EditMode.READ_ONLY ? (
    <KitCard onClick={onToggle}>
      <KitCard.Header>
        {t('common:accountPage.passwordManagement')}
      </KitCard.Header>
      <KitCard.Body>
        {t('common:accountPage.changePassword')}
      </KitCard.Body>
    </KitCard>
  ) : (
    <KitEditPanel
      mode={mode}
      title={t('common:accountPage.passwordManagement')}
      onSubmit={onSubmit} onCancel={onCancel}
      onToggle={onToggle}
      t={t}>
      <KitTextField
        label={t('common:accountPage.oldPass')}
        value={pass.oldPass}
        type='password'
        name='oldPass'
        error={errors && errors.oldPass ? errors.oldPass.message : ''}
        ref={register({ required: { value: true, message: t('generalErrors.required') } })}
        onChange={onChangeOldPass}
        readOnly={disableEdit}
        required
      />
      <KitTextField
        label={t('common:accountPage.newPass')}
        value={pass.newPass}
        type='password'
        name='newPass'
        error={errors && errors.newPass ? errors.newPass.message : ''}
        ref={register({ required: { value: true, message: t('generalErrors.required') } })}
        onChange={onChangeNewPass}
        readOnly={disableEdit}
        required
      />
      <KitTextField
        label={t('common:accountPage.newPassRepeated')}
        value={pass.newPassRepeated}
        type='password'
        name='newPassRepeated'
        error={errors && errors.newPassRepeated ? errors.newPassRepeated.message : ''}
        ref={register({ required: { value: true, message: t('generalErrors.required') } })}
        onChange={onChangeNewPassRepeated}
        readOnly={disableEdit}
        required
      />
    </KitEditPanel>
  );
};

export default PasswordManagement;
