import React, { Component } from 'react';
import HelpIndex from '../../Components/HelpIndex';
import HelpSection from '../../Components/HelpSection';
import { Translation } from 'react-i18next';
import "whatwg-fetch";
import styles from './style.module.scss';
import { waitlistConfig } from '../../../data/waitlist';

class WaitlistFAQ extends Component {
  render() {
    return (
      <Translation>
        {(t) => (
          <div className={styles.WaitlistFAQ} id="top">
            <div>
              <h1>{t("header_waitlist")}</h1>
              <ul>
                {waitlistConfig.map((section, i) => {
                  return (
                    <HelpIndex key={i} row={i + 1} title={section.title} />
                  );
                })}
              </ul>
              {waitlistConfig.map((section, i) => {
                return (
                  <HelpSection
                    key={i}
                    row={i + 1}
                    title={section.title}
                    content={section.content}
                  />
                );
              })}
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default WaitlistFAQ;
