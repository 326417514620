import { FC } from 'react';
import styled from 'styled-components';
import { ThemeConstants as constants } from '@chargepoint/cp-toolkit';
import packageJson from '../../package.json';

const Container = styled.div`
  padding: ${constants.spacing.m}rem ${constants.spacing.l}rem;
`;

const Version: FC = () => {
  return (
    <div>
      <h1>Version</h1>
      <Container>
        <p>
          Version: <b>{packageJson.version}</b>
        </p>
        <p>
          GitHub SHA:{' '}
          <b>{`...${window.runtimeVersion.GITHUB_SHA.substr(
              window.runtimeVersion.GITHUB_SHA.length - 8
          )}`}</b>
        </p>
      </Container>
    </div>
  );
};

export default Version;
