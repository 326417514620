import React, { useContext, useEffect, useState } from 'react';
import {
  KitEditPanel,
  KitFlexCol,
  KitFlexRowSpaced,
  KitNotification,
  KitPhone,
  KitSpinner,
  KitTab,
  KitTabList,
  KitTabPanel,
  KitTabs,
  KitTextField,
  KitToast,
  ThemeColors,
  ThemeConstants,
} from '@chargepoint/cp-toolkit';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { SessionContext } from '@chargepoint/common/hooks/SessionContext';
import { useForm } from 'react-hook-form';
import { AccountService } from '@chargepoint/common/services/AccountService';
import { getLastURLPath } from '../../common/utils';
import Page from '../../components/Page';
import ServerMsgSnackBar, {
  ServerMsgType,
} from '../../components/ServerMsgSnackBar';
import AccountNotifications from '../../components/account-notification/AccountNotifications';
import Privileges from '../../components/Privileges';
import { StyledDiv, Styledlabel } from './styled-elements';
import ContactInformation from '../../components/ContactInformation';
import AccountInfo from '../../components/AccountInfo';
import { EditMode, NOTIFICATION_TYPE } from '../../common/constants';
import PasswordManagement from '../../components/PasswordManagement';

const { fontSize, spacing } = ThemeConstants;
const KitTabsExtended = KitTabs as any;

const UILayout = {
  footerHeight: 4,
  hPageMargin: ThemeConstants.spacing.absolute.m * 3,
};

const Container = styled.div`
  padding-top: ${spacing.l}rem;
  margin-bottom: ${spacing.xl}rem;
  min-height: 100%;
  height: 100%;
  width: 100%;
`;

const HeaderContainer = styled.div`
  background-color: ${ThemeColors.white};
  font-size: ${fontSize.text_14}rem;
  ul {
    padding-left: ${UILayout.hPageMargin}px;
  }
`;

const StyledPhone = styled(StyledDiv)`
  padding-top: ${spacing.absolute.xs}px;
`;

const StyledPageHeading = styled.h1`
  font-size: 1.25rem;
  && {
    padding-left: ${UILayout.hPageMargin}px;
    margin-top: 0;
  }
`;

const StyledKitTabList = styled(KitTabList)`
  && {
    padding-left: ${UILayout.hPageMargin}px;

    li {
      color: ${ThemeColors.gray_50};
    }
  }
`;

const StyledProfile = styled.div`
  color: ${ThemeColors.gray_50};
  font-size: ${ThemeConstants.fontSize.text_14}rem;
  text-transform: uppercase;
  padding-left: ${UILayout.hPageMargin}px;
  padding-top: ${spacing.absolute.l}px;
`;

const HiddenKitPanel = styled(KitTabPanel)`
  && {
    display: none;
  }
`;

const BackgroundColorContainer = styled.div<{
  background_color: string;
  with_bottom_border: boolean;
}>`
  background-color: ${(props: any) => props.background_color};
  border-bottom: ${(props: any) =>
    props.with_bottom_border ? `1px solid ${ThemeColors.gray_10}` : ''};
  flex-grow: 1;
  padding-left: ${UILayout.hPageMargin}px;
  padding-right: ${UILayout.hPageMargin}px;
`;

const FlexColStyled = styled(KitFlexCol)`
  width: calc(33% - ${ThemeConstants.spacing.absolute.sm}px);
  gap: ${ThemeConstants.spacing.absolute.l}px;
  @media (max-width: ${ThemeConstants.breakpoints.lg}px) {
    width: calc(50% - ${ThemeConstants.spacing.absolute.sm}px);
  }
  @media (max-width: ${ThemeConstants.breakpoints.sm}px) {
    width: 100%;
  }
`;

const FlexRowSpaced = styled(KitFlexRowSpaced)`
  flex-wrap: wrap;
  gap: ${ThemeConstants.spacing.absolute.sm}px;
`;

const AccountWrapper = styled.div`
  z-index: 1;
`;

interface AccountPageProps {  
  basePath: string;
}

function AccountPage({basePath}: AccountPageProps) {
  const { t } = useTranslation();
  const { session, refreshSession, updateSession } = useContext(SessionContext);
  const [email, setEmail] = useState({ initial: '', current: '' });
  const [userId, setUserId] = useState(undefined);
  const [accountInfo, setAccountInfo] = useState({});
  const [disableEdit, setDisableEdit] = useState<boolean>(false);
  const [serverMessage, setServerMessage] = useState<string | string[]>('');
  const defaultKey = getLastURLPath(window.location.pathname);

  const tabs = [
    {
      id: 'overview',
      label: t('common:accountPage.overview'),
    },
    {
      id: 'notifications',
      label: t('common:accountPage.notifications'),
    },
  ];

  const defaultTabIndex = tabs.findIndex((tab) => tab.id === defaultKey);
  const [selectedIndex, setIndex] = useState(
    defaultTabIndex !== -1 ? defaultTabIndex : 0
  );
  const [showNotifyBySpinner, setShowNotifyBySpinner] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [mode, setMode] = useState(EditMode.READ_ONLY);
  const [phone, setPhone] = useState({ current: '', initial: '' });
  const [code, setCode] = useState({ current: '', initial: '' });
  const [name, setName] = useState('');
  const [countries, setCountries] = useState([]);
  const {
    register,
    setError,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });

  const updatePhone = (value) => {
    setPhone({ ...phone, current: value });
  };

  const updateCode = (value) => {
    setCode({ initial: code.initial, current: value });
  };

  const onChangeEmail = (ev) => {
    setEmail({ ...email, current: ev.target.value });
  };

  /**
   * initializer function to get all the countries
   */
  useEffect(() => {
    (async () => {
      const uid =
        session.userInfo && session.userInfo.userId
          ? session.userInfo.userId
          : undefined;
      if (!uid) {
        return;
      }
      setShowNotifyBySpinner(true);
      const notificationDetails = await AccountService.getNotificationDetails(
        uid
      );
      const details = await AccountService.getAccountDetails(uid);
      setAccountInfo(details);
      setShowNotifyBySpinner(false);
      const { notify_info } = notificationDetails;
      const email = notify_info ? notify_info.email : '';
      const code =
        notify_info && notify_info.phone ? notify_info.phone.country_code : '';
      const phone =
        notify_info && notify_info.phone ? notify_info.phone.number : '';
      setEmail({ current: email, initial: email });
      setCode({ current: code, initial: code });
      setPhone({ current: phone, initial: phone });
      setName(
        session.userInfo && session.userInfo.userFirstName
          ? session.userInfo.userFirstName
          : ''
      );
      setUserId(uid);
      if (session.isNOC && session.isCPAdminMigratedToOKTA && details.is_sso_migrated) {
        setDisableEdit(true);
      }
    })();
  }, [session]);

  useEffect(() => {
    (async () => {
      try {
        setShowNotifyBySpinner(true);
        const allCountries = await AccountService.getAllCountries();
        const filteredCountries = allCountries.filter(
          ({ callingCode }) => callingCode !== 0
        );
        setCountries(filteredCountries);
      } catch (error) {
        setCountries([]);
      } finally {
        setShowNotifyBySpinner(false);
      }
    })();
  }, []);

  useEffect(() => {
    setIndex(defaultTabIndex !== -1 ? defaultTabIndex : 0);
  }, [defaultTabIndex]);

  const onTabChange = (tabIndex) => {
    setIndex(tabIndex);
    const tabKey = tabs[tabIndex].id;
    debugger;
    navigate(`${basePath}/${tabKey}`);
  };

  const clearMsg = function() {
    setServerMessage('');
  };

  const updateError = (field: string, message: string, type = 'server') => {
    setError(field, {
      type,
      message,
    });
  };

  const handleSubmit = () => {
    (async () => {
      const result = await trigger();
      if (result) {
        try {
          setShowNotifyBySpinner(true);
          const response = await AccountService.updateNotificationDetails({
            id: userId,
            email: email.current,
            code: code.current,
            number: phone.current,
          });
          if (response && response.status === true) {
            setMode(EditMode.READ_ONLY);
            setEmail({ ...email, initial: email.current });
            setPhone({ ...phone, initial: phone.current });
            setCode({ ...code, initial: code.current });
          } else {
            const { message } = response.error;
            if (typeof message === 'string') {
              setServerMessage(message);
              return;
            }
            Object.keys(message).forEach((key: string) => {
              const regexp = /notify_info\[(.*?)\]/m;
              if (regexp.test(key)) {
                const groups = key.match(regexp);
                updateError(groups[1], message[key]);
                return;
              }
              updateError(key, message[key]);
            });
          }
        } catch (error) {
          setServerMessage(error.message);
        } finally {
          setShowNotifyBySpinner(false);
        }
      }
    })();
  };

  const handleCancel = () => {
    setMode(EditMode.READ_ONLY);
    setEmail({ ...email, current: email.initial });
    setPhone({ ...phone, current: phone.initial });
    setCode({ ...code, current: code.initial });
  };

  const getNotificationContainer = () => {
    return (
      <Container>
        {!phone.initial && !showNotifyBySpinner && (
          <KitNotification
            style={{
              marginTop: 0,
              marginBottom: `${ThemeConstants.spacing.absolute.l}px`,
            }}
            type="info"
            message={t('common:accountPage.enterMobileNumber')}
          />
        )}
        <FlexRowSpaced>
          <FlexColStyled>
            <AccountWrapper>
              <KitEditPanel
                t={t}
                title={t('common:accountPage.contactInfo')}
                mode={mode}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
                onToggle={() => {
                  setMode(
                    mode === EditMode.READ_ONLY
                      ? EditMode.EDIT
                      : EditMode.READ_ONLY
                  );
                }}
              >
                {showNotifyBySpinner && <KitSpinner size="s" />}
                {mode === EditMode.EDIT ? (
                  <>
                    <KitTextField
                      label={t('common:accountPage.email')}
                      value={email.current}
                      type="email"
                      name="email"
                      onChange={onChangeEmail}
                      error={
                        errors && errors.email
                          ? errors.email.message || t('generalErrors.required')
                          : ''
                      }
                      ref={register({
                        required: {
                          value: true,
                          message: t('generalErrors.required'),
                        },
                      })}
                      required
                    />
                    <KitPhone
                      countries={countries}
                      updatePhone={updatePhone}
                      updateCode={updateCode}
                      phone={phone.current}
                      code={code.current}
                      errors={errors}
                      required
                      t={t}
                    />
                  </>
                ) : (
                  <KitFlexCol>
                    <Styledlabel>{t('common:accountPage.email')}</Styledlabel>
                    <StyledDiv>{email.initial}</StyledDiv>
                    <Styledlabel>
                      {t('common:accountPage.cellPhone')}
                    </Styledlabel>
                    <StyledPhone>
                      {code.initial ? `+${code.initial}` : '-'} {phone.initial}
                    </StyledPhone>
                  </KitFlexCol>
                )}
              </KitEditPanel>
            </AccountWrapper>
            <div>
              <AccountNotifications
                type={NOTIFICATION_TYPE.VALET}
                userId={userId}
              />
            </div>
          </FlexColStyled>
          <FlexColStyled>
            <AccountNotifications
              type={NOTIFICATION_TYPE.GENERAL}
              userId={userId}
            />
          </FlexColStyled>
          <FlexColStyled>
            <AccountNotifications
              type={NOTIFICATION_TYPE.FLEET}
              userId={userId}
            />
          </FlexColStyled>
        </FlexRowSpaced>
      </Container>
    );
  };

  const getHeaderContent = () => {
    return (
      <>
        <HeaderContainer>
          <StyledProfile>{t('common:accountPage.profile')}</StyledProfile>
          <StyledPageHeading>
            {t('common:accountPage.account', { name })}
          </StyledPageHeading>
          <KitTabsExtended
            selectedIndex={selectedIndex}
            onSelect={(k) => onTabChange(k)}
          >
            <StyledKitTabList>
              {tabs.map(({ id, label }) => (
                <KitTab key={id}>{label}</KitTab>
              ))}
            </StyledKitTabList>
            <HiddenKitPanel />
            <HiddenKitPanel />
          </KitTabsExtended>
        </HeaderContainer>
        <BackgroundColorContainer
          background_color={ThemeColors.gray_10}
          with_bottom_border
        >
          <KitToast position="top-right" duration={2000} reverseOrder />
          <ServerMsgSnackBar
            visible={!!serverMessage}
            message={serverMessage}
            msgType={ServerMsgType.Error}
            clearMsg={clearMsg}
          />
        </BackgroundColorContainer>
      </>
    );
  };

  const getOverviewContent = () => {
    return (
      <Container>
        <FlexRowSpaced>
          <FlexColStyled>
            <AccountInfo
              userId={userId}
              disableEdit={disableEdit}
              details={accountInfo}
              updateSession={updateSession}
            />
            <Privileges userId={userId} />
          </FlexColStyled>
          <FlexColStyled>
            <ContactInformation
              countries={countries}
              userId={userId}
              disableEdit={disableEdit}
              refreshSession={refreshSession}
            />
          </FlexColStyled>
          <FlexColStyled>
            <PasswordManagement disableEdit={disableEdit} userId={userId} />
          </FlexColStyled>
        </FlexRowSpaced>
      </Container>
    );
  };

  const getRoutedContent = () => {
    if (/account\/notifications/i.test(pathname)) {
      return getNotificationContainer();
    }
    return getOverviewContent();
  };
  return (
    <Page>
      {getHeaderContent()}
      {getRoutedContent()}
    </Page>
  );
}

export default AccountPage;
