import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import { getLocaleName } from '@chargepoint/common/util/i18n';
import langVersion from '@chargepoint/common/locales/version.json';

export const sessionObj = JSON.parse(window.sessionStorage.getItem('sessionObj'));

let currentLocale = 'en-US';
if (sessionObj && sessionObj.locale) {
  currentLocale = sessionObj.locale;
}

currentLocale = getLocaleName(currentLocale);

const local_storage_expiration_time = process.env.NODE_ENV !== 'production' ? 5*1000 : 7*24*60*60*1000; // hot reload or 7 day cache
const langEnpoint = `https://${process.env.REACT_APP_DOMAIN}/locales/{{lng}}/{{ns}}.json`;
i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: currentLocale,
    fallbackLng: 'en-US',
    load: 'currentOnly',
    ns: ['common', 'kit', 'translation', 'web_common'], // NOTE: common.json file is sym linked to /common/locales/{{lng}}/{{ns}}
    debug: process.env.NODE_ENV !== 'production',
    backend: {
      backends: [
        LocalStorageBackend, // primary
        HttpBackend           // fallback
      ],
      backendOptions: [
        {
          // prefix for stored languages
          prefix: 'i18next_admin:',
          expirationTime: local_storage_expiration_time,
          // language versions
          defaultVersion: langVersion.number,
          },
        {
          loadPath: langEnpoint // xhr load path for my own fallback
        }
      ]
    }
  });

export default i18n;
