import { Component, ReactPortal, ReactNode } from 'react';
import { createPortal } from 'react-dom';

class ReactNGPortal extends Component<{ children: ReactNode }> {
  el: HTMLDivElement;

  portalRoot = document.getElementById('react-portal-root');

  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount(): void {
    if (this.portalRoot) {
      this.portalRoot.appendChild(this.el);
    }
  }

  componentWillUnmount(): void {
    if (this.portalRoot) {
      this.portalRoot.removeChild(this.el);
    }
  }

  render(): ReactPortal {
    if (this.portalRoot) {
      return createPortal(this.props.children, this.el);
    }
    return null;
  }
}

export default ReactNGPortal;
