import { createGlobalStyle } from 'styled-components';

import { ThemeConstants } from '@chargepoint/cp-toolkit';

const PageletCss = createGlobalStyle`



/****************************
 * App / Page
 ****************************/
    
#react-app-root,
#react-driver-root {
  height: 100%;
  font-family: ${({ theme }) => theme.font.fontFamily};
  font-size: ${ThemeConstants.baseFontSize}px;
  color: ${({ theme }) => theme.page.body.text};

}


code {
  font-family: ${ThemeConstants.fontFamily.code};
}

  
.react-app-container h1 {
  font-size: ${ThemeConstants.fontSize.h1}rem;
}
.react-app-container h2 {
  font-size: ${ThemeConstants.fontSize.h2}rem;
}
.react-app-container h3 {
  font-size: ${ThemeConstants.fontSize.h3}rem;
}
.react-app-container h4 {
  font-size: ${ThemeConstants.fontSize.h4}rem;
}
.react-app-container h5 {
  font-size: ${ThemeConstants.fontSize.h5}rem;
}
.react-app-container h6 {
  font-size: ${ThemeConstants.fontSize.h6}rem;
}

.react-app-container h1:first-child,
.react-app-container h2:first-child,
.react-app-container h3:first-child,
.react-app-container h4:first-child,
.react-app-container h5:first-child,
.react-app-container h6:first-child {
  margin-top: 0em;
}

.react-app-container h1:last-child,
.react-app-container h2:last-child,
.react-app-container h3:last-child,
.react-app-container h4:last-child,
.react-app-container h5:last-child,
.react-app-container h6:last-child {
  margin-bottom: 0em;
}

.react-app-container p {
  margin: 0em 0em 1em;
  font-size: ${ThemeConstants.fontSize.p}rem;
}

.react-app-container p:first-child {
  margin-top: 0em;
}
.react-app-container p:last-child {
  margin-bottom: 0em;
}
  
.react-app-container li {
  font-size: ${ThemeConstants.fontSize.p}rem;
}

.react-app-container a {
  color: ${({ theme }) => theme.link.text};
  text-decoration: none;
}

input,
textarea,
select,
button {
  font-family: inherit;
}

[role=button] {
  cursor: pointer;
}

`;

export default PageletCss;
