import React, {Component, useContext} from 'react';
import {KitSpinner} from '@chargepoint/cp-toolkit';
import cloneDeep from 'lodash/cloneDeep';
import Intro from '../Sections/Intro';
import SubscriberInfoSection from '../Sections/SubscriberInfo';
import PaymentInfoSection from '../Sections/PaymentInfo';
import Remitance from '../Sections/Remitance';
import BankInfo from '../Sections/BankInfo';
import {
  getDefaultCurrencyOfCountry,
  getFlexBillingCountryConfig,
  getFlexBillingDetails,
  getUserInfoByToken
} from '../Actions/flexbilling';
import {FlexFormIntent, MainState} from './main';
import {
  AccountType,
  Address,
  CloudPlanData,
  FlexBillingData,
  FlexCountryConfig,
  flxDetailsToMainStateMapper,
  getFlexFormFields,
  getWizardFlexFormFields,
  RemitAccountType,
  userDetailsToMainStateMapper
} from './utils';
import {flexbillingCacheFactory} from '../Actions/flexbilling-cache';
// Components
import ButtonSet from '../Components/ButtonSet';
// Utils and Styles
import styles from './main.module.scss';
import {ServerMsg} from '../Components/ServerMsg';
import {useParams} from 'react-router-dom';
import {FlexBillingOption} from '../Sections/Intro/intro';
import {SessionContext, SessionState} from '@chargepoint/common/hooks/SessionContext';
import SubscriberAgreement from "../Sections/SubscriberAgreement";

interface FlexBillingFormProps {
  orgId?: string;
  inviteCode?: string;
  match?: any;
  session: SessionState;
}

class FlexBillingForm extends Component<FlexBillingFormProps, MainState> {
  countryConfigs: Map<string, FlexCountryConfig> = null;

  selectedCountryConfig: FlexCountryConfig = null;

  flexbillingCache = flexbillingCacheFactory.subscribe();

  resetState: MainState;

  state: MainState = {
    showLoader: false,
    countries: [],
    states: [],
    companyCountryId: null,
    reloadCountries: false,
    activateFlexBilling: {
      setUpFlexLater: false,
      isActivate: false,
      canEditVendorId: false,
      orgName: '',
      cpOrgId: '',
      companyOrganizationId: '',
      groupName: '',
      remittanceId: '',
      oldRemittanceId: '',
      fixedFee: '0.00',
      oldFixedFee: null,
      variableFee: '0.00',
      oldVariableFee: null,
      description: null,
      paymentOption: FlexBillingOption.HOLD_MY_FEES,
      optOutReason: null,
      applyDues: true
    },
    subscriberInfo: {
      legalName: '',
      address: new Address(),
      taxId: '',
      taxIdLabel: ''
    },
    companyOrganizationId: '',
    userId: '',
    paymentInfo: {
      currency: '',
      currencyCode: '',
      accountReceivableContact: {
        name: '',
        email: '',
        phone: '',
        countryDialCode: ''
      },
      showACHCheckingInfo: false
    },
    ACHInfo: {
      bankName: '',
      showAddress: true,
      bankAddress: new Address(),
      bankNumberId: null,
      bankNumberIdLabel: '',
      bankRoutingId: null,
      bankSwiftCode: null,
      bankTransitId: null,
      bankIBAN: null,
      branchName: null,
      accountNumber: '',
      accountType: AccountType.Checking,
      remittanceAddress: null,
      remitAccountType: RemitAccountType.ACH,
      achAggreement: ''
    },
    subscriberAgreement: {
      date: '',
      initials: '',
      name: '',
      title: ''
    },
    isNoc: false,
    countryConfig: null,
    flexBillingProps: {
      flexbillingId: '',
      deviceGroupId: '',
      deviceGroupName: null,
      deviceGroupVendorId: null,
      remitOption: null,
      task: null,
      formIntent: FlexFormIntent.Creation,
      currencyCode: null,
      isMFH: 0,
      canEditPriceData: false,
      createNewApprovedCustomGroup: '0',
      orgCountry: ''
    },
    serverMsg: null,
    dataLoaded: false,
    cloudPlanData: {
      postPaidPlanActive: false
    },
    holdMyFeesPolicyUrl: '',
    standaloneFlexBillingTermsUrl: '',
    feeOffsetPolicyUrl: ''
  };

  constructor(props: any) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.flexbillingCache.getSupportedCountries();
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    if (params.orgId) {
      this.setState({
        ...this.state,
        activateFlexBilling: { ...this.state.activateFlexBilling, cpOrgId: params.orgId }
      });
    }
    getFlexBillingCountryConfig().then(countryConfigs => {
      this.countryConfigs = countryConfigs;
    });

    (async () => {
      this.setState({ ...this.state, showLoader: true });
      let tempState = { ...this.state };
      const flexBillingData = window.sessionStorage.getItem('flexBillingData');
      if (flexBillingData != null) {
        const flexBillingObj = JSON.parse(flexBillingData) as FlexBillingData;
        tempState.flexBillingProps.flexbillingId = flexBillingObj.flexBillingId
          ? flexBillingObj.flexBillingId
          : '';
        tempState.flexBillingProps.deviceGroupId = flexBillingObj.flexBillingGroupId
          ? flexBillingObj.flexBillingGroupId
          : '';
        tempState.flexBillingProps.remitOption = flexBillingObj.remitOption
          ? flexBillingObj.remitOption
          : '';
        tempState.flexBillingProps.task = flexBillingObj.task
          ? flexBillingObj.task
          : '';
        tempState.flexBillingProps.createNewApprovedCustomGroup = flexBillingObj.createNewApprovedCustomGroup
          ? flexBillingObj.createNewApprovedCustomGroup
          : '0';
        tempState.flexBillingProps.orgCountry = flexBillingObj.orgCountry
          ? flexBillingObj.orgCountry
          : '';

        if (flexBillingObj.groupName) {
          tempState.activateFlexBilling.groupName = flexBillingObj.groupName;
          tempState.flexBillingProps.deviceGroupName = flexBillingObj.groupName;
        }
      }
      tempState.countries = await this.flexbillingCache.getSupportedCountries();
      let userCountryId;

      if (typeof params.orgId !== 'undefined') {
        const flxDetails = await getFlexBillingDetails(
          tempState.flexBillingProps.flexbillingId,
          tempState.flexBillingProps.deviceGroupId,
          params.orgId
        );
        const hasCountry = tempState.countries.find(country => {
          return country.id === Number(flxDetails.company_country_id);
        });

        if (!hasCountry) {
          this.flexbillingCache.clearCountryCache();
          tempState.countries = await this.flexbillingCache.getSupportedCountries(
            flxDetails.company_country_id
          );
          tempState.reloadCountries = true;
        }

        tempState.countries = await this.flexbillingCache.getSupportedCountries();
        tempState = await flxDetailsToMainStateMapper(tempState, flxDetails);
        window.sessionStorage.removeItem('flexBillingData');
        if (
          this.state.subscriberInfo.address.countryId !== -1 &&
          typeof this.state.subscriberInfo.address.countryId !== 'undefined'
        ) {
          userCountryId = this.state.subscriberInfo.address.countryId;
        }
      } else {
        const userDetails = await getUserInfoByToken(params.inviteCode);
        tempState = await userDetailsToMainStateMapper(tempState, userDetails);
        userCountryId = tempState.subscriberInfo.address.countryId
          ? tempState.subscriberInfo.address.countryId
          : tempState.countries[0].id;

        const currency = await getDefaultCurrencyOfCountry(userCountryId);
        tempState.paymentInfo.currencyCode = currency.code;
        tempState.paymentInfo.currency = currency.label;
      }

      if (userCountryId > 0) {
        tempState = {
          ...tempState,
          countryConfig: this.countryConfigs[`country_id_${userCountryId}`]
        };
      }

      tempState.showLoader = false;
      tempState.dataLoaded = true;

      const cloudPlanData = window.sessionStorage.getItem('cloudPlanData');
      if (cloudPlanData != null) {
        const cloudPlanDataObj = JSON.parse(cloudPlanData) as CloudPlanData;
        tempState.cloudPlanData.postPaidPlanActive = cloudPlanDataObj.postPaidPlanActive ?? false;
      }

      this.setState(tempState);
      this.resetState = cloneDeep(tempState);
    })();

    if (typeof params.inviteCode !== 'undefined') {
      document.addEventListener('react-invite-save', this.updateFlexbilling);
      document.getElementById('country_org').addEventListener('change', this.onOrgCountryChange);
    }
  }

  onOrgCountryChange = event => {
    const countryId = event.target.value;

    if (countryId > 0) {
      (async () => {
        const currency = await getDefaultCurrencyOfCountry(countryId);
        const tempState = { ...this.state };
        tempState.paymentInfo.currencyCode = currency.code;
        tempState.paymentInfo.currency = currency.label;
        this.setState(tempState);
      })();
    }
  };

  onChange(key: string, replacementObj: any) {
    const stateCopy: MainState = { ...this.state };
    if (key === 'setUpFlexLater'
      && replacementObj.paymentOption === FlexBillingOption.MAIL_A_CHECK
      && typeof this.props.match.params.inviteCode !== 'undefined') {

      let inputEl: HTMLInputElement;
      if (this.state.subscriberInfo.legalName === '') {
        inputEl = document.getElementById('org_legal_entity_name') as HTMLInputElement;
        stateCopy.subscriberInfo.legalName = inputEl.value;
      }
      if (this.state.subscriberInfo.address.line1 === '') {
        inputEl = document.getElementById('user_add1') as HTMLInputElement;
        stateCopy.subscriberInfo.address.line1 = inputEl.value;
      }
      if (this.state.subscriberInfo.address.line2 === '') {
        inputEl = document.getElementById('user_add2') as HTMLInputElement;
        stateCopy.subscriberInfo.address.line2 = inputEl.value;
      }
      if (!this.state.subscriberInfo.address.countryId) {
        inputEl = document.getElementById('country') as HTMLInputElement;
        stateCopy.subscriberInfo.address.countryId = parseInt(inputEl.value);
      }
      if (!this.state.subscriberInfo.address.stateProvinceId) {
        inputEl = document.getElementById('state') as HTMLInputElement;
        stateCopy.subscriberInfo.address.stateProvinceId = parseInt(inputEl.value);
      }
      if (this.state.subscriberInfo.address.city === '') {
        inputEl = document.getElementById('user_city') as HTMLInputElement;
        stateCopy.subscriberInfo.address.city = inputEl.value;
      }
      if (this.state.subscriberInfo.address.postalCode === '') {
        inputEl = document.getElementById('user_zip') as HTMLInputElement;
        stateCopy.subscriberInfo.address.postalCode = inputEl.value;
      }
    }
    // @ts-ignore
    stateCopy[key as keyof MainState] = replacementObj;
    this.setState(stateCopy);
  }

  onCountryChange = (countryId: number) => {
    this.setState({ ...this.state, countryConfig: this.countryConfigs[`country_id_${countryId}`] });
  };

  updateFlexbilling = evt => {
    this.setState({ ...this.state, showLoader: true });
    let formData = getFlexFormFields(cloneDeep(this.state));

    if (this.props.match.params.orgId) {
      fetch('/organization/updateFlexBillingInformation', {
        method: 'POST',
        body: formData
      })
        .then(res => {
          return res.json();
        })
        .then(msgObj => {
          if (!msgObj.error_message) {
            // FB updated successfully
            this.resetState = cloneDeep({ ...this.state, showLoader: false });
          }
          document.getElementById('react-app-root').scrollTo(0, 0);
          this.setState({ ...this.state, serverMsg: msgObj, showLoader: false });
        });
    } else {
      let isSaveAndContinue = false;
      if (evt.detail && evt.detail.isSaveAndContinue) {
        isSaveAndContinue = true;
      }

      formData = getWizardFlexFormFields(this.state, isSaveAndContinue);

      fetch('/users/insertFlexBillingDetails', {
        method: 'POST',
        body: formData
      })
        .then(res => {
          return res.json();
        })
        .then(msgObj => {
          if (!msgObj.message && msgObj.content) {
            msgObj.message = msgObj.content;
          }

          this.setState({ ...this.state, serverMsg: msgObj, showLoader: false });
          document.getElementById('react-app-root').scrollTo(0, 0);

          if (!msgObj.error_message && typeof window.createMSSA !== 'undefined') {
            window.createMSSA();
          }
        });
    }

    evt.preventDefault();
  };

  componentWillUnmount() {
    flexbillingCacheFactory.unsubscribe();
  }

  resetForm = () => {
    this.setState(cloneDeep(this.resetState));
  };

  render() {
    const { isNoc } = this.state;
    const { session } = this.props;

    return (
      <>
        {this.state.serverMsg && (
          <ServerMsg
            error_message={this.state.serverMsg.error_message}
            message={this.state.serverMsg.message}
          />
        )}
        <form className={styles.flexbillingForm} onSubmit={this.updateFlexbilling}>
          {this.state.showLoader && <KitSpinner withOverlay />}
          <Intro
            isWelcome={typeof this.props.match.params.inviteCode !== 'undefined'}
            isNoc={isNoc}
            postPaidPlanActive={this.state.cloudPlanData.postPaidPlanActive}
            formIntent={this.state.flexBillingProps.formIntent}
            activateFlexBilling={this.state.activateFlexBilling}
            canEditPrice={this.state.flexBillingProps.canEditPriceData}
            deviceGroupId={this.state.flexBillingProps.deviceGroupId}
            holdMyFeesPolicyUrl={this.state.holdMyFeesPolicyUrl}
            standaloneFlexBillingTermsUrl={this.state.standaloneFlexBillingTermsUrl}
            feeOffsetPolicyUrl={this.state.feeOffsetPolicyUrl}
            resetForm={this.resetForm}
            onChange={this.onChange}
          />
          <fieldset disabled={isNoc}>
            <SubscriberInfoSection
              hide={this.state.activateFlexBilling.setUpFlexLater
                || (session?.isFlexBillingHoldMyFeesServiceEnabled
                  && this.state.activateFlexBilling.paymentOption === FlexBillingOption.HOLD_MY_FEES)}
              subscriberInfo={this.state.subscriberInfo}
              reloadCountries={this.state.reloadCountries}
              hideAddress={false}
              onChange={this.onChange}
              onCountryChange={this.onCountryChange}
              countryConfig={this.state.countryConfig}
              flexBillingId={this.state.flexBillingProps.flexbillingId}
            />
            {this.state.dataLoaded && !this.state.activateFlexBilling.setUpFlexLater && (
              <PaymentInfoSection // loading this last for better ux
                hide={this.state.activateFlexBilling.setUpFlexLater
                  || (session?.isFlexBillingHoldMyFeesServiceEnabled
                    && (this.state.activateFlexBilling.paymentOption === FlexBillingOption.HOLD_MY_FEES
                    || this.state.activateFlexBilling.paymentOption === FlexBillingOption.MAIL_A_CHECK))}
                paymentInfo={this.state.paymentInfo}
                onChange={this.onChange}
              />
            )}
            {this.state.dataLoaded && (
              <Remitance
                hide={this.state.activateFlexBilling.setUpFlexLater
                  || (session?.isFlexBillingHoldMyFeesServiceEnabled
                    && this.state.activateFlexBilling.paymentOption !== FlexBillingOption.OPT_OUT)}
                bankInfo={this.state.ACHInfo}
                activateFlexBilling={this.state.activateFlexBilling}
                onChange={this.onChange}
                isNoc={isNoc}
                countryConfig={this.state.countryConfig}
              />
            )}
            {(this.state.ACHInfo.remitAccountType === RemitAccountType.ACH
              || (session?.isFlexBillingHoldMyFeesServiceEnabled
                && this.state.activateFlexBilling.paymentOption === FlexBillingOption.NOW)) && (
              <BankInfo
                hide={this.state.activateFlexBilling.setUpFlexLater
                  || (session?.isFlexBillingHoldMyFeesServiceEnabled
                    && this.state.activateFlexBilling.paymentOption !== FlexBillingOption.NOW)}
                bankInfo={this.state.ACHInfo}
                reloadCountries={this.state.reloadCountries}
                onChange={this.onChange}
                onCountryChange={this.onCountryChange}
                countryConfig={this.state.countryConfig}
              />
            )}
            <SubscriberAgreement
              hide={this.state.activateFlexBilling.setUpFlexLater
                || session?.isFlexBillingHoldMyFeesServiceEnabled}
              onChange={this.onChange}
              subscriberAgreement={this.state.subscriberAgreement}
            />
            {this.props.match.params.orgId && !isNoc && (
              <ButtonSet
                cancelFunction={e => {
                  e.preventDefault();
                  document.dispatchEvent(new CustomEvent('flexbilling-canceled'));
                }}
                resetFunction={e => {
                  this.resetForm();
                  e.preventDefault();
                }}
              />
            )}
          </fieldset>
        </form>
      </>
    );
  }
}

const withRouter = WrappedComponent => props => {
  const params = useParams();
  const match = { params };
  const { session } = useContext(SessionContext);

  return (
    <WrappedComponent
      {...props}
      match={match}
      session={session}
    />
  );
};

export default withRouter(FlexBillingForm);
