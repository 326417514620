import { Country } from '@chargepoint/admin/src/flex-billing/utils/statesCountries';
import { SessionState } from '../hooks/SessionContext';
import { EMVData, ReceiptData } from '@chargepoint/web-common';
import { NOTIFICATION_TYPE, RequestType } from '@chargepoint/admin/src/common/constants';

interface ContactInfo {
  user_id: string;
  email: string;
  city: string;
  country_id: string;
  address1: string;
  phone_code?: string;
  phone?: string;
  cell_phone_code?: string;
  cell_phone?: string;
  fax_code?: string;
  fax?: string;
  pref_lang?: string;
  address2?: string;
  state_id?: string;
  zipcode?: string;
}

interface AccountInfo {
  account_number: string;
  first_name: string;
  id: string;
  is_sso_migrated: boolean
  last_name: string;
  org_name?: string;
  username: string;
}

class Account {
  accountAPIServer: string;

  accountAPIServerV2: string;

  locale: string;

  initService(session: SessionState) {
    this.accountAPIServer = session.accountsAPI;
    this.locale = session.prefLangLocale;
  }

  getHeaders = (locale: string, options = {}) => {
    return {
      ...options,
      'Accept-Language': locale
    };
  };

  makePost = (url: string, data: any, locale: string) => {
    return fetch(url, {
      method: RequestType.POST,
      headers: {
        'Accept-Language': locale
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .catch(error => error);
  };

  getCountries = async (extraCountryId?: string): Promise<Array<Country>> => {
    const locale = this.getLocale();
    const accountApiEndpoint = this.getAccountApiEndpoint();

    let url = `${accountApiEndpoint}metadata/countries`;
    if (extraCountryId) {
      url += `?countryIdForLegacyCountries=${extraCountryId}`;
    }
    return fetch(url, {
      headers: this.getHeaders(locale)
    }).then(resp => {
      return resp.json();
    });
  };

  getStates = async (countryId: string | number) => {
    const accountApiEndpoint = this.getAccountApiEndpoint();
    const locale = this.getLocale();
    return fetch(`${accountApiEndpoint}metadata/countries/${countryId}/states`, {
      headers: this.getHeaders(locale)
    }).then(resp => {
      return resp.json();
    });
  };

  getAllCountries = async () => {
    const accountApiEndpoint = this.getAccountApiEndpoint();
    const locale = this.getLocale();
    return fetch(`${accountApiEndpoint}metadata/countries?onlySupported=false`, {
      headers: this.getHeaders(locale)
    })
      .then(resp => {
        if (!resp.ok) {
          throw new Error('cannot get countries list');
        }
        return resp.json();
      })
      .catch(error => error);
  };

  getNotificationDetails = async (id: number | string) => {
    const url = `/backend.php/organizations/userprofile/notification_contact_info/${id}`;
    const locale = this.getLocale();
    return fetch(url, {
      headers: this.getHeaders(locale)
    })
      .then(resp => {
        if (!resp.ok) {
          throw new Error();
        }
        return resp.json();
      })
      .catch(error => error);
  };

  getUserAccessDetails = async (id: number | string) => {
    const url = `/backend.php/organizations/userprofile/user_access/${id}`;
    const locale = this.getLocale();
    return fetch(url, {
      headers: this.getHeaders(locale)
    })
      .then(resp => {
        if (!resp.ok) {
          throw new Error();
        }
        return resp.json();
      })
      .catch(error => error);
  };

  getUserContactDetails = async (id: number | string) => {
    const url = `/backend.php/organizations/userprofile/contact_info/${id}`;
    const locale = this.getLocale();
    return fetch(url, {
      headers: this.getHeaders(locale)
    })
      .then(resp => {
        if (!resp.ok) {
          throw new Error();
        }
        return resp.json();
      })
      .catch(error => error);
  };

  getAvailableLanguages = async () => {
    const url = '/backend.php/organizations/userprofile/languages/';
    const locale = this.getLocale();
    return fetch(url, {
      headers: this.getHeaders(locale)
    })
      .then(resp => resp.json())
      .catch(error => error);
  };

  getAccountDetails = async (id: number | string): Promise<AccountInfo> => {
    const url = `/backend.php/organizations/userprofile/account_info/${id}`;
    const locale = this.getLocale();
    return fetch(url, {
      headers: this.getHeaders(locale)
    })
      .then(resp => {
        if (!resp.ok) {
          throw new Error();
        }
        return resp.json();
      })
      .catch(error => error);
  };

  updateContactDetails = async body => {
    const url = '/backend.php/organizations/userprofile/contact_info';
    const locale = this.getLocale();

    const {
      userId,
      email,
      address1,
      address2,
      city,
      countryId,
      stateId,
      zipcode,
      phone,
      phoneCode,
      fax,
      faxCode,
      cellPhone,
      cellPhoneCode,
      prefLang
    } = body;

    const data: ContactInfo = {
      user_id: userId,
      email,
      address1,
      address2,
      city,
      country_id: countryId,
      state_id: stateId,
      zipcode,
      phone_code: phoneCode,
      phone,
      fax_code: faxCode,
      fax,
      cell_phone_code: cellPhoneCode,
      cell_phone: cellPhone,
      pref_lang: prefLang
    };

    return this.makePost(url, data, locale);
  };

  refreshLangCache = async () => {
    const url = '/index.php/nghelper/refresh_session_obj/lang';
    const locale = this.getLocale();
    return fetch(url, {
      headers: this.getHeaders(locale)
    })
      .then(resp => resp.json())
      .catch(error => error);
  };

  updateNotificationDetails = async body => {
    const url = '/backend.php/organizations/userprofile/notification_contact_info';
    const locale = this.getLocale();

    const { id, email, number, code } = body;
    const data = {
      user_id: id,
      notify_info: {
        email,
        phone: {
          number: number || '',
          country_code: code || ''
        }
      }
    };

    return this.makePost(url, data, locale);
  };

  updatePassword = async body => {
    const url = '/backend.php/organizations/userprofile/change_password';
    const locale = this.getLocale();

    const { userId, oldPass, newPass, newPassRepeated } = body;
    const data = {
      user_id: userId,
      current_password: oldPass,
      new_password: newPass,
      confirm_password: newPassRepeated
    };

    return fetch(url, {
      method: RequestType.POST,
      headers: this.getHeaders(locale),
      body: JSON.stringify(data)
    })
      .then(resp => resp.json())
      .catch(error => error);
  };

  updateAccountDetails = async body => {
    const url = '/backend.php/organizations/userprofile/account_info';
    const locale = this.getLocale();

    const { id, firstName, lastName } = body;
    const data = {
      user_id: id,
      first_name: firstName,
      last_name: lastName
    };

    return fetch(url, {
      method: RequestType.POST,
      headers: this.getHeaders(locale),
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .catch(error => error);
  };

  getReceiptData = async (vehicleChargeId: string): Promise<ReceiptData> => {
    const accountApiEndpoint = this.getAccountApiEndpoint();
    return fetch(`${accountApiEndpoint}receipt/${vehicleChargeId}`, {
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      return res.json();
    });
  };

  getReceiptEMVData = async (vehicleChargeId: string): Promise<EMVData> => {
    const accountApiEndpoint = this.getAccountApiEndpoint();
    return fetch(`${accountApiEndpoint}receipt/payment/${vehicleChargeId}`, {
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      return res.json();
    });
  };

  getNotifications = async (
    id: string | number,
    type: NOTIFICATION_TYPE
  ): Promise<any> => {
    const locale = this.getLocale();
    return fetch(`/backend.php/organizations/userprofile/notification_${type}_setting/${id}`, {
      cache: 'no-cache',
      credentials: 'include',
      headers: this.getHeaders(locale, { 'Content-Type': 'application/json' })
    }).then(res => res.json());
  };

  updateNotifications = async (body, type: NOTIFICATION_TYPE): Promise<any> => {
    const url = `/backend.php/organizations/userprofile/notification_${type}_setting`;
    const locale = this.getLocale();
    const { id, notifications } = body;
    const data = {
      user_id: parseInt(id, 10),
      [type]: notifications
    };
    return this.makePost(url, data, locale);
  };

  getAccountApiEndpoint() {
    if (!this.accountAPIServer) {
      throw new Error('accountAPIServer is not set');
    }
    return this.accountAPIServer;
  }

  getLocale() {
    if (!this.locale) {
      throw new Error('locale is not set');
    }
    return this.locale;
  }
}

export const AccountService = new Account();
