import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KitNavBarProps, KitNavBar } from '@chargepoint/cp-toolkit';
import { SessionContext } from '@chargepoint/common/hooks/SessionContext';
import RegionSwitcher from './RegionSwitcher';
import GlobalFilter from './GlobalFilter';
import { checkIfAdminAccount, createNavItem } from '../common/utils';
import { ADMIN_ACCOUNT_URL, TRAINING_VIDEOS } from '../common/constants';
import styled from "styled-components";

const Icon = styled.svg`
  fill: ${({ theme }) => theme.page.body.text};
  height: 1.2rem;
  width: 1.2rem;
  margin-left: 0.5rem;
  display: inline-block;
  position: absolute;
  margin-top: -0.2rem;
`;

type TType = {
  t: (string: any, any?: any) => string;
};

interface AppInfo {
  id: string;
  org_id: string;
  title: string;
  url: string;
}

const NgNavBar: React.FC<any> = () => {
  const { t } = useTranslation() as TType;
  const { session } = useContext(SessionContext);
  let previousMenuIndex = window.sessionStorage.getItem('selected-nav-index') || 0;
  previousMenuIndex = Number(previousMenuIndex);
  const isAdminAccount = checkIfAdminAccount(window.location.pathname);
  const [activeNavIndex, setActiveNavIndex] = useState(previousMenuIndex);
  const [settingsLink, setSettingsLink] = useState('');
  const [appsLinks, setAppLinks] = useState(null);
  let helpEndpoint = null;

  const init = () => {
    document.addEventListener('set-nav-index', updateNavIndex);

    return () => {
      document.removeEventListener('set-nav-index', updateNavIndex);
    };
  };

  const updateNavIndex = e => {
    setActiveNavIndex(e.detail.navIndex);
  };

  const reloadAppLinks = () => {
    if (isAdminAccount) {
      return;
    }
    fetch('/backend.php/coulombuser/get_user_apps')
      .then(res => res.json())
      .then(res => {
        if (res.content && res.content.apps) {
          updateNavLinks(res.content.apps);
        }
      });
  };

  useEffect(init, []);

  useEffect(reloadAppLinks, [session.prefLangLocale]);

  const logout = () => {
    window.sessionStorage.removeItem('show-react-nav');
    window.sessionStorage.removeItem('sessionObj');
    window.location.replace('/logout');
  };

  const showHelp = () => {
    window.location.replace(helpEndpoint);
  };

  const navigateToAccount = () => {
    if (isAdminAccount || !session.featureFlags.getFlag('showNewAccountSettings')) {
      window.location.href = settingsLink;
      return;
    }
    window.open(settingsLink, '_blank');
  };

  const navProps: KitNavBarProps = {
    navLinks: [
      {
        name: '',
        path: ''
      }
    ],
    apps: null,
    username: '',
    onLogoutFunc: logout,
    onHelpFunc: showHelp,
    onAccountFunc: navigateToAccount,
    t: t,
    useReactRouter: false,
    openAppInNewTab: false,
    sortApps: true,
    displayPoweredBy: false,
    logo: {
      mobileLogoUrl: null,
      desktopLogoUrl: null,
      altText: null,
      ariaDescription: null
    }
  };

  if (session) {
    if (session.nav) {
      navProps.navLinks = getTopLevelNav(session.nav);
      helpEndpoint = getHelpUrl(session.nav);
    }
    if (session.userInfo) {
      navProps.username = session.userInfo.userFullName;
    }
    if (session.coBrandShowPoweredBy) {
      navProps.displayPoweredBy = session.coBrandShowPoweredBy;
    }
    if (session.coBrandLogoDesktopImg) {
      navProps.logo.desktopLogoUrl = session.coBrandLogoDesktopImg;
    }
    if (session.coBrandLogoMobileImg) {
      navProps.logo.mobileLogoUrl = session.coBrandLogoMobileImg;
    }
    if (session.coBrandLogoName) {
      navProps.logo.altText = session.coBrandLogoName;
    }
  }

  useEffect(() => {
    if (session.featureFlags.getFlag('showNewAccountSettings')) {
      setSettingsLink(ADMIN_ACCOUNT_URL);
    } else {
      setSettingsLink(`/organization/ViewAdmin/${session.userInfo.userId}`);
    }
  }, [session.featureFlags, session.userInfo.userId]);

  function getHelpUrl(nav: any) {
    return (nav || []).find(navItem => navItem.Action === TRAINING_VIDEOS)
      ? `/${TRAINING_VIDEOS}`
      : null;
  }

  function getSupportLinkTitle() {
    return <>
      {t('support_center')}
      <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
        <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h560v-280h80v280q0 33-23.5 56.5T760-120H200Zm188-212-56-56 372-372H560v-80h280v280h-80v-144L388-332Z"/>
      </Icon>
    </>;
  }

  function getTopLevelNav(nav: any) {
    if (isAdminAccount) {
      return null;
    }

    const topLevelNav = [];

    const generateClickHandler = route => {
      return e => {
        if (document.getElementsByTagName('app-root').length === 0) {
          return;
        }
        if (e.preventDefault) {
          e.preventDefault();
        }
        document.dispatchEvent(
          new CustomEvent('load-ng-route', {
            detail: { route: route }
          })
        );
      };
    };
    for (let x = 0; x < nav.length; x++) {
      if (nav[x].Action !== TRAINING_VIDEOS) {
        topLevelNav.push({
          name: nav[x].Text,
          path: nav[x].Action.charAt(0) === '/' ? nav[x].Action : `/${nav[x].Action}`,
          onSelect: generateClickHandler(nav[x].Action)
        });
      }
    }
    if (session.featureFlags.getFlag('showSupportCenterLink')) {
      topLevelNav.push({
        name: getSupportLinkTitle(),
        path: session.supportCenterURL,
        target: '_blank'
      });
    }
    return topLevelNav;
  }

  function updateNavLinks(remoteNavLinks) {
    const apps = [];
    Object.entries(remoteNavLinks).forEach(([appKey, appInfo], index) => {
      const count = (appInfo as AppInfo[]).length;
      (appInfo as AppInfo[]).forEach(({ id, url, title, org_id }) => {
        apps.push(createNavItem({ url, title, org_id, count, appKey, t }));
      });
    });
    setAppLinks(apps);
  }

  if (!session || !session.userInfo || !session.isLoggedIn) {
    return null;
  }

  if (isAdminAccount) {
    return <KitNavBar {...navProps} activeNavLinkIndex={activeNavIndex} />;
  }

  return (
    <KitNavBar {...navProps} apps={appsLinks} activeNavLinkIndex={activeNavIndex}>
      <GlobalFilter />
      <RegionSwitcher />
    </KitNavBar>
  );
};

export default NgNavBar;
