import React from 'react';
// IE11 START
import 'react-app-polyfill/ie11';
import 'url-search-params-polyfill';
import 'formdata-polyfill';
import 'shim-selected-options';
import 'element-scroll-polyfill';
import 'proxy-polyfill';
import 'core-js/stable/reflect';
// END
import App from './App';
import AppPHP from './AppPHP';
import './Intl/i18n';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';

const containers = new Map();
function loadReact(rootName: string) {
  if (containers.has(rootName)) {
    containers.get(rootName).render(<App />);
  } else {
    const container = document.getElementById(rootName);
    const root = createRoot(container);
    root.render(<App />);
    containers.set(rootName, root);
  }
}

window['loadReact'] = loadReact;

if (document.getElementById('react-app-root')) {
    loadReact('react-app-root');
} else if (document.getElementById('php-react-app-root')) {
  const container = document.getElementById('php-react-app-root');
  const root = createRoot(container);
  root.render(<AppPHP />);
}



document.addEventListener('react-reload', function() {
  if (
    document.getElementById('react-app-root') &&
    document.getElementById('react-app-root').innerHTML === ''
  ) {
    loadReact('react-app-root');
  } else if (
    document.getElementById('php-react-app-root') &&
    document.getElementById('php-react-app-root').innerHTML === ''
  ) {
    const container = document.getElementById('php-react-app-root');
    const root = createRoot(container);
    root.render(<AppPHP />);
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
