import { BankInfo } from '../Sections/BankInfo/bankInfo';
import { SubscriberInfo } from '../Sections/SubscriberInfo/subscriberinfo';
import { ActivateFlexBilling } from '../Sections/Intro/intro';
import { SubscriberAgreement } from './utils';
import { FlexCountryConfig } from './utils';
import { Country } from '../utils/statesCountries';
import { PaymentInfo } from '../Sections/PaymentInfo/paymentinfo';
import { CloudPlanData } from './utils';

export interface MainState {
  showLoader: boolean;
  countries?: Array<Country>;
  states?: Array<{ name: string; id: number; originalName: string; }>;

  activateFlexBilling?: ActivateFlexBilling;

  subscriberInfo: SubscriberInfo;
  companyOrganizationId: string;
  companyCountryId: string;
  reloadCountries: boolean;
  userId: string;
  paymentInfo: PaymentInfo;
  ACHInfo: BankInfo;
  subscriberAgreement: SubscriberAgreement;
  isNoc: boolean;
  flexBillingProps: {
    flexbillingId?: string;
    deviceGroupId?: string;
    deviceGroupName?: string;
    deviceGroupVendorId?: string;
    remitOption?: string;
    task?: string;
    formIntent: FlexFormIntent;
    currencyCode: string | null;
    isMFH: number;
    canEditPriceData: boolean;
    createNewApprovedCustomGroup?: string;
    orgCountry?: string;
  };
  countryConfig: FlexCountryConfig | null;
  serverMsg?: {
    error_message: boolean;
    message: string;
  }
  dataLoaded: boolean;
  cloudPlanData: CloudPlanData;
  holdMyFeesPolicyUrl: string;
  standaloneFlexBillingTermsUrl: string;
  feeOffsetPolicyUrl: string;
}

export enum FlexFormIntent {
  Creation = 0,
  Update = 1,
  Activate = 1
}
