export const DEFAULT_CORPORATE_URL = 'https://www.chargepoint.com';
export const CORPORATE_SUPPORT_URL = '/support/';
export const CORPORATE_DRIVER_FAQ_URL = '/support/driver-faq/';
export const RECEIPT_EMV_DATA_ROLES = ['csr_admin', 'noc_level_2', 'noc_level_3', 'super_user'];

class NosBackend {
  getLocaleCorporateUrl(locale: string): Promise<string> {
    const formData = new FormData();
    formData.set('locale', locale);
    return fetch('/backend.php/coulombuser/get_locale_corporate_url', {
      method: 'POST',
      body: formData
    })
      .then(r => r.json())
      .then(result => {
        if (result.content && result.content.lang_info && result.content.lang_info.length > 0) {
          return `https://${result.content.lang_info[0].lang_corporate_url}`;
        }
        return DEFAULT_CORPORATE_URL;
      });
  }
}

export const NosBackendService = new NosBackend();
