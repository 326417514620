import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldProps } from './Field';
import { Select as StyledSelect } from './styled';
import { normalizeSelectOption } from './utils';

type SelectProps = FieldProps & { options: any[] };

export function Select(props: SelectProps) {
  const { name, label, options, ...rest } = props;
  const { t } = useTranslation();

  return (
    <Field {...rest} name={name} label={label} as={StyledSelect}>
      {options.map(option => {
        const { label, value } = normalizeSelectOption(option);

        return (
          <option key={value} value={value}>
            {t(`videoAd.${label as string}`)}
          </option>
        );
      })}
    </Field>
  );
}
