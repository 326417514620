import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { ThemeConstants, ThemeColors } from '@chargepoint/cp-toolkit';

const { spacing, fontSize } = ThemeConstants;

const Heading = styled.div`
  background-color: ${({ theme }) => theme.page.body.bg};
  border-bottom: 1px solid ${ThemeColors.gray_20};
  padding: ${spacing.absolute.l}px ${spacing.absolute.xl}px;
  @media all and (max-width: ${ThemeConstants.breakpoints.md}px) {
    padding: ${spacing.absolute.sm}px ${spacing.absolute.m}px;
  }
  & p {
    font-size: ${fontSize.text_14}rem;
  }
  & h1 {
    font-size: ${({ theme }) => theme.headingFont.fontSize.h4}rem;
  }
  & h2 {
    font-size: ${({ theme }) => theme.headingFont.fontSize.h5}rem;
  }
  & h3 {
    font-size: ${({ theme }) => theme.headingFont.fontSize.h6}rem;
  }
`;

export interface PageHeadingProps {
  className?: string;
  children?: ReactNode;
}

const PageHeading: FC<PageHeadingProps> = ({ className, children }) => {
  return <Heading className={className}>{children}</Heading>;
};

export default PageHeading;

export const PageHeadingNoLine = styled(PageHeading)`
  border-bottom: none;
  padding-bottom: 0;
`;