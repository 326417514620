export function getLocaleName(locale) {
  let result = 'en-US';
  // iOS can set only 'de' in webview
  const mainLocale = locale.substring(0, 2).toLowerCase();

  switch (mainLocale) {
    case 'en' :
      if (locale === 'en-GB' || locale === 'en-AU' || locale === 'en-NZ') {
        result = 'en-GB';
      } else {
        // en-CA
        result = 'en-US';
      }
      break;
    case 'fr' :
      if (locale === 'fr-CA' ) {
        result = 'fr-CA';
      } else {
        result = 'fr-FR';
      }
      break;
    case 'es' :
      result = 'es-ES';
      break;
    case 'de' :
      result = 'de-DE';
      break;
    case 'it' :
      result = 'it-IT';
      break;
    case 'nl' :
      result = 'nl-NL';
      break;
    case 'sv' :
      result = 'sv-SE';
      break;
    case 'nb' :
      result = 'nb-NO';
      break;
    case 'da' :
      result = 'da-DK';
      break;
    case 'pt' :
      result = 'pt-PT';
      break;
    default:
      if (locale) {
        result = locale;
      }
      break;
  }

  return result;
}

export function getBaseLangName(locale) {
  return locale.split('-')[0];
}

