import React, { Component, Fragment } from 'react';
import HelpLine from '../HelpLine';
import { HelpGroupProps } from './helpgroup';
import { Translation } from 'react-i18next'

class HelpGroup extends Component<HelpGroupProps> {
  render() {
    return (
      <Translation>
        {t => (
          <Fragment>
            <tr>
              <th colSpan={4}>{t(this.props.name)}</th>
            </tr>
            {
              this.props.content && this.props.content.map((section, i) => {
                return (<HelpLine key={i} row={i + 1} name={t(section.name)} video={section.video} pdf={section.pdf} link={section.link} region={section.region}/>);
              })
            }
          </Fragment>
        )}
      </Translation>
    );
  }
}

export default HelpGroup;
