export function deletePaymentMethod(orgId: number, paymentMethodId: number, connectionIds?: Array<number>) {
  let body  = {} as any;
  if (connectionIds) {
    body.connectionIds = connectionIds;
  }
  return fetch(`/backend.php/fuelcards/organizationpayment/deletePaymentMethod/${orgId}/${paymentMethodId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify(body),
  }).then((response) => {
    return response.json();
  }).then((data) => {
    return data.data;
  });
}

export function getPaymentMethods(orgId: string) {
  return fetch(`/backend.php/fuelcards/organizationpayment/getPaymentMethods/${orgId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({})
  }).then((response) => {
    return response.json();
  }).then((data) => {
    return data.data;
  })
}

export function createPaymentMethod(orgId: string, body: any) {
  return fetch(`/backend.php/fuelcards/organizationpayment/createPaymentMethods/${orgId}`,{
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify(body)
  }).then((response) => {
    return response.json();
  }).then((data) =>{
    return data.data;
   });
}

export function editPaymentMethod(orgId: string, paymentMethodId: number, body: any) {
  return fetch(`/backend.php/fuelcards/organizationpayment/editPaymentMethods/${orgId}/${paymentMethodId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify(body)
  }).then((response) => {
    return response.json();
  }).then((data) =>{
    return data.data;
   });
}

export function getOrgPaymentProviders(currencies: string) {
  return fetch(`/backend.php/fuelcards/getOrgPaymentProviders?currencies=${currencies}`).then((data) => data.json()).then((data) =>{
    return data.response;
  })
}
