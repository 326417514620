import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  KitButton,
  KitFlexWrapRowSpaced,
  KitForm,
  KitSpinner,
  ThemeConstants,
  ThemeColors,
  KitInput,
  KitFlexWrapRow
} from '@chargepoint/cp-toolkit';
import styled from 'styled-components';

const StyledWarning = styled.p`
  color: ${ThemeColors.red_50};
  font-size: ${ThemeConstants.fontSize.base}rem;
  margin-top: 0;
  margin-bottom: ${ThemeConstants.spacing.absolute.l}px;
  margin-right: ${ThemeConstants.spacing.absolute.s}px;
  line-height: 1;
  min-height: 1rem;
`;

const FormWrapper = styled.div`
  margin: ${ThemeConstants.spacing.absolute.sm}px 0 ${ThemeConstants.spacing.absolute.xxl}px;
  min-height: 50px;
  .cp-form-label {
    line-height: ${ThemeConstants.fontSize.text_24}rem;
  }
  .cp-form-label::after {
    left: 5px;
    top: 5px;
  }
`;

const SelectWrapper = styled.div`
  margin-bottom: ${ThemeConstants.spacing.absolute.l}px;
  margin-right: ${ThemeConstants.spacing.absolute.l}px;
  width: 40%;
`;

const InputWrapper = styled.div`
  margin-bottom: ${ThemeConstants.spacing.absolute.xl}px;
  margin-right: ${ThemeConstants.spacing.absolute.l}px;
`;

export interface Props {
  deviceId: string;
  timeZone: string;
  timeZoneOffset: number;
  retries: string;
  retryInterval: string;
  startTime: string;
  stopTime: string;
  onClose: Function;
  sendToIframe: Function;
}

enum Identities {
  CloudAgent = 'cloudagent',
  SessionMgr = 'sessionmgr',
  ChargeManager = 'chargemgr',
  PowerDirector = 'powerdirector',
  PDServer = 'pdserver',
  ChassisServer = 'chassis-server',
  MMIManager = 'mmimanager',
  SafetyLoop = 'dc_safety_loop',
  AutomaticVolumeGainControl = 'avgc',
  Frontend = 'frontend.bin',
  CardReader = 'cardreader',
  Telemetry = 'telemetry',
  Kernel = 'kernel',
  SystemD = 'systemd',
  Trampoline = 'trampolineclient',
  SnifferPCAP = 'sniffer',
  Eichrecht = 'eichrecht',
  EichrechtProxy = 'eichrecht_proxy'
}

enum Severity {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Debug = 'debug'
}

const GetDiagnosticsModalHolder: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [identities, setIdentities] = useState('');
  const [severity, setSeverity] = useState('');
  const [retries, setRetries] = useState(props.retries);
  const [retryInterval, setRetryInterval] = useState(props.retryInterval);
  const [startTime, setStartTime] = useState(props.startTime.replace(' ', 'T'));
  const [stopTime, setStopTime] = useState(props.stopTime.replace(' ', 'T'));
  const [isLoading, setIsLoading] = useState(false);
  const [serverMsg, setServerMsg] = useState('');
  const [response, setResponse] = useState('');
  const date = new Date();
  date.setTime(date.getTime() + props.timeZoneOffset * 1000);
  const today = date.toISOString().substring(0, 19);

  const identitiesOptions = [
    {
      label: t('get_diagnostics.cloud_agent'),
      value: Identities.CloudAgent
    },
    {
      label: t('get_diagnostics.session_mgr'),
      value: Identities.SessionMgr
    },
    {
      label: t('get_diagnostics.charge_manager'),
      value: Identities.ChargeManager
    },
    {
      label: t('get_diagnostics.power_director'),
      value: Identities.PowerDirector
    },
    {
      label: t('get_diagnostics.pd_server'),
      value: Identities.PDServer
    },
    {
      label: t('get_diagnostics.chassis_server'),
      value: Identities.ChassisServer
    },
    {
      label: t('get_diagnostics.mmi_manager'),
      value: Identities.MMIManager
    },
    {
      label: t('get_diagnostics.safety_loop'),
      value: Identities.SafetyLoop
    },
    {
      label: t('get_diagnostics.automatic_vgc'),
      value: Identities.AutomaticVolumeGainControl
    },
    {
      label: t('get_diagnostics.frontend'),
      value: Identities.Frontend
    },
    {
      label: t('get_diagnostics.card_reader'),
      value: Identities.CardReader
    },
    {
      label: t('get_diagnostics.telemetry'),
      value: Identities.Telemetry
    },
    {
      label: t('get_diagnostics.kernel'),
      value: Identities.Kernel
    },
    {
      label: t('get_diagnostics.system_d'),
      value: Identities.SystemD
    },
    {
      label: t('get_diagnostics.trampoline'),
      value: Identities.Trampoline
    },
    {
      label: t('get_diagnostics.sniffer_pcap'),
      value: Identities.SnifferPCAP
    },
    {
      label: t('get_diagnostics.eichrecht'),
      value: Identities.Eichrecht
    },
    {
      label: t('get_diagnostics.eichrecht_proxy'),
      value: Identities.EichrechtProxy
    }
  ];

  const severityOptions = [
    {
      label: t('error'),
      value: Severity.Error
    },
    {
      label: t('warning'),
      value: Severity.Warning
    },
    {
      label: t('info'),
      value: Severity.Info
    },
    {
      label: t('debug'),
      value: Severity.Debug
    }
  ];

  function getDiagnostics() {
    setIsLoading(true);
    const formData = new FormData();

    formData.set('startTime', startTime.replace('T', ' '));
    formData.set('stopTime', stopTime.replace('T', ' '));
    formData.set('min_severity', severity);
    formData.set('identities', identities);
    formData.set('retries', retries);
    formData.set('retryInterval', retryInterval);
    formData.set('deviceId', props.deviceId);
    formData.set('stationTimezone', props.timeZone);

    fetch('/stations/get_diagnostics_request', {
      method: 'POST',
      body: formData
    })
      .then(r => r.json())
      .then(result => {
        if (typeof result.response === 'undefined') {
          setServerMsg(t('server.error'));
        } else {
          setServerMsg('');
          setResponse(result.response);
          if (props.sendToIframe) {
            props.sendToIframe(result);
          }
        }
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return (
      <FormWrapper>
        <KitSpinner />
      </FormWrapper>
    );
  }

  if (!isLoading && response !== '') {
    return (
      <FormWrapper>
        <p>{response}</p>
      </FormWrapper>
    );
  }

  return (
    <KitForm onSubmit={getDiagnostics}>
      <FormWrapper>
        {serverMsg !== '' && <StyledWarning>{serverMsg}</StyledWarning>}
        <KitFlexWrapRow>
          <SelectWrapper>
            <KitForm.Label text={t('get_diagnostics.retries')} htmlFor='retries' />
            <KitInput
              required
              min='1'
              type='number'
              id='retries'
              enterKeyHint='enter'
              value={retries}
              onChange={e => {
                setRetries(Math.abs(Number(e.target.value)).toString());
              }}
            />
          </SelectWrapper>
          <SelectWrapper>
            <KitForm.Label text={t('get_diagnostics.retry_interval')} htmlFor='retryInterval' />
            <KitInput
              required
              min='1'
              type='number'
              id='retryInterval'
              enterKeyHint='enter'
              value={retryInterval}
              onChange={e => {
                setRetryInterval(Math.abs(Number(e.target.value)).toString());
              }}
            />
          </SelectWrapper>
        </KitFlexWrapRow>
        <KitFlexWrapRow>
          <SelectWrapper>
            <KitForm.Select 
            required
            options={identitiesOptions}
            label ={t('get_diagnostics.identities')}
            id='identities'
            components={{
              IndicatorSeparator: null
            }}
            onChange={val => {
              setIdentities(val.value);
            }}
            />
          </SelectWrapper>
          <SelectWrapper>
          <KitForm.Select 
            required
            options={severityOptions}
            id='severity'
            label = {t('get_logs.severity')}
            components={{
              IndicatorSeparator: null
            }}
            onChange={val => {
              setSeverity(val.value);
            }}
            />
            
          </SelectWrapper>
        </KitFlexWrapRow>
        <KitFlexWrapRow>
          <InputWrapper>
            <KitForm.Label text={t('from')} htmlFor='startTime' />
            <KitInput
              required
              type='datetime-local'
              step='1'
              id='startTime'
              max={today}
              enterKeyHint='enter'
              value={startTime}
              onChange={e => {
                setStartTime(e.target.value);
                if (Date.parse(stopTime) < Date.parse(e.target.value)) {
                  setStopTime(today);
                }
              }}
            />
          </InputWrapper>
        </KitFlexWrapRow>
        <KitFlexWrapRow>
          <InputWrapper>
            <KitForm.Label text={t('to')} htmlFor='stopTime' />
            <KitInput
              required
              type='datetime-local'
              step='1'
              id='stopTime'
              min={startTime}
              max={today}
              enterKeyHint='enter'
              value={stopTime}
              onChange={e => {
                setStopTime(e.target.value);
              }}
            />
          </InputWrapper>
        </KitFlexWrapRow>
      </FormWrapper>
      <KitFlexWrapRowSpaced>
        <KitButton
          onClick={() => {
            props.onClose();
          }}
          variant='secondary'
          disabled={isLoading}
        >
          {t('cancel')}
        </KitButton>
        <KitButton variant='primary' type='submit' disabled={isLoading}>
          {t('get')}
        </KitButton>
      </KitFlexWrapRowSpaced>
    </KitForm>
  );
};

export default GetDiagnosticsModalHolder;
