import React, { Component, Fragment } from 'react';
import EnglishOnly from '../EnglishOnly'
import { Translation } from 'react-i18next';
import styles from './style.module.scss';


interface PDFLinkProps {
  englishOnly?: boolean,
  url: string,
  title?: string,
  file?: string
}

class PDFLink extends Component<PDFLinkProps> {
  render() {
    return (
      <Translation>
        {t => (
          <Fragment>
            <a className={styles.PDFLink} href={this.props.url} target='_blank' rel='noopener noreferrer'>
              {this.props.title || t('link_summary')}
              <img src={`https://${process.env.REACT_APP_DOMAIN}/images/icon_pdf.gif`} alt={this.props.title || t('link_summary') as string} />
            </a>
            {this.props.englishOnly && <EnglishOnly />}
          </Fragment>
        )}
      </Translation>
    );
  }
}

export default PDFLink;
