import React, {Component} from 'react';
import HelpGroup from '../../Components/HelpGroup'
import 'whatwg-fetch';
import styles from './style.module.scss';
import {helpVideosConfig} from '../../../data/helpvideos';
import {useParams} from 'react-router-dom';
import i18n from '../../../Intl/i18n';

export function withParams(Component) {
  return props => <Component {...props} params={useParams()}/>;
}

interface HelpVideosProps {
  params: {
    videoId?: string,
    pdfId?: string,
    lang?: string
  };
}

interface HelpVideosState {
  openLink?: string;
}

class HelpVideos extends Component<HelpVideosProps, HelpVideosState> {
  state: HelpVideosState = {
    openLink: null
  }

  componentDidMount() {
    if (this.props.params.videoId || this.props.params.pdfId) {
      helpVideosConfig.forEach((section) => {
        section.content.forEach((child) => {
          if (this.props.params.videoId && this.props.params.videoId === child.pdf?.id) {
            this.setState({openLink: child.video});
          } else if (this.props.params.pdfId && this.props.params.pdfId === child.pdf?.id) {
            let link = '/UI/s3docs/docs/help/' + child.pdf.id;
            if (child.pdf.versions) {
              if (this.props.params.lang !== 'en-US' && child.pdf.versions.indexOf(this.props.params.lang) > -1) {
                link += '_' + this.props.params.lang;
              } else if (i18n.language !== 'en-US' && child.pdf.versions.indexOf(i18n.language) > -1) {
                link += '_' + i18n.language;
              }
            }
            link += '.pdf';
            this.setState({openLink: link});
          }
        });
      });
    }
  }

  render() {
    if (this.props.params.videoId || this.props.params.pdfId) {
      if (this.state.openLink) {
        window.location.href = this.state.openLink;
      } else {
        window.location.href = '/';
      }

      return null;
    }

    return (
      <div className={styles.HelpVideos}>
        <table>
          {
            helpVideosConfig.map((section, i) => {
              return (<HelpGroup key={i} row={i++} name={section.name} content={section.content} />);
            })
          }
        </table>
      </div>
    );
  }
}

export default withParams(HelpVideos);
