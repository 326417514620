import Pagelet from '@chargepoint/common/components/Pagelet';
import React from 'react';
import FuelCardProviders from '../../fuel-cards/FuelCardProviders';

function FuelCardProvidersPagelet() {
  return (
    <Pagelet>
      <FuelCardProviders />
    </Pagelet>
  );
}

export default FuelCardProvidersPagelet;
