import { SessionContext } from '@chargepoint/common/hooks/SessionContext';
import { useContext } from 'react';
import { sessionObj } from '../Intl/i18n';

const useSession = () => {
  const { session } = useContext(SessionContext);
  if (sessionObj) {
    return sessionObj;
  }
  return session;
};

export default useSession;
