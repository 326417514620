import { HelpGroupContentProps } from "../help-docs/Components/HelpGroup/helpgroup";

interface HelpVideosConfigModel {
  name: string;
  content: HelpGroupContentProps [];
}

export const helpVideosConfig: HelpVideosConfigModel[] = [
  {
    name: "helpvideos.help_0_name",
    content: [
      {
        name: "helpvideos.help_0_content_0",
        video: "https://youtu.be/6o_5Ht6mIv8"
      },
      {
        name: "helpvideos.help_0_content_1",
        video: "https://youtu.be/Uy4daLjOfSI",
        pdf: {
          id: "OrgAdminSetup",
          versions: [
            "en-GB",
            "de-DE",
            "es-ES",
            "fr-FR",
            "fr-CA",
            "it-IT",
            "nl-NL",
            "sv-SE",
            "nb-NO",
            "da-DK"
          ]
        }
      },
      {
        name: "helpvideos.help_0_content_2",
        video: "https://youtu.be/Wmb-V919elI",
        pdf: {
          id: "ActivateStations",
          versions: [
            "en-GB",
            "de-DE",
            "es-ES",
            "fr-FR",
            "fr-CA",
            "it-IT",
            "nl-NL",
            "sv-SE",
            "nb-NO",
            "da-DK"
          ]
        }
      },
      {
        name: "helpvideos.help_0_content_3",
        pdf: {
          id: "TransferTokens",
          versions: [
            "en-GB",
            "de-DE",
            "es-ES",
            "fr-FR",
            "fr-CA",
            "it-IT",
            "nl-NL",
            "sv-SE",
            "nb-NO",
            "da-DK"
          ]
        }
      }
    ]
  },
  {
    name: "helpvideos.help_1_name",
    content: [
      {
        name: "helpvideos.help_1_content_0",
        video: "https://youtu.be/JNxXgdOWgmI",
        pdf: {
          id: "Connections",
          versions: [
            "en-GB",
            "de-DE",
            "es-ES",
            "fr-FR",
            "fr-CA",
            "it-IT",
            "nl-NL",
            "sv-SE",
            "nb-NO",
            "da-DK"
          ]
        }
      },
      {
        name: "helpvideos.help_1_content_1",
        video: "https://youtu.be/zZTx3C5uprQ",
        pdf: {
          id: "ControlAccessToStations",
          versions: [
            "en-GB",
            "de-DE",
            "es-ES",
            "fr-FR",
            "fr-CA",
            "it-IT",
            "nl-NL",
            "sv-SE",
            "nb-NO",
            "da-DK"
          ]
        }
      },
      {
        name: "helpvideos.help_1_content_2",
        video: "https://youtu.be/uJhgR-fs8Yk",
        pdf: {
          id: "CollectPayment",
          versions: [
            "de-DE",
            "es-ES",
            "fr-FR",
            "fr-CA",
            "it-IT",
            "nl-NL",
            "sv-SE",
            "nb-NO",
            "da-DK"
          ]
        }
      },
      {
        name: "helpvideos.help_1_content_3",
        video: "https://youtu.be/GIlRsJnBzaI"
      },
      {
        name: "helpvideos.help_1_content_4",
        video: "https://youtu.be/bTQGugg4LRo",
        pdf: {
          id: "DisplayStationMessages",
          versions: [
            "en-GB",
            "de-DE",
            "es-ES",
            "fr-FR",
            "fr-CA",
            "it-IT",
            "nl-NL",
            "sv-SE",
            "nb-NO",
            "da-DK"
          ]
        }
      },
      {
        name: "helpvideos.help_1_content_5",
        pdf: {
          id: "ApplyVideoAds",
          versions: [
            "en-GB",
            "de-DE",
            "es-ES",
            "fr-FR",
            "fr-CA",
            "it-IT",
            "nl-NL",
            "sv-SE",
            "nb-NO",
            "da-DK"
          ]
        }
      },
      {
        name: "helpvideos.help_1_content_6",
        video: "https://youtu.be/LEY22SRlYZQ",
        pdf: {
          id: "CreateWaitlists",
          versions: [
            "en-GB",
            "de-DE",
            "es-ES",
            "fr-FR",
            "fr-CA",
            "it-IT",
            "nl-NL",
            "sv-SE",
            "nb-NO",
            "da-DK"
          ]
        },
        link: {
          name: "FAQ",
          url: "/waitlistFAQ"
        }
      },
      {
        name: "helpvideos.help_1_content_7",
        pdf: {
          id: "SetupValet",
          versions: [
            "en-GB",
            "de-DE",
            "es-ES",
            "fr-FR",
            "fr-CA",
            "it-IT",
            "nl-NL",
            "sv-SE",
            "nb-NO",
            "da-DK"
          ]
        }
      }
    ]
  },
  {
    name: "helpvideos.help_2_name",
    content: [
      {
        name: "helpvideos.help_2_content_0",
        video: "https://youtu.be/U_QutYilnGo",
        pdf: {
          id: "SetupStationGroups",
          versions: [
            "en-GB",
            "de-DE",
            "es-ES",
            "fr-FR",
            "it-IT",
            "nl-NL",
            "sv-SE",
            "nb-NO",
            "da-DK"
          ]
        }
      },
      {
        name: "helpvideos.help_2_content_1",
        pdf: {
          id: "GrantObjects",
          versions: [
            "en-GB",
            "de-DE",
            "es-ES",
            "fr-FR",
            "fr-CA",
            "it-IT",
            "nl-NL",
            "sv-SE",
            "nb-NO",
            "da-DK"
          ]
        }
      },
      {
        name: "helpvideos.help_2_content_2",
        video: "https://youtu.be/YltvrwY2eBI",
        pdf: {
          id: "GrantRights",
          versions: [
            "en-GB",
            "de-DE",
            "es-ES",
            "fr-FR",
            "fr-CA",
            "it-IT",
            "nl-NL",
            "sv-SE",
            "nb-NO",
            "da-DK"
          ]
        }
      },
      {
        name: "helpvideos.help_2_content_3",
        video: "https://youtu.be/RMdh27qpFvA",
        pdf: {
          id: "GenerateReports",
          versions: [
            "en-GB",
            "de-DE",
            "es-ES",
            "fr-FR",
            "fr-CA",
            "it-IT",
            "nl-NL",
            "sv-SE",
            "nb-NO",
            "da-DK"
          ]
        }
      },
      {
        name: "helpvideos.help_2_content_4",
        pdf: {
          id: "SetupWebServicesAPI",
          versions: [
            "en-GB",
            "de-DE",
            "es-ES",
            "fr-FR",
            "fr-CA",
            "it-IT",
            "nl-NL",
            "sv-SE",
            "nb-NO",
            "da-DK"
          ]
        }
      },
      {
        name: "helpvideos.help_2_content_6",
        pdf: {
          id: "ManageEnergy",
          versions: [
            "en-GB",
            "de-DE",
            "es-ES",
            "fr-FR",
            "fr-CA",
            "it-IT",
            "nl-NL"
          ]
        }
      }
    ]
  },
  {
    name: "helpvideos.help_3_name",
    content: [
      {
        name: "helpvideos.help_3_content_0",
        pdf: {
          id: "ManageYourFleetVehicles",
          versions: [
            "en-GB",
            "de-DE",
            "es-ES",
            "fr-FR",
            "fr-CA",
            "it-IT",
            "nl-NL",
            "sv-SE",
            "nb-NO",
            "da-DK"
          ]
        }
      },
      {
        region: "eu",
        name: "helpvideos.help_3_content_1",
        pdf: {
          id: "ManageYourFleetDrivers",
          versions: [
            "de-DE",
            "es-ES",
            "fr-FR",
            "it-IT",
            "nl-NL",
            "sv-SE",
            "nb-NO",
            "da-DK"
          ]
        }
      }
    ]
  }
];
