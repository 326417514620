import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  KitButton,
  KitFlexWrapRowSpaced,
  KitForm,
  KitRadioGroup,
  KitSpinner,
  ThemeConstants,
  ThemeColors,
  KitSelect
} from '@chargepoint/cp-toolkit';
import styled from 'styled-components';

const StyledWarning = styled.p`
  color: ${ThemeColors.red_50};
  font-size: ${ThemeConstants.fontSize.base}rem;
  margin-top: 0;
  margin-bottom: ${ThemeConstants.spacing.absolute.l}px;
  margin-right: ${ThemeConstants.spacing.absolute.s}px;
  line-height: 1;
  min-height: 1rem;
`;

const FormWrapper = styled.div`
  margin: ${ThemeConstants.spacing.absolute.sm}px 0 ${ThemeConstants.spacing.absolute.xxl}px;
  min-height: 50px;
  .cp-form-label {
    line-height: ${ThemeConstants.fontSize.text_24}rem;
  }
  .cp-form-label::after {
    left: 5px;
    top: 5px;
  }
`;

const SelectWrapper = styled.div`
  max-width: 300px;
  margin-bottom: 15px;
`;

export interface Props {
  onClose: Function;
  type: string;
  deviceId: string;
  isOcpp: string;
}

enum RebootSchedule {
  OnIdle = 'OnIdle',
  Immediate = 'Immediate'
}

const RebootModalHolder: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [powerCycle, setPowerCycle] = useState('0');
  const [rebootSchedule, setRebootSchedule] = useState(RebootSchedule.OnIdle);
  const [isLoading, setIsLoading] = useState(false);
  const [serverMsg, setServerMsg] = useState('');

  if (!props.deviceId) {
    return null;
  }

  const scheduleOptions = [
    {
      label: t('reboot_station.when_idle'),
      value: RebootSchedule.OnIdle
    },
    {
      label: t('reboot_station.immediate'),
      value: RebootSchedule.Immediate
    }
  ];

  function rebootStation() {
    setIsLoading(true);
    const formData = new FormData();
    formData.set('action', 'reboot');
    formData.set('deviceId', props.deviceId);
    formData.set('schedule', rebootSchedule);
    formData.set('is_ocpp', props.isOcpp);
    formData.set('power_cycle', powerCycle);

    fetch('/stations/stationCommand', {
      method: 'POST',
      body: formData
    })
      .then(r => r.json())
      .then(result => {
        setServerMsg(result.response);
        setIsLoading(false);
      });
  }

  function getRadioOptions() {
    if (props.type === 'power_block') {
      return [
        { label: t('reboot_station.reboot_pb'), value: '0', name: 'powercycle' },
        { label: t('reboot_station.reboot_pb_and_connected_pls'), value: '1', name: 'powercycle' }
      ];
    }

    return [
      { label: t('reboot_station.reboot_pl'), value: '0', name: 'powercycle' },
      { label: t('reboot_station.reboot_pl_and_connected_pb'), value: '1', name: 'powercycle' }
    ];
  }

  if (isLoading) {
    return (
      <FormWrapper>
        <KitSpinner />
      </FormWrapper>
    );
  }

  if (!isLoading && serverMsg !== '') {
    return (
      <FormWrapper>
        <strong>{serverMsg}</strong>
      </FormWrapper>
    );
  }

  return (
    <KitForm onSubmit={rebootStation}>
      <FormWrapper>
        <SelectWrapper>
          <KitSelect
            required
            defaultValue={scheduleOptions[0]}
            options={scheduleOptions}
            components={{
              IndicatorSeparator: null
            }}
            onChange={(newValue: {label: string, value: RebootSchedule}) => {
              setRebootSchedule(newValue.value);
            }}
          />
        </SelectWrapper>
        {rebootSchedule === RebootSchedule.Immediate && (
          <StyledWarning>{t('reboot_station.warning_msg')}</StyledWarning>
        )}
        <KitRadioGroup
          defaultValue={powerCycle}
          options={getRadioOptions()}
          onChange={() => {
            setPowerCycle(powerCycle === '0' ? '1' : '0');
          }}
        />
      </FormWrapper>
      <KitFlexWrapRowSpaced>
        <KitButton
          onClick={() => {
            props.onClose();
          }}
          variant='secondary'
          disabled={isLoading}
        >
          {t('cancel')}
        </KitButton>
        <KitButton onClick={rebootStation} variant='primary' type='submit' disabled={isLoading}>
          {t('reboot')}
        </KitButton>
      </KitFlexWrapRowSpaced>
    </KitForm>
  );
};

export default RebootModalHolder;
