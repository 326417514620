import * as React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ThemeConstants, KitSpinner, KitModal, KitButton, KitIcon } from '@chargepoint/cp-toolkit';
import { useTranslation } from 'react-i18next';
import AddEditPaymentMethodModal from './AddEditPaymentMethodModal';
import PaymentMethodsTable, { PaymentMethod } from './PaymentMethodsTable';
import {
  deletePaymentMethod,
  getPaymentMethods,
  getOrgPaymentProviders
} from './PaymentMethodActions';
import ServerMsgSnackBar, { ServerMsgType } from '../../components/ServerMsgSnackBar';

const PaymentContainer = styled.div`
  margin: ${ThemeConstants.spacing.absolute.l}px;
`;

const StyledAddPaymentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${ThemeConstants.spacing.m}rem;
`;

const AddPaymentTrigger = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: ${ThemeConstants.fontSize.text_14}rem;
  color: ${({ theme }) => theme.page.body.text};
`;

const StyledAdd = styled(KitIcon)`
  margin-right: 0.35rem;
`;

const KitButtonMarginRight = styled(KitButton)`
  && {
    margin-right: ${ThemeConstants.spacing.absolute.sm}px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: ${ThemeConstants.spacing.absolute.xl}px;
`;

function PaymentMethods() {
  const { t } = useTranslation();
  const { orgId } = useParams<{ orgId: string }>();
  const [paymentMethods, setPaymentMethods] = useState<Array<PaymentMethod> | []>([]);
  const [orgPaymentProviders, setOrgPaymentProviders] = useState([]);
  const [orgInfo, setOrgInfo] = useState(null);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editPaymentMethod, setEditPaymentMethod] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [serverMessage, setServerMessage] = useState<string | string[]>('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState({ paymentId: null, orgId: null, connectionIds: [] });

  useEffect(() => {
    const formData = new FormData();
    formData.append('organizationId', orgId);
    formData.append('companyId', orgId);
    Promise.all([
      fetch('/backend.php/organizations/searchOrgById', {
        method: 'POST',
        body: formData
      }).then(orgData => {
        return orgData.json();
      }),
      fetch('/backend.php/organizations/getOrgCurrency', {
        method: 'POST',
        body: formData
      }).then(data => {
        return data.json();
      })
    ]).then(([orgInfoResp, currencyResp]) => {
      let orgName = '';
      if (orgInfoResp.content && orgInfoResp.content.length > 0) {
        orgName = orgInfoResp.content[0].name.split('(')[0];
      }
      const orgData = {
        name: orgName,
        currency: currencyResp,
        id: orgId
      };
      setOrgInfo(orgData);
    });
  }, [orgId]);

  const deleteMethod = (paymentId: number, orgId: number, connectionIds: Array<number>) => {
    setDeleteInfo({ paymentId, orgId, connectionIds });
    setShowConfirmModal(true);
  };

  const editMethod = paymentMethod => {
    setIsEdit(true);
    setEditPaymentMethod(paymentMethod);
    setOpenPaymentModal(true);
  };

  const getAllData = () => {
    if (orgInfo && orgInfo.currency) {
      (async () => {
        setShowSpinner(true);
        const [orgPaymentProviders, orgPaymentMethods] = await Promise.all([
          getOrgPaymentProviders(orgInfo.currency.currencyIsoCode),
          getPaymentMethods(orgId)
        ]);
        setShowSpinner(false);
        const msg = [];
        if (orgPaymentProviders.error) {
          if (orgPaymentProviders.message) {
            msg.push(orgPaymentProviders.message);
          } else {
            msg.push(t('fuelCards.errors.paymentProvidersServerGeneral'));
          }
        }
        if (orgPaymentMethods.error) {
          if (orgPaymentMethods.message) {
            msg.push(orgPaymentMethods.message);
          } else {
            msg.push(t('fuelCards.errors.paymentMethodServerGeneral'));
          }
        }
        if (msg.length !== 0) {
          setServerMessage(msg);
          setPaymentMethods([]);
          setOrgPaymentProviders([]);
        } else {
          setPaymentMethods(orgPaymentMethods.data);
          setOrgPaymentProviders(orgPaymentProviders.data);
        }
      })();
    }
  };

  useEffect(getAllData, [orgInfo]);

  const reloadTableData = function() {
    setOpenPaymentModal(false);
    setShowSpinner(true);
    getPaymentMethods(orgId).then(response => {
      setPaymentMethods(response.data);
      setShowSpinner(false);
    });
  };

  const clearMsg = function() {
    setServerMessage('');
  };

  return (
    <PaymentContainer>
      <ServerMsgSnackBar
        visible={!!serverMessage}
        message={serverMessage}
        msgType={ServerMsgType.Error}
        clearMsg={clearMsg}
      />
      <StyledAddPaymentContainer>
        <AddPaymentTrigger
          onClick={() => {
            setIsEdit(false);
            setOpenPaymentModal(true);
          }}
        >
          <StyledAdd icon='plus' />
          {t('addMethod')}
        </AddPaymentTrigger>
      </StyledAddPaymentContainer>
      <PaymentMethodsTable
        deleteMethod={deleteMethod}
        editMethod={editMethod}
        paymentMethods={paymentMethods}
      />
      <AddEditPaymentMethodModal
        showModal={openPaymentModal}
        hideModal={() => {
          setOpenPaymentModal(false);
        }}
        paymentProviders={orgPaymentProviders}
        orgInfo={orgInfo}
        editMode={isEdit}
        paymentMethodToEdit={editPaymentMethod}
        updateCallback={reloadTableData}
        cancelCallback={() => {
          setOpenPaymentModal(false);
        }}
      />
      <KitModal
        show={showConfirmModal}
        closeOnClickOutside
        onHide={() => {
          setShowConfirmModal(false);
          setDeleteInfo({ paymentId: null, orgId: null, connectionIds: [] });
        }}
      >
        <KitModal.Header t={t} closeButton>
          <KitModal.Title>{t('deletePaymentTitle')}</KitModal.Title>
        </KitModal.Header>
        <KitModal.Body>
          <div>{t('deletePaymentMethod')}</div>
          <ButtonContainer>
            <KitButtonMarginRight
              onClick={() => {
                setShowConfirmModal(false);
                if (!deleteInfo.paymentId) {
                  return;
                }
                setShowSpinner(true);
                deletePaymentMethod(
                  deleteInfo.orgId,
                  deleteInfo.paymentId,
                  deleteInfo.connectionIds
                ).then(data => {
                  setDeleteInfo({ paymentId: null, orgId: null, connectionIds: [] });
                  setShowSpinner(false);
                  if (data.error) {
                    setServerMessage(data.error);
                  } else {
                    reloadTableData();
                  }
                });
              }}
            >
              {t('delete')}
            </KitButtonMarginRight>
            <KitButton
              variant='secondary'
              onClick={() => {
                setShowConfirmModal(false);
                setDeleteInfo({ paymentId: null, orgId: null, connectionIds: [] });
              }}
            >
              {t('ButtonSet.cancel')}
            </KitButton>
          </ButtonContainer>
        </KitModal.Body>
      </KitModal>
      {showSpinner && <KitSpinner withOverlay />}
    </PaymentContainer>
  );
}

export default PaymentMethods;
