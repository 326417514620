import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from '../../Main/main.module.scss';
import { SubscriberAggreementProps } from './subscriberaggreement';

const SubscriberAgreement = (props: SubscriberAggreementProps) => {
  const { t } = useTranslation();

  return (
    <>
      <h3 className={props.hide ? styles.hide : styles.required}>
          {t('subscriberAgreement.paragraph_agreement')}
      </h3>
      <div className={props.hide ? styles.hide : styles.row}>
        <div className={styles.formGroup}>
          <label htmlFor='aggreement_initials' >{t('subscriberAgreement.label_initial')}</label>
          <input name='aggreement_initials' id='aggreement_initials' value={props.subscriberAgreement.initials} onChange={(ev) => { props.onChange('subscriberAgreement', { ...props.subscriberAgreement, initials: ev.target.value }) }} />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor='aggreement_date' >{t('subscriberAgreement.label_date')}</label>
          <input readOnly name='aggreement_date' id='aggreement_date' value={props.subscriberAgreement.date} onChange={(ev) => { props.onChange('subscriberAgreement', { ...props.subscriberAgreement, date: ev.target.value }) }} />
        </div>
      </div>

      <div className={props.hide ? styles.hide : styles.row}>
        <div className={styles.formGroup}>
          <label htmlFor='aggreement_name' >{t('subscriberAgreement.label_printName')}</label>
          <input name='aggreement_name' id='aggreement_name' value={props.subscriberAgreement.name} onChange={(ev) => { props.onChange('subscriberAgreement', { ...props.subscriberAgreement, name: ev.target.value }) }} />
        </div>

        <div className={styles.formGroup}>
          <label htmlFor='aggreement_title' >{t('subscriberAgreement.label_title')}</label>
          <input name='aggreement_title' id='aggreement_title' value={props.subscriberAgreement.title} onChange={(ev) => { props.onChange('subscriberAgreement', { ...props.subscriberAgreement, title: ev.target.value }) }} />
        </div>
      </div>
    </>
  );
};

export default SubscriberAgreement;
