export function getProviders() {
  return fetch('/backend.php/fuelcards/getProviders').then(resp => {
    return resp.json();
  });
}

export function getProviderDetail(code: string) {
  return fetch(`/backend.php/fuelcards/getProviders/${code}`).then(resp => {
    return resp.json();
  });
}
