import * as React from 'react';
import { KitModal } from '@chargepoint/cp-toolkit';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import AddEditPaymentMethodForm, {
  AddEditPaymentMethodFormProps
} from './AddEditPaymentMethodForm';

export interface AddEditPaymentMethodModalProps extends AddEditPaymentMethodFormProps {
  showModal: boolean;
  hideModal: () => void;
}

const AddPaymentControl = (props: AddEditPaymentMethodModalProps) => {
  const { t } = useTranslation();
  const { showModal } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);

  return (
    <>
      <KitModal show={show} onHide={props.hideModal} closeOnClickOutside>
        <KitModal.Header t={t} closeButton>
          <KitModal.Title>{t('setupPayment')}</KitModal.Title>
        </KitModal.Header>
        <KitModal.Body>
          <AddEditPaymentMethodForm {...props} cancelCallback={props.hideModal} />
        </KitModal.Body>
      </KitModal>
    </>
  );
};

export default AddPaymentControl;
