import * as React from 'react';
import { useState, useEffect, useRef, Fragment } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { KitIcon, ThemeColors } from '@chargepoint/cp-toolkit';
// TODO discuss with UX what these colors should be

const slideDownAnimation = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
    
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideUpAnimation = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
`;

export enum ServerMsgType {
  Error,
  Success,
  Info,
  Warning
}

const SnackbarContainer = styled.div<{ visible?: boolean; msgType: ServerMsgType }>`
  display: flex;
  justify-content: space-between;
  ${props => {
    switch (props.msgType) {
      case ServerMsgType.Success:
        return `
          border: solid 1px ${ThemeColors.green_50};
          background-color: ${ThemeColors.green_10};
          color: ${ThemeColors.gray_90};
        `;
      default:
        return `
        border: solid 1px ${ThemeColors.red_50};
        background-color: ${ThemeColors.red_10};
        color: ${ThemeColors.red_50};
        `;
    }
  }};
  animation: ${props => {
    if (props.visible) {
      return css`0.4s ${slideDownAnimation}`;
    }
    return css`0.4s ${slideUpAnimation}`;
  }};
  transform: ${props => {
    if (props.visible) {
      return 'translateY(0)';
    }
    return 'translateY(-100%)';
  }};
`;

const Container = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Body = styled.div`
  padding: 16px;
`;

const StyledClose = styled(KitIcon)`
  cursor: pointer;
`;

export interface ServerMsgSnackBarProps {
  msgType: ServerMsgType;
  message: string | string[];
  visible: boolean;
  clearMsg: Function;
  className?: string;
}

const ServerMsgSnackBar: React.FC<ServerMsgSnackBarProps> = ({
  visible,
  message,
  clearMsg,
  msgType,
  className
}) => {
  const snackContainerRef = useRef(null);
  const visibilityRef = useRef(visible);
  const [animationSet, setAnimationSet] = useState(false);
  const [mainContainerHidden, setMainContainerHidden] = useState(visible);
  visibilityRef.current = visible;

  useEffect(() => {
    if (visible === true) {
      setMainContainerHidden(visible);
    }
  }, [visible]);

  if (snackContainerRef.current && !animationSet) {
    snackContainerRef.current.addEventListener('animationend', () => {
      if (!visibilityRef.current) {
        setMainContainerHidden(false);
      }
    });
    setAnimationSet(true);
  }
  const getMessage = () => {
    if (message instanceof Array) {
      return message.map((msgEl, index) => {
        return (
          <Fragment key={index}>
            <span key={index}>{msgEl}</span> <br />
          </Fragment>
        );
      });
    }
    return <>{message}</>;
  };

  return (
    <>
      {mainContainerHidden && (
        <Container role='alert' aria-live='polite' className={className}>
          <SnackbarContainer ref={snackContainerRef} msgType={msgType} visible={visible}>
            <Body>{getMessage()}</Body>
            <div>
              <StyledClose
                icon='close-circle'
                onClick={() => {
                  clearMsg();
                }}
              />
            </div>
          </SnackbarContainer>
        </Container>
      )}
    </>
  );
};

export default ServerMsgSnackBar;
