/* eslint-disable class-methods-use-this */

import {
  AuthenticateCard3DRequest,
  AuthorizePayPalRequest,
  PaymentResponse,
  PayPalExpressCheckoutRequest,
  PayPalExpressCheckoutResponse,
} from './PaymentServiceModel';
import ReCaptchaService from './RecaptchaService';
import md5 from 'blueimp-md5';
import { KitUtilCommon } from '@chargepoint/cp-toolkit';
import {
  AuthenticationSource,
  ChallengeWindowSize,
  getBrowserData,
  handle3dsVersionCheck,
  handleInitiateAuthentication,
  ICheckVersionResponseData,
  IInitiateAuthenticationResponseData,
  MethodUrlCompletion,
  TransactionStatus
} from 'globalpayments-3ds';
import { SessionState } from '@chargepoint/common/hooks/SessionContext';
import AnalyticsService from './analytics/AnalyticsService';
import { analyticEvents } from './analytics/AnalyticEvents';

class PaymentService {
  private static instance: PaymentService;
  private sessionObj: any;

  constructor(sessionObj?: SessionState) {
    this.sessionObj = sessionObj;
  }

  public static getInstance(sessionObj?: SessionState): PaymentService {
    if (!PaymentService.instance) {
      PaymentService.instance = new PaymentService(sessionObj);
    }
    // NEED TO LOAD RECAPTCHA JS
    ReCaptchaService.getInstance(sessionObj.reCaptchaApiKey);
    // NEED TO LOAD MixPanel JS
    AnalyticsService.getInstance(sessionObj);
    return PaymentService.instance;
  }

  orgType = 'WEB';
  acceptHeader = 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8';

  authorizeCreditCard(
    cardNumber: number,
    expirationMonth: number,
    expirationYear: number,
    cvc: number,
    zip: string,
    currency: string,
    serverTransactionId?: string
  ): PromiseLike<PaymentResponse> {
    const request = {
      address: {
        zipCode: zip,
        countryCode: this.sessionObj.userCountryCode,
      },
      cardNumber: cardNumber.toString(),
      currency: currency,
      cvv: cvc.toString(),
      email: this.sessionObj.userEmail || this.sessionObj.userInfo.userEmail,
      expirationMonth: expirationMonth.toString(),
      expirationYear: expirationYear.toString(),
      fullName: this.sessionObj.userFullName || this.sessionObj.userInfo.userFullName,
      paymentType: 'AUTO_TOP',
      userType: 'REGISTERED',
      serverTransactionId: serverTransactionId ? serverTransactionId : ''
    };

    const ccAuthorizeURL = this.sessionObj.ccAuthorizeURL;
    return ReCaptchaService.getInstance()
        .getToken('CreditCardAuthorize')
        .then((reCaptchaToken) => {
          const timestamp = Math.floor(Date.now() / 1000).toString();
          let signature = '';
          signature = md5(
              signature.concat(
                  request.cardNumber,
                  request.cvv,
                  Number(request.expirationMonth).toString(),
                  Number(request.expirationYear).toString(),
                  timestamp
              )
          );

          if (reCaptchaToken === null) {
            throw new Error('reCaptchaToken is null');
          }

          const url =
            ccAuthorizeURL +
            KitUtilCommon.getQueryString({
              timestamp,
              signature,
            });

          return fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
              Accept: '*/*',
              'Content-Type': 'application/json',
              'Accept-Language': this.getLocale(),
              'CP-Captcha-Token': reCaptchaToken,
              'CP-Session-Type': this.orgType,
            },
            body: JSON.stringify(request),
          }).then((resp) => {
            return resp.json();
          });
        });
  }

  startPaypalExpressCheckout(
      request: PayPalExpressCheckoutRequest
  ): PromiseLike<PayPalExpressCheckoutResponse> {
    const options = this.getHttpOptions();
    options.method = 'POST';
    options.body = JSON.stringify(request);

    return fetch(this.sessionObj.paypalSetExpressCheckout, options).then((resp) => {
      return resp.json();
    });
  }

  authorizePayPal(request: AuthorizePayPalRequest): PromiseLike<PaymentResponse> {
    const options = this.getHttpOptions();
    options.method = 'POST';
    options.body = JSON.stringify(request);

    return fetch(this.sessionObj.paypalAuthorizeURL, options).then((resp) => {
      return resp.json();
    });
  }

  async checkCardVersion(
      cardNumber: number,
      expirationMonth: number,
      expirationYear: number,
      cvc: number,
      zip: string,
      currency: string
  ): Promise<IInitiateAuthenticationResponseData> {
    AnalyticsService.getInstance().trackEvent(
        analyticEvents.card3dsInitialization,
        { 'User Type': 'Driver' }
    );

    const reCaptchaToken = await ReCaptchaService.getInstance()
        .getToken('CreditCardAuthorize');

    // call payment api to check 3D support by card
    let checkResponse = await fetch(
        this.sessionObj.cc3DCheckUrl,
        this.getV2HttpOptions(
            reCaptchaToken,
            JSON.stringify({
              cardNumber: cardNumber,
              authenticationSource: AuthenticationSource.Browser,
              currency: currency
            })
        )
    ).then((resp) => {
      return resp.json();
    }) as ICheckVersionResponseData;

    AnalyticsService.getInstance().trackEvent(
        analyticEvents.card3dsCheckVersion,
        {
          'User Type': 'Driver',
          'Is Enrolled': checkResponse.enrolled,
          'Server Transaction ID': checkResponse.serverTransactionId
        }
    );

    // skip next steps if card is not enrolled for 3D secure
    if (typeof checkResponse.enrolled === 'string' && checkResponse.enrolled !== 'true') {
      // send a success status to start authorize process
      return {
        status: TransactionStatus.AuthenticationSuccessful
      };
    } else if ('errorMessage' in checkResponse && checkResponse.errorMessage) {
      return checkResponse;
    }

    let methodUrlComplete = MethodUrlCompletion.Unavailable;

    if (checkResponse.methodUrl) {
      try {
        // 3dsVersionCheck always returns the same checkResponse object without change
        checkResponse = await handle3dsVersionCheck(
            checkResponse,
            {
              timeout: 10 * 1000,
              origin: this.sessionObj.paymentURL?.replace('/payment/', '')
            }
        );
        methodUrlComplete = MethodUrlCompletion.Yes;
      } catch (e) {
        // according to global payment docs UI should not wait for a success
        // not all banks support this step
        methodUrlComplete = MethodUrlCompletion.No;
      }
    }

    return await this.AuthenticateCard3D(
        cardNumber,
        expirationMonth,
        expirationYear,
        cvc,
        zip,
        currency,
        methodUrlComplete,
        checkResponse.serverTransactionId,
    );
  }

  async AuthenticateCard3D(
      cardNumber: number,
      expirationMonth: number,
      expirationYear: number,
      cvc: number,
      zip: string,
      currency: string,
      methodUrlComplete: MethodUrlCompletion,
      serverTransactionId?: string
  ): Promise<IInitiateAuthenticationResponseData> {
    const reCaptchaToken = await ReCaptchaService.getInstance()
        .getToken('CreditCardAuthorize');

    const body = {
      authenticationSource: AuthenticationSource.Browser,
      methodUrlComplete: methodUrlComplete,
      browserData: {
        ...getBrowserData(),
        ...{
          challengeWindowSize: ChallengeWindowSize.FullScreen,
          acceptHeader: this.acceptHeader
        }
      },
      cardDetail: {
        cardNumber: cardNumber.toString(),
        expirationMonth: expirationMonth.toString(),
        expirationYear: expirationYear.toString(),
        firstName: this.sessionObj.userFirstName || this.sessionObj.userInfo.userFirstName,
        lastName: this.sessionObj.userFullName.replace(this.sessionObj.userFirstName + ' ', '')
            || this.sessionObj.userInfo.userFullName.replace(this.sessionObj.userInfo.userFirstName + ' ', '')
      },
      challengeWindow: {
        windowSize: ChallengeWindowSize.FullScreen,
        displayMode: 'lightbox'
      },
      order: {
        amount: 0,
        currency: currency,
        shippingAddress: {
          country: this.sessionObj.userCountryCode,
          zipCode: zip
        }
      },
      payer: {
        billingAddress: {
          country: this.sessionObj.userCountryCode,
          zipCode: zip
        },
        email: this.sessionObj.userEmail || this.sessionObj.userInfo.userEmail,
        mobilePhone: {
          countryCode: 0,
          subscriberNumber: '000-000-0000'
        }
      },
      serverTransactionId: serverTransactionId
    } as AuthenticateCard3DRequest;

    // call payment api to authenticate card for 3D secure
    const authenticateData = await fetch(
        this.sessionObj.cc3DAuthenticateURL,
        this.getV2HttpOptions(
            reCaptchaToken,
            JSON.stringify(body)
        )
    ).then((resp) => {
      return resp.json();
    }) as IInitiateAuthenticationResponseData;

    AnalyticsService.getInstance().trackEvent(
        analyticEvents.card3dsAuthenticate,
        {
          'User Type': 'Driver',
          'Authenticate Status': authenticateData.status,
          'Server Transaction ID': serverTransactionId,
          'ACS Transaction ID': authenticateData.acsTransactionId
        }
    );

    // skip challenge flow if it is not required
    if (authenticateData.status !== TransactionStatus.ChallengeRequired) {
      authenticateData.serverTransactionId = serverTransactionId;
      return authenticateData;
    }

    return await handleInitiateAuthentication(
        authenticateData,
        {
          origin: this.sessionObj.paymentURL?.replace('/payment/', ''),
          displayMode: 'lightbox',
          windowSize: ChallengeWindowSize.FullScreen
        }
    );
  }

  // eslint-disable-next-line no-undef
  getHttpOptions(): RequestInit {
    return {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        'Accept-Language': this.getLocale(),
      },
    };
  }

  // eslint-disable-next-line no-undef
  getV2HttpOptions(reCaptchaToken: string | null, body: string, method = 'POST'): RequestInit {
    return {
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        'Accept-Language': this.getLocale(),
        'CP-Captcha-Token': reCaptchaToken ? reCaptchaToken : '',
        'CP-Session-Type': this.orgType,
      },
      body,
      method,
    };
  }

  getLocale() {
    return this.sessionObj.locale || this.sessionObj.prefLangLocale;
  }
}

export default PaymentService;
