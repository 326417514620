import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReceiptModalHolder from './ReceiptModalHolder';
import RebootModalHolder from './RebootModalHolder';
import GetLogsModalHolder from './GetLogsModalHolder';
import GetDiagnosticsModalHolder from './GetDiagnosticsModalHolder';
import ResetModalHolder from './ResetModalHolder';
import FleetEditPayment from '../../components/FleetEditPayment';

const ModalWrapper = styled.div`
  min-height: 100%;
`;

export interface Props {
  className?: string;
}

interface ModalData {
  // driver receipt
  vehicleChargeId?: string;
  // reboot and reset station
  stationType?: string;
  stationDeviceId?: string;
  stationIsOcpp?: string;
  // get station logs
  stationMacAddress?: string;
  stationTimeZone?: string;
  stationTimeZoneOffset?: number;
  stationLogType?: string;
  stationShowLogOptions?: string;
  // get diagnostics
  diagnosticsRetries?: string;
  diagnosticsRetryInterval?: string;
  diagnosticsStartTime?: string;
  diagnosticsStopTime?: string;
  // fleet payment
  fleetId?: string;
}

const ModalOnEvent: React.FC<Props> = props => {
  const [component, setComponent] = useState<string>(null);
  const [data, setData] = useState<ModalData | null>(null);

  useEffect(() => {
    const reactData = sessionStorage.getItem('reactData');
    const reactDataObj = JSON.parse(reactData);

    if (reactDataObj) {
      setData(reactDataObj);

      if (reactDataObj.component) {
        setComponent(reactDataObj.component);
      }
    }

    const handleOpenDialog = (e: CustomEvent) => {
      if (e.detail.data) {
        setData(e.detail.data);
      }
      if (e.detail.component) {
        setComponent(e.detail.component);
      }
    };

    document.addEventListener('show-react-modal-event', handleOpenDialog);

    return () => {
      document.removeEventListener('show-react-modal-event', handleOpenDialog);
      closeDialog();
    };
  }, []);

  if (!component) {
    return null;
  }

  function sendToIframe(data) {
    if (data) {
      document.dispatchEvent(
        new CustomEvent('send-to-iframe', {
          detail: {
            sendToIframe: data
          }
        })
      );
    }
  }

  function closeDialog() {
    setComponent(null);
    setData(null);
    document.dispatchEvent(new CustomEvent('close-react-modal-event'));
  }

  function renderDialog() {
    switch (component) {
      case 'driver_receipt':
        return <ReceiptModalHolder vehicleChargeId={data.vehicleChargeId} />;
      case 'station_reboot':
        return (
          <RebootModalHolder
            onClose={closeDialog}
            type={data.stationType}
            deviceId={data.stationDeviceId}
            isOcpp={data.stationIsOcpp}
          />
        );
      case 'factory_reset':
        return <ResetModalHolder onClose={closeDialog} deviceId={data.stationDeviceId} />;
      case 'station_get_logs':
        return (
          <GetLogsModalHolder
            onClose={closeDialog}
            macAddress={data.stationMacAddress}
            timeZone={data.stationTimeZone}
            timeZoneOffset={data.stationTimeZoneOffset}
            logType={data.stationLogType}
            showLogOptions={Boolean(data.stationShowLogOptions)}
            startTime={data.diagnosticsStartTime}
            stopTime={data.diagnosticsStopTime}
          />
        );
      case 'station_get_diagnostics':
        return (
          <GetDiagnosticsModalHolder
            onClose={closeDialog}
            sendToIframe={sendToIframe}
            deviceId={data.stationDeviceId}
            timeZone={data.stationTimeZone}
            timeZoneOffset={data.stationTimeZoneOffset}
            retries={data.diagnosticsRetries}
            retryInterval={data.diagnosticsRetryInterval}
            startTime={data.diagnosticsStartTime}
            stopTime={data.diagnosticsStopTime}
          />
        );
      case 'fleet_update_payment_method':
        return (
          <FleetEditPayment onClose={closeDialog} fleetId={data.fleetId} />
        );
      default:
        return null;
    }
  }

  return <ModalWrapper className={props.className}>{renderDialog()}</ModalWrapper>;
};

export default ModalOnEvent;
