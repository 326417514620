import styled from 'styled-components';
import { ThemeColors, ThemeConstants } from '@chargepoint/cp-toolkit';
//TODO switch to KitModal

export const ModalContent = styled.div`
  position: absolute;
  padding: ${ThemeConstants.spacing.absolute.m}px;
  background: ${ThemeColors.white};
  border: ${ThemeConstants.border.width}px solid ${ThemeColors.gray_40};
  box-shadow: 0 1px 5px ${ThemeColors.gray_40};
  border-radius: ${ThemeConstants.border.radius}px;
  width: 40vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 100vh;
  box-sizing: border-box;
`;
