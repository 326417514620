import React, { useState, useEffect } from 'react';
import { KitSpinner } from '@chargepoint/cp-toolkit';
import { AccountService } from '../services/AccountService';
import { RolloutFlags, getFeatureFlags } from '../services/featureFlags/FeatureFlagService';

export interface SessionState {
  prefLangLocale?: string;
  isLoggedIn?: boolean;
  isDriver?: boolean;
  dataServer?: string;
  accountsAPI?: string;
  accountAPIServerV2?: string;
  discoveryURL?: string;
  reCaptchaApiKey?: string;
  ccAuthorizeURL?: string;
  paypalSetExpressCheckout?: string;
  paypalExpressCheckout?: string;
  paypalAuthorizeURL?: string;
  l10nUnits?: { length: string; volume: string };
  currencyCode?: string;
  currencySymbol?: string;
  companyID?: number;
  permissions?: Map<string, boolean>;
  supportEmail?: string;
  customerSupportNumber?: string;
  portalTimezone?: string;
  subdomain?: string;
  brandedLogoURL?: string;
  userInfo?: UserInfo;
  nosletMapCoordinates?: { lat: number; lon: number };
  corporateUrl?: string;
  nav?: Array<any>;
  coBrandLogoName?: string;
  coBrandLogoDesktopImg?: string;
  coBrandLogoMobileImg?: string;
  coBrandShowPoweredBy?: boolean;
  isNOC?: boolean;
  featureFlags?: RolloutFlags;
  // CPSRV-41388
  isVatInProgressQaFlag?: boolean;
  instance?: string;
  isVatEnabled?: boolean;
  images_url?: string;
  userCountryCode?: string;
  isCPAdminMigratedToOKTA?: boolean;
  cc3DCheckUrl?: string;
  cc3DAuthenticateURL?: string;
  paymentURL?: string;
  isCC3DSecureEnabled?: boolean;
  mixPanelTrackingID?: string;
  supportCenterURL?: string;
  isFlexBillingHoldMyFeesServiceEnabled?: boolean;
  isFlexBillingMailCheckEnabled?: boolean;
}

interface UserInfo {
  userFirstName: string;
  userFullName: string;
  userName: string;
  userRole: string;
  userId: string | number;
  userEmail: string;
}

interface SessionCtxData {
  session: Partial<SessionState>;
  updateSession: Function;
  refreshSession?: Function;
}

const SessionContext = React.createContext<SessionCtxData>({
  session: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateSession: () => {}
});

const SessionProvider = (props: any) => {
  const [sessionState, setSessionState] = useState<SessionState>({
    prefLangLocale: null,
    isLoggedIn: null
  });

  const makePermissionMap = (tasks: Array<string>) => {
    const mapArray = tasks.map<[string, boolean]>(task => {
      return [task, true];
    });
    return new Map<string, boolean>(mapArray);
  };

  const createSessionData = (data): SessionState => {
    return {
      prefLangLocale: data.locale,
      isLoggedIn: !!data.userID,
      isDriver: data.isDriver,
      dataServer: data.dataserver,
      accountsAPI: data.accountsAPI || data.accountAPIServer,
      accountAPIServerV2: data.accountAPIServerV2,
      discoveryURL: data.discoveryURL,
      paypalSetExpressCheckout: data.paypalSetExpressCheckout,
      paypalAuthorizeURL: data.paypalAuthorizeURL,
      reCaptchaApiKey: data.reCaptchaApiKey,
      ccAuthorizeURL: data.ccAuthorizeURL,
      userCountryCode: data.userCountryCode,
      paypalExpressCheckout: data.paypalExpressCheckout,
      l10nUnits: {
        length: data.length_unit,
        volume: data.volume_unit
      },
      currencyCode: data.currency_code,
      currencySymbol: data.currency_symbol,
      companyID: data.company_id ? data.company_id : null,
      permissions: data.taskPermissions ? makePermissionMap(data.taskPermissions) : null,
      supportEmail: data.support_email,
      customerSupportNumber: data.customer_support_number,
      portalTimezone: data.portalTimezone,
      subdomain: data.subdomain,
      brandedLogoURL: data.brandedLogo ? data.brandedLogo : null,
      nav: data.nav,
      userInfo: {
        userFirstName: data.userFirstName,
        userFullName: data.userFullName,
        userName: data.userName,
        userRole: data.role,
        userId: data.userID,
        userEmail: data.userEmail,
      },
      nosletMapCoordinates: {
        lat: data.mapData.map_latitude,
        lon: data.mapData.map_longitude
      },
      corporateUrl: data.corporate_url,
      coBrandLogoName: data.cobrand_logo_name ? data.cobrand_logo_name : null,
      coBrandLogoDesktopImg: data.cobrand_logo_desktop_img ? data.cobrand_logo_desktop_img : null,
      coBrandLogoMobileImg: data.cobrand_logo_mobile_img ? data.cobrand_logo_mobile_img : null,
      coBrandShowPoweredBy: data.cobrand_show_powered_by ? data.cobrand_show_powered_by : false,
      isNOC: data.isNOC ? data.isNOC : null,
      featureFlags: getFeatureFlags(
        data.webAdminFeatureFlagAppId,
        data.role,
        data.isNOC,
        data.userID,
        data.company_id,
        data.instance
      ),
      isVatInProgressQaFlag: data.isVatInProgressQaFlag,
      instance: data.instance,
      isVatEnabled: data.isVatEnabled,
      images_url: data.images_url,
      isCPAdminMigratedToOKTA: data.isCPAdminMigratedToOKTA,
      cc3DCheckUrl: data.cc3DCheckUrl,
      cc3DAuthenticateURL: data.cc3DAuthenticateURL,
      paymentURL: data.paymentURL,
      isCC3DSecureEnabled: data.isCC3DSecureEnabled,
      mixPanelTrackingID: data.mixPanelTrackingID,
      supportCenterURL: data.supportCenterURL,
      isFlexBillingHoldMyFeesServiceEnabled: data.isFlexBillingHoldMyFeesServiceEnabled,
      isFlexBillingMailCheckEnabled: data.isFlexBillingMailCheckEnabled
    };
  };

  const refreshSessionData = data => {
    const sessionData: SessionState = createSessionData(data);
    setSessionState(sessionData);
  };

  function loadSessionData() {
    (async () => {
      await fetch('/index.php/nghelper/getSession', {
        cache: 'no-cache',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then(
          result => {
            const sessionData: SessionState = createSessionData(result);
            setSessionState(sessionData);
            AccountService.initService(sessionData);
          },
          error => {
            if (window.location.pathname === '/version') {
              setSessionState({
                prefLangLocale: 'en-US',
                isLoggedIn: null
              })
            } else {
              console.log(error);
            }
          }
        );
    })();
  }

  useEffect(() => {
    loadSessionData();
  }, []);

  return (
    <SessionContext.Provider
      value={{
        session: sessionState,
        updateSession: loadSessionData,
        refreshSession: refreshSessionData
      }}
    >
      {sessionState.prefLangLocale ? props.children : <KitSpinner withOverlay />}
    </SessionContext.Provider>
  );
};

export { SessionContext, SessionProvider };
