import React, { Fragment, Component } from 'react';
import PDFLink from '../PDFLink';
import { sessionObj } from '../../../Intl/i18n';
import { Translation } from 'react-i18next';
import styles from './style.module.scss';
import { SessionContext } from '@chargepoint/common/hooks/SessionContext';

interface ReleaseNotesLineProps {
  row: number;
  version: string;
  name: string;
  overview: string;
  pdf: string;
  highlights: string;
}

class ReleaseNotesLine extends Component<ReleaseNotesLineProps> {
  context: React.ContextType<typeof SessionContext>;
  state = {
    url: undefined,
    overviewUrl: undefined
  };

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: ReleaseNotesLineProps) {
    super(props);
  }

  componentDidMount() {
    const { session } = this.context!;
    const imagesUrl = sessionObj?.images_url
      ? sessionObj.images_url
      : session.images_url;
    this.setState({
      url: imagesUrl + '/docs/relnotes/' + this.props.pdf,
      overviewUrl: this.props.overview ? imagesUrl + '/docs/relnotes/' + this.props.overview : undefined
    });
  }

  render() {
    const cls = this.props.row % 2 ? 'col' : styles.colOdd;

    return (
      <Translation>
        {(t) => (
          <tr className={styles.ReleaseNotesLine}>
            <td className={cls + ' ' + styles.name}>
              <img src={`https://${process.env.REACT_APP_DOMAIN}/images/icon_plus.gif`} alt={this.props.name} />
              {t(this.props.name)}
            </td>
            <td className={cls + ' ' + styles.version}>{this.props.version}</td>
            <td className={cls}>
              {this.state.url && (
                <PDFLink
                  title={t('link_details') as string}
                  url={this.state.url}
                  file={this.props.pdf}
                />
              )}
              {this.state.overviewUrl && (
                <Fragment>
                  <br />
                  <PDFLink
                    title={t('link_overview') as string}
                    url={this.state.overviewUrl}
                    file={this.props.overview}
                  />
                </Fragment>
              )}
            </td>
            <td
              className={cls}
              dangerouslySetInnerHTML={{
                __html: t(this.props.highlights) as string,
              }}
            ></td>
          </tr>
        )}
      </Translation>
    );
  }
}

ReleaseNotesLine.contextType = SessionContext;

export default ReleaseNotesLine;
