import React, { useCallback } from 'react';
import { OrgSelection as BaseOrgSelection } from '@chargepoint/cp-toolkit';
import styled from 'styled-components';
import { FieldRow, FieldContainer, LabelText } from './styled';
import { FieldProps } from './Field';
import { useField, useFormContext } from '../hooks';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  flex: 1 0 auto;
  font-weight: 400;
  border: 1px solid;
`;

export function OrgSelection(props: FieldProps) {
  const { name, label, isDisabled } = props;
  const { input } = useField({ name });
  const { setValue, values } = useFormContext();
  const orgName = values['orgName'] ? values['orgName'] : '';
  const { t } = useTranslation();

  const handleChange = useCallback(
    org => {
      return setValue(name, org.id);
    },
    [name, setValue]
  );

  return (
    <FieldContainer>
      <FieldRow>
        <LabelText>{label}</LabelText>
        <Container>
          <BaseOrgSelection
            {...input}
            isDisabled={isDisabled}
            defaultOrgName={orgName}
            onChange={handleChange}
            noRecordsMessage={t('videoAd.noRecordsMessage')}
          />
        </Container>
      </FieldRow>
    </FieldContainer>
  );
}
