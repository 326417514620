import {createGlobalStyle} from 'styled-components';

import {ThemeConstants} from '@chargepoint/cp-toolkit';

const GlobalCss = createGlobalStyle`

/****************************
 * Reset
 ****************************/

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  text-size-adjust: 100%
}

body {
  margin: 0;
}

img {
    max-width: 100%;
    height: auto;
}

/****************************
 * Fonts
 ****************************/

/* Add in @font-face here when we get this figured out */

/****************************
 * App / Page
 ****************************/
    
html,
body,
#react-app-root,
#react-driver-root {
  height: 100%;
  font-family: ${({ theme }) => theme.font.fontFamily};
  font-size: ${ThemeConstants.baseFontSize}px;
  color: ${({ theme }) => theme.page.body.text};
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: ${({ theme }) => theme.page.body.bg};
}

body pre {
  line-height: 18px;
  padding: 11px 1rem;
  white-space: pre-wrap;
  background: rgba(0,0,0,0.05);
  color: rgb(51,50,49);
  border-radius: 3px;
  margin: 1rem 0;
}

code {
  font-family: ${ThemeConstants.fontFamily.code};
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0;
}
  
h1 {
  font-size: ${ThemeConstants.fontSize.h1}rem;
}
h2 {
  font-size: ${ThemeConstants.fontSize.h2}rem;
}
h3 {
  font-size: ${ThemeConstants.fontSize.h3}rem;
}
h4 {
  font-size: ${ThemeConstants.fontSize.h4}rem;
}
h5 {
  font-size: ${ThemeConstants.fontSize.h5}rem;
}
h6 {
  font-size: ${ThemeConstants.fontSize.h6}rem;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

p {
  margin: 0 0 1em;
  font-size: ${ThemeConstants.fontSize.p}rem;
}

p:first-child {
  margin-top: 0;
}
p:last-child {
  margin-bottom: 0;
}
  
li {
  font-size: ${ThemeConstants.fontSize.p}rem;
}

a {
  color: ${({ theme }) => theme.link.text};
  text-decoration: none;
}

input,
textarea,
select,
button {
  font-family: inherit;
}

[role=button] {
  cursor: pointer;
}

`;

export default GlobalCss;
