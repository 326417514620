import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  KitButton,
  KitFlexWrapRowSpaced,
  KitForm,
  KitSpinner,
  ThemeConstants,
  ThemeColors,
  KitInput,
  KitFlexWrapRow
} from '@chargepoint/cp-toolkit';
import styled from 'styled-components';

const StyledWarning = styled.p`
  color: ${ThemeColors.red_50};
  font-size: ${ThemeConstants.fontSize.base}rem;
  margin-top: 0;
  margin-bottom: ${ThemeConstants.spacing.absolute.l}px;
  margin-right: ${ThemeConstants.spacing.absolute.s}px;
  line-height: 1;
  min-height: 1rem;
`;

const StyledInfo = styled.p`
  font-size: ${ThemeConstants.fontSize.base}rem;
  margin-top: ${ThemeConstants.spacing.absolute.l}px;
  margin-bottom: ${ThemeConstants.spacing.absolute.l}px;
  line-height: 1;
  min-height: 1rem;
`;

const FormWrapper = styled.div`
  margin: ${ThemeConstants.spacing.absolute.sm}px 0 ${ThemeConstants.spacing.absolute.xxl}px;
  min-height: 50px;
  .cp-form-label {
    line-height: ${ThemeConstants.fontSize.text_24}rem;
  }
  .cp-form-label::after {
    left: 5px;
    top: 5px;
  }
`;

const SelectWrapper = styled.div`
  margin-bottom: ${ThemeConstants.spacing.absolute.l}px;
  margin-right: ${ThemeConstants.spacing.absolute.l}px;
  width: 40%;
`;

const InputWrapper = styled.div`
  margin-bottom: ${ThemeConstants.spacing.absolute.xl}px;
  margin-right: ${ThemeConstants.spacing.absolute.l}px;
`;

const twentyFourHoursAgo = (d: Date) => {
  const yesterday = new Date();
  yesterday.setTime(d.getTime() - 24 * 60 * 60 * 1000);
  return yesterday;
}

export interface Props {
  logType: string;
  showLogOptions: boolean;
  macAddress: string;
  timeZone: string;
  timeZoneOffset: number;
  startTime: string;
  stopTime: string;
  onClose: Function;
}

enum Facility {
  ALL =  'ALL',
  CLOUDCLIENT =  'CLOUDCLIENT',
  MCP =  'MCP',
  PILOT =  'PILOT',
  BACKLIGHT =  'BACKLIGHT',
  CARDREADER =  'CARDREADER',
  CHARGING =  'CHARGING',
  FAULTMGR =  'FAULTMGR',
  FRONTEND =  'FRONTEND',
  LDCTRL =  'LDCTRL',
  METERING =  'METERING',
  OTAMGR =  'OTAMGR',
  OTAMGR_CLASSIC =  'OTAMGR_CLASSIC',
  PINGTEST =  'PINGTEST',
  THERMALMGMT =  'THERMALMGMT',
  TOU =  'TOU',
  TIMESYNC =  'TIME-SYNC',
  WLANAPP =  'WLANAPP',
  CONFMGR =  'CONFMGR',
  CTLOGMGR =  'CTLOGMGR',
  HBT =  'HBT',
  QTMGR =  'QTMGR',
  STUNNEL =  'STUNNEL',
  LASTGASP =  'LASTGASP',
  TAMPER =  'TAMPER',
  RFID =  'RFID',
  LAIRDBT =  'LAIRD-BT',
  CELLMODEM =  'CELLMODEM',
  LED =  'LED',
  EMBNETWORKWWAN =  'EMB-NETWORK-WWAN',
  SSH =  'SSH',
  TRAMPOLINE =  'TRAMPOLINE',
  INSTALLWIZARD =  'INSTALLWIZARD',
  JOURNAL =  'JOURNAL',
  CHRONY =  'CHRONY',
  NLMSGBRGD =  'NLMSGBRGD',
  INITD =  'INIT-D'
}

enum Severity {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Debug = 'debug',
  LOGS = 'LOGS',
  TCPDUMP = 'TCPDUMP',
  ALL = 'ALLLOGS'
}

const GetLogsModalHolder: React.FC<Props> = props => {
  const { t } = useTranslation();
  const [facility, setFacility] = useState(Facility.ALL);
  const [severity, setSeverity] = useState(
    props.logType === 'gdiagfiles' ? Severity.ALL : Severity.Error
  );
  let date = new Date();
  date.setTime(date.getTime() + props.timeZoneOffset * 1000);
  let yesterdayDate = twentyFourHoursAgo(date);
  const today = date.toISOString().substring(0, 10);
  const time = date.toISOString().substring(11, 16);
  const timestamp = date.getTime();
  date = new Date(today);
  const yesterday = yesterdayDate.toISOString().substring(0, 10);
  const yesterdayTime = yesterdayDate.toISOString().substring(11, 16);
  const yesterdayTimestamp = yesterdayDate.getTime();
  yesterdayDate = new Date(yesterday);
  const [fromTime, setFromTime] = useState({
    date: yesterdayDate.getTime(),
    time: (Number(yesterdayTime.split(':')[0]) * 60 + Number(yesterdayTime.split(':')[1])) * 60 * 1000,
    timestamp: yesterdayTimestamp
  });
  const [toTime, setToTime] = useState({
    date: date.getTime(),
    time: (Number(time.split(':')[0]) * 60 + Number(time.split(':')[1])) * 60 * 1000,
    timestamp: timestamp
  });
  const [isLoading, setIsLoading] = useState(false);
  const [serverMsg, setServerMsg] = useState('');
  const [link, setLink] = useState('');

  const facilityOptions = [
    {
      label: Facility.ALL,
      value: Facility.ALL
    }
  ];

  const facilityAllOptions = [
    {
      label: Facility.ALL,
      value: Facility.ALL
    },
    {
      label: Facility.CLOUDCLIENT,
      value: Facility.CLOUDCLIENT
    },
    {
      label: Facility.MCP,
      value: Facility.MCP
    },
    {
      label: Facility.PILOT,
      value: Facility.PILOT
    },
    {
      label: Facility.BACKLIGHT,
      value: Facility.BACKLIGHT
    },
    {
      label: Facility.CARDREADER,
      value: Facility.CARDREADER
    },
    {
      label: Facility.CHARGING,
      value: Facility.CHARGING
    },
    {
      label: Facility.FAULTMGR,
      value: Facility.FAULTMGR
    },
    {
      label: Facility.FRONTEND,
      value: Facility.FRONTEND
    },
    {
      label: Facility.LDCTRL,
      value: Facility.LDCTRL
    },
    {
      label: Facility.METERING,
      value: Facility.METERING
    },
    {
      label: Facility.OTAMGR,
      value: Facility.OTAMGR
    },
    {
      label: Facility.OTAMGR_CLASSIC,
      value: Facility.OTAMGR_CLASSIC
    },
    {
      label: Facility.PINGTEST,
      value: Facility.PINGTEST
    },
    {
      label: Facility.THERMALMGMT,
      value: Facility.THERMALMGMT
    },
    {
      label: Facility.TOU,
      value: Facility.TOU
    },
    {
      label: Facility.TIMESYNC,
      value: Facility.TIMESYNC
    },
    {
      label: Facility.WLANAPP,
      value: Facility.WLANAPP
    },
    {
      label: Facility.CONFMGR,
      value: Facility.CONFMGR
    },
    {
      label: Facility.CTLOGMGR,
      value: Facility.CTLOGMGR
    },
    {
      label: Facility.HBT,
      value: Facility.HBT
    },
    {
      label: Facility.QTMGR,
      value: Facility.QTMGR
    },
    {
      label: Facility.STUNNEL,
      value: Facility.STUNNEL
    },
    {
      label: Facility.LASTGASP,
      value: Facility.LASTGASP
    },
    {
      label: Facility.TAMPER,
      value: Facility.TAMPER
    },
    {
      label: Facility.RFID,
      value: Facility.RFID
    },
    {
      label: Facility.LAIRDBT,
      value: Facility.LAIRDBT
    },
    {
      label: Facility.CELLMODEM,
      value: Facility.CELLMODEM
    },
    {
      label: Facility.LED,
      value: Facility.LED
    },
    {
      label: Facility.EMBNETWORKWWAN,
      value: Facility.EMBNETWORKWWAN
    },
    {
      label: Facility.SSH,
      value: Facility.SSH
    },
    {
      label: Facility.TRAMPOLINE,
      value: Facility.TRAMPOLINE
    },
    {
      label: Facility.INSTALLWIZARD,
      value: Facility.INSTALLWIZARD
    },
    {
      label: Facility.JOURNAL,
      value: Facility.JOURNAL
    },
    {
      label: Facility.CHRONY,
      value: Facility.CHRONY
    },
    {
      label: Facility.NLMSGBRGD,
      value: Facility.NLMSGBRGD
    },
    {
      label: Facility.INITD,
      value: Facility.INITD
    },
  ];

  const severityOptions = [
    {
      label: t('error'),
      value: Severity.Error
    },
    {
      label: t('warning'),
      value: Severity.Warning
    },
    {
      label: t('info'),
      value: Severity.Info
    },
    {
      label: t('debug'),
      value: Severity.Debug
    }
  ];

  const severityDiagOptions = [
    {
      label: t('facility_all').toUpperCase(),
      value: Severity.ALL
    },
    {
      label: Severity.LOGS,
      value: Severity.LOGS
    },
    {
      label: Severity.TCPDUMP,
      value: Severity.TCPDUMP
    }
  ];

  function getLogs() {
    setIsLoading(true);
    const formData = new FormData();
    let date = new Date(fromTime.timestamp);
    formData.set(
      'from_time',
      date
        .toISOString()
        .substring(0, 16)
        .replace('T', ' ')
    );
    date = new Date(toTime.timestamp);
    formData.set(
      'to_time',
      date
        .toISOString()
        .substring(0, 16)
        .replace('T', ' ')
    );
    formData.set('log_level', severity);
    formData.set('facility', facility);
    formData.set('mac_addr', props.macAddress);

    fetch('/stations/fetch_gs_logs', {
      method: 'POST',
      body: formData
    })
      .then(r => r.json())
      .then(result => {
        if (typeof result.response === 'undefined') {
          setServerMsg(t('server.error'));
        } else {
          setServerMsg('');
          setLink(result.response);
        }
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return (
      <FormWrapper>
        <KitSpinner />
      </FormWrapper>
    );
  }

  if (!isLoading && link !== '') {
    return (
      <FormWrapper>
        <p>{t('get_logs.save_link')}</p>
        <strong>
          <a href={`https://${link}`}>{link}</a>
        </strong>
      </FormWrapper>
    );
  }

  return (
    <KitForm onSubmit={getLogs}>
      <FormWrapper>
        {serverMsg !== '' && <StyledWarning>{serverMsg}</StyledWarning>}
        <StyledInfo>
          {props.logType === 'gdiagfiles'
            ? t('get_logs.logs_in_local_time')
            : t('get_logs.select_log_retrieval')}
        </StyledInfo>
        <KitFlexWrapRow>
          {props.logType !== 'gdiagfiles' && (
            <SelectWrapper>
              <KitForm.Select
                label={t('get_logs.facility')}
                required
                options={props.showLogOptions ? facilityAllOptions : facilityOptions}
                id='facility'
                components={{
                  IndicatorSeparator: null
                }}
                onChange={val => {
                  setFacility(val.value);
                }}
              />
            </SelectWrapper>
          )}
          <SelectWrapper>
            <KitForm.Select 
              label={t(props.logType === 'gdiagfiles' ? 'get_logs.log_type' : 'get_logs.severity')}
              defaultValue={
                props.logType === 'gdiagfiles' ? severityDiagOptions[0] : severityOptions[0]
              }
              required
              options={props.logType === 'gdiagfiles' ? severityDiagOptions : severityOptions}
              id='severity'
              components={{
                IndicatorSeparator: null
              }}
              onChange={val => {
                setSeverity(val.value);
              }}
            />
            
          </SelectWrapper>
        </KitFlexWrapRow>
        <KitFlexWrapRow>
          <InputWrapper>
            <KitForm.Label text={t('from')} htmlFor='dateFrom' />
            <KitInput
              required
              type='date'
              id='dateFrom'
              max={today}
              enterKeyHint='enter'
              defaultValue={yesterday}
              onChange={e => {
                setFromTime({
                  ...fromTime,
                  ...{
                    date: e.target.valueAsNumber,
                    timestamp: e.target.valueAsNumber + fromTime.time
                  }
                });
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <KitForm.Label text=' ' htmlFor='timeFrom' />
            <br />
            <KitInput
              required
              type='time'
              id='timeFrom'
              enterKeyHint='enter'
              defaultValue={yesterdayTime}
              onChange={e => {
                setFromTime({
                  ...fromTime,
                  ...{
                    time: e.target.valueAsNumber,
                    timestamp: e.target.valueAsNumber + fromTime.date
                  }
                });
              }}
            />
          </InputWrapper>
        </KitFlexWrapRow>
        <KitFlexWrapRow>
          <InputWrapper>
            <KitForm.Label text={t('to')} htmlFor='dateTo' />
            <KitInput
              required
              type='date'
              id='dateTo'
              max={today}
              defaultValue={today}
              enterKeyHint='enter'
              onChange={e => {
                setToTime({
                  ...toTime,
                  ...{
                    date: e.target.valueAsNumber,
                    timestamp: e.target.valueAsNumber + toTime.time
                  }
                });
              }}
            />
          </InputWrapper>
          <InputWrapper>
            <KitForm.Label text=' ' htmlFor='timeTo' />
            <br />
            <KitInput
              required
              type='time'
              id='timeTo'
              enterKeyHint='enter'
              defaultValue={time}
              onChange={e => {
                setToTime({
                  ...toTime,
                  ...{
                    time: e.target.valueAsNumber,
                    timestamp: e.target.valueAsNumber + toTime.date
                  }
                });
              }}
            />
          </InputWrapper>
        </KitFlexWrapRow>
        <p>
          <a
            href={`/station_logs?macAddress=${props.macAddress}`}
            target='_blank'
            rel='noopener noreferrer'
          >
            {props.logType === 'gdiagfiles'
              ? t('get_logs.view_pulled_logs')
              : t('get_logs.view_available_log')}
          </a>
        </p>
      </FormWrapper>
      <KitFlexWrapRowSpaced>
        <KitButton
          onClick={() => {
            props.onClose();
          }}
          variant='secondary'
          disabled={isLoading}
        >
          {t('cancel')}
        </KitButton>
        <KitButton variant='primary' type='submit' disabled={isLoading}>
          {t('get')}
        </KitButton>
      </KitFlexWrapRowSpaced>
    </KitForm>
  );
};

export default GetLogsModalHolder;
