import React, { cloneElement, useState, Children, ReactElement } from 'react';
import { Translation } from 'react-i18next';
import { KitButton } from '@chargepoint/cp-toolkit';
import { ModalWrapper, ModalContent, FormFooter } from './styled';

export interface ModalProps {
  content: ReactElement;
  children: ReactElement;
  onAgree: Function;
  onCancel: Function;
  open: boolean;
}

export function Modal(props: ModalProps) {
  const { children, content, onAgree, onCancel } = props;
  const [isOpen, setIsOpen] = useState(props.open);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    return setIsOpen(true);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    onCancel(event);
    setIsOpen(false);
  };

  const handleAgree = (event: React.MouseEvent<HTMLElement>) => {
      onAgree(event);
      setIsOpen(false);
  };

  const element = cloneElement(content, {
    onClick: handleOpen
  });

  const modalContent = cloneElement(Children.only(children), {
    onCancel: handleClose,
    onSubmit: handleClose
  });

  return (
    <>
      {element}
      {isOpen && (
        <ModalWrapper onClick={handleClose}>
          <ModalContent onClick={event => event.stopPropagation()}>
            {modalContent}
            <FormFooter>
              <KitButton variant='secondary' onClick={handleClose}>
                <Translation>{t => t('videoAd.cancel')}</Translation>
              </KitButton>
              <KitButton onClick={handleAgree}>
                <Translation>{t => t('videoAd.agree')}</Translation>
              </KitButton>
            </FormFooter>
          </ModalContent>
        </ModalWrapper>
      )}
    </>
  );
}
