import React, {
  createContext,
  useState,
  useCallback,
  ReactNode,
  Ref,
} from 'react';

interface FormContextValue {
  values: Record<string, any>;
  setValue: (field: string, newValue: any) => void;
}

interface FormProviderProps {
  children: ReactNode;
  formRef?: Ref<HTMLFormElement>;
  initialValues: FormContextValue['values'];
  onSubmit(values: FormContextValue['values']): Promise<void> | void;
}

export const FormContext = createContext<FormContextValue>({
  values: {},
  setValue() {
    return;
  },
});

const defaultSubmit = () => {};

export function FormProvider(props: FormProviderProps) {
  const { onSubmit = defaultSubmit, formRef } = props;
  const [isSubmitting, setSubmitting] = useState(false);
  const [values, setFormValues] = useState(props.initialValues);
  const setValue: FormContextValue['setValue'] = (field, newValue) => {
    setFormValues(currentValues => {
      const newValues = Object.assign({}, currentValues);
      newValues[field] = newValue;
      return newValues;
    });
  };

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault();
      setSubmitting(true);

      try {
        await onSubmit(values);
      } catch {
        // nothing
      } finally {
        setSubmitting(false);
      }
    },
    [onSubmit, values]
  );

  const providerValue = {
    isSubmitting,
    values,
    setValue,
  };

  return (
    <FormContext.Provider value={providerValue}>
      <form ref={formRef} onSubmit={handleSubmit}>
        {props.children}
      </form>
    </FormContext.Provider>
  );
}
