import React from 'react';
import { KitButton } from '@chargepoint/cp-toolkit';
import { useTranslation } from 'react-i18next';
import styles from './buttonset.module.scss';

function ButtonSet(props: any) {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.buttonContainer}>
        <KitButton variant='secondary' onClick={props.cancelFunction}>
          {t('ButtonSet.cancel')}
        </KitButton>
        <KitButton variant='secondary' onClick={props.resetFunction}>
          {t('ButtonSet.reset')}
        </KitButton>
        <KitButton>{t('ButtonSet.submit')}</KitButton>
      </div>
    </>
  );
}

export default ButtonSet;
