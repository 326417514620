import VideoUploaderWrapper from '../components/video-ad/VideoUploader';
import HelpVideos from '../help-docs/Pages/HelpVideos';
import ReleaseNotes from '../help-docs/Pages/ReleaseNotes';
import WaitlistFAQ from '../help-docs/Pages/WaitlistFAQ';
import AccountPage from '../pages/Account';
import FuelCardProvidersPage from '../pages/FuelCardProviders/FuelCardProvidersPage';

interface DeveloperRoute {
  path: string;
  navigatePath?: string;
  name: string;
  description: string;
  component: () => JSX.Element;
  childRoutes?: DeveloperRoute[];
  appendOrgId?: boolean;
}

const useDeveloperRoutes = () => {
  const getFuelCardProvidersPage = () => {
    return <FuelCardProvidersPage />;
  };
  const pathPrefix = '/r/admin/developer/';
  const developerRoutes: DeveloperRoute[] = [
    {
      path: `${pathPrefix}fuel-card-providers`,
      name: 'Fuel Card Providers',
      description: 'Manage fuel card providers',
      component: () => {
        return <FuelCardProvidersPage />;
      },
    },
    {
      path: `${pathPrefix}help-videos`,
      name: 'Help Videos',
      description: 'Manage help videos',
      component: () => {
        return <HelpVideos />;
      },
    },
    {
      path: `${pathPrefix}release-notes`,
      name: 'Release Notes',
      description: 'Manage release notes',
      component: () => {
        return <ReleaseNotes />;
      },
    },
    {
      path: `${pathPrefix}waitlist-faq`,
      name: 'Waitlist FAQ',
      description: 'Manage waitlist FAQ',
      component: () => {
        return <WaitlistFAQ />;
      },
    },
    {
      path: `${pathPrefix}video-uploader`,
      name: 'Video Ads Uploader',
      description: 'Manage video ads',
      component: () => {
        return <VideoUploaderWrapper />;
      },
    },
    {
      path: `${pathPrefix}account`,
      name: 'Account',
      description: 'Manage account',
      component: () => {
        return <AccountPage basePath={`${pathPrefix}account`} />;
      },
      childRoutes: [
        {
          path: 'notifications',
          name: 'Notifications',
          description: 'Manage notifications',
          component: () => {
            return <AccountPage basePath={`${pathPrefix}account`} />;
          },
        },
        {
          path: 'overview',
          name: 'Overview',
          description: 'Manage overview',
          component: () => {
            return <AccountPage basePath={`${pathPrefix}account`} />;
          },
        },
      ],
    },
  ];

  return { developerRoutes, getFuelCardProvidersPage };
};

export default useDeveloperRoutes;
