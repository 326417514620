export const releaseNotesConfig = [
  {
    name: "3Q2021",
    version: "7.41 – 7.46",
    pdf: "Chargepoint-3Q2021-ReleaseNotes.pdf",
    highlights: "releasenotes.3Q2021_highlights"
  },
  {
    name: "4Q2019",
    version: "6.95 – 7.00",
    pdf: "chargepoint-4Q2019-release-notes-v1.1-20200101.pdf",
    highlights: "releasenotes.note_0_highlights"
  },
  {
    name: "3Q2019",
    version: "6.88 – 6.94",
    pdf: "chargepoint-3Q2019-release-notes-v1.1-20191001.pdf",
    highlights: "releasenotes.note_1_highlights"
  },
  {
    name: "2Q2019",
    version: "6.82 – 6.87",
    pdf: "chargepoint-2Q2019-release-notes-v1.1-20190701.pdf",
    highlights: "releasenotes.note_2_highlights"
  },
  {
    name: "1Q2019",
    version: "6.75 – 6.81",
    pdf: "chargepoint-1Q2019-release-notes-v1.1-20190401.pdf",
    highlights: "releasenotes.note_3_highlights"
  },
  {
    name: "4Q2018",
    version: "6.70 – 6.74",
    pdf: "chargepoint-4Q2018-release-notes-v1.1-20190101.pdf",
    highlights: "releasenotes.note_4_highlights"
  },
  {
    name: "3Q2018",
    version: "6.63 – 6.69",
    pdf: "chargepoint-3Q2018-release-notes-v1.0-20181001.pdf",
    highlights: "releasenotes.note_5_highlights"
  },
  {
    name: "2Q2018",
    version: "6.57 – 6.62",
    pdf: "chargepoint-2Q2018-release-notes-v1.0-20180701.pdf",
    highlights: "releasenotes.note_6_highlights"
  },
  {
    name: "1Q2018",
    version: "6.50 – 6.56",
    pdf: "chargepoint-1Q2018-release-notes-v1.0-20180401.pdf",
    highlights: "releasenotes.note_7_highlights"
  },
  {
    name: "4Q2017",
    version: "6.44 – 6.49",
    pdf: "chargepoint-4Q2017-release-notes-v1.0-20180101.pdf",
    highlights: "releasenotes.note_8_highlights"
  },
  {
    name: "3Q2017",
    version: "6.37 – 6.43",
    pdf: "chargepoint-3Q2017-release-notes-v1.0-20171001.pdf",
    highlights: "releasenotes.note_9_highlights"
  },
  {
    name: "2Q2017",
    version: "6.31 – 6.36",
    pdf: "chargepoint-2Q2017-release-notes-v1.0-20170701.pdf",
    highlights: "releasenotes.note_10_highlights"
  },
  {
    name: "1Q2017",
    version: "6.24 – 6.30",
    pdf: "chargepoint-1Q2017-release-notes-v1.0-20170401.pdf",
    highlights: "releasenotes.note_11_highlights"
  },
  {
    name: "4Q2016",
    version: "6.18 – 6.23",
    pdf: "chargepoint-4Q2016-release-notes-v1.0-20170103.pdf",
    highlights: "releasenotes.note_12_highlights"
  },
  {
    name: "3Q2016",
    version: "6.11 – 6.17",
    pdf: "chargepoint-3Q2016-release-notes-v1.0-20161001.pdf",
    highlights: "releasenotes.note_13_highlights"
  },
  {
    name: "2Q2016",
    version: "6.4 .. 6.10",
    pdf: "chargepoint-2Q2016-release-notes-v1.0-20160809.pdf",
    highlights: "releasenotes.note_14_highlights"
  },
  {
    name: "1Q2016",
    version: "5.51 .. 6.3",
    pdf: "chargepoint-1Q2016-release-notes-v1.1-20160320.pdf",
    highlights: "releasenotes.note_15_highlights"
  },
  {
    name: "4Q2015",
    version: "5.44 .. 5.50",
    pdf: "chargepoint-4Q2015-release-notes-v1.1-20160302.pdf",
    highlights: "releasenotes.note_16_highlights"
  },
  {
    name: "3Q2015",
    version: "5.38 .. 5.43",
    pdf: "chargepoint-3Q2015-release-notes-v1.1-20160302.pdf",
    overview: "object-granting-overview-v0.2-20150823.pdf",
    highlights: "releasenotes.note_17_highlights"
  },
  {
    name: "2Q2015",
    version: "5.31 .. 5.37",
    pdf: "chargepoint-2Q2015-release-notes-v1.1-20150705.pdf",
    highlights: "releasenotes.note_18_highlights"
  },
  {
    name: "1Q2015",
    version: "5.20 .. 5.30",
    pdf: "chargepoint-1Q2015-release-notes-v1.0-20150419.pdf",
    highlights: "releasenotes.note_19_highlights"
  },
  {
    name: "4Q2014",
    version: "5.16 .. 5.19",
    pdf: "chargepoint-4Q2014-release-notes-v1.0-20150419.pdf",
    highlights: "releasenotes.note_20_highlights"
  },
  {
    name: "2014-09-26",
    version: "5.10 .. 5.15",
    pdf: "chargepoint-5.15-release-notes-v1.0-20141001.pdf",
    highlights: "releasenotes.note_21_highlights"
  },
  {
    name: "2014-06-20",
    version: "5.5 .. 5.9",
    pdf: "chargepoint-5.9-release-notes-v1.0-20140623.pdf",
    highlights: "releasenotes.note_22_highlights"
  },
  {
    name: "2014-04-11",
    version: "5.4",
    pdf: "chargepoint-5.4-release-notes-v1.0-20140412.pdf",
    overview: "chargepoint-5.4-overview-v1.0-20140409.pdf",
    highlights: "releasenotes.note_23_highlights"
  },
  {
    name: "2014-03-28",
    version: "5.3",
    pdf: "chargepoint-5.3-release-notes-v1.1-20140331.PDF",
    overview: "chargepoint-5.3-preliminary-v1.0-20140323.pdf",
    highlights: "releasenotes.note_24_highlights"
  },
  {
    name: "2014-03-21",
    version: "5.2",
    pdf: "chargepoint-5.2-release-notes-v1.0-20140321-PUBLIC.pdf",
    highlights: "releasenotes.note_25_highlights"
  },
  {
    name: "2014-02-27",
    version: "5.1",
    pdf: "chargepoint-5.1-release-notes-v1.1-20140321-PUBLIC.pdf",
    highlights: "releasenotes.note_26_highlights"
  },
  {
    name: "2014-02-07",
    version: "5.0.1",
    pdf: "chargepoint-5.0.1-release-notes-v1.0-20140207-PUBLIC.pdf",
    highlights: "releasenotes.note_27_highlights"
  },
  {
    name: "2014-02-01",
    version: "5.0",
    pdf: "chargepoint-5.0-release-notes-v1.1-20140207-PUBLIC.pdf",
    highlights: "releasenotes.note_28_highlights"
  }
];
