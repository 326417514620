import React, {useState, useEffect, useContext} from 'react';
import { EMVData, ReceiptData, ReceiptDetails } from '@chargepoint/web-common';
import {Trans, useTranslation} from 'react-i18next';
import { AccountService } from '@chargepoint/common/services/AccountService';
import { KitSpinner } from '@chargepoint/cp-toolkit';
import { SessionContext } from '@chargepoint/common/hooks/SessionContext';
import {
  CORPORATE_DRIVER_FAQ_URL,
  CORPORATE_SUPPORT_URL,
  DEFAULT_CORPORATE_URL,
  NosBackendService,
  RECEIPT_EMV_DATA_ROLES
} from '../../services/NosBackendService';

export interface Props {
  vehicleChargeId: string;
}

const ReceiptModalHolder: React.FC<Props> = props => {
  const { t, i18n } = useTranslation();
  const { session } = useContext(SessionContext);
  const [receiptData, setReceiptData] = useState<ReceiptData | null>(null);
  const [emvData, setEMVData] = useState<EMVData | null>(null);

  const [supportLink, setSupportLink] = useState(DEFAULT_CORPORATE_URL + CORPORATE_SUPPORT_URL);
  const [faqLink, setFaqLink] = useState(DEFAULT_CORPORATE_URL + CORPORATE_DRIVER_FAQ_URL);

  useEffect(() => {
    NosBackendService.getLocaleCorporateUrl(i18n.language.toLowerCase()).then(url => {
      if (url) {
        setSupportLink(url + CORPORATE_SUPPORT_URL);
        setFaqLink(url + CORPORATE_DRIVER_FAQ_URL);
      }
    });
  }, [i18n.language]);

  useEffect(() => {
    (async () => {
      if (props.vehicleChargeId) {
        const result = await AccountService.getReceiptData(props.vehicleChargeId);
        document.dispatchEvent(
          new CustomEvent('update-ng-title', {
            detail: {
              title:
                result.showInvoice === true
                  ? t('common:receipt.invoice')
                  : t('common:receipt.receipt')
            }
          })
        );
        if (result.session_type === 'CONTACTLESS' && RECEIPT_EMV_DATA_ROLES.indexOf(session.userInfo.userRole) !== -1) {
          const emvData = await AccountService.getReceiptEMVData(props.vehicleChargeId);
          setEMVData(emvData);
        } else {
          setEMVData(null);
        }
        setReceiptData(result);
      }
    })();
  }, [props.vehicleChargeId, t, session.userInfo.userRole]);

  if (!props.vehicleChargeId  || !session.userInfo) {
    return null;
  }

  if (receiptData === null) {
    return <KitSpinner />;
  }

  return(
    <ReceiptDetails
      t={t}
      corpSupportUrl={supportLink}
      corporateFAQUrl={faqLink}
      nosServerEndpoint={window.location.host}
      accountEndPoint={AccountService.getAccountApiEndpoint()}
      chargingSessionData={receiptData}
      emvData={emvData}
      locale={i18n.language}
      Trans={Trans}
      isLoggedIn={session.isLoggedIn}
  />
  )
};

export default ReceiptModalHolder;
