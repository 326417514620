import React, { createRef, Component } from 'react';
import { KitButton, KitSpinner, ThemeConstants } from '@chargepoint/cp-toolkit';
import { Translation } from 'react-i18next';
import {
  FieldContainer,
  UploaderContainer,
  UploaderWrapper,
  ErrorLabel,
  LabelText,
  FieldRow,
  FileLabel,
  Select as StyledSelect
} from './styled';
import { Modal } from './Modal';
import { Metadata } from './Metadata';
import { pollMetadata } from './utils';
import { FormContext } from '../contexts/FormContext';

const DEFAULT_FILE_NAME = 'selectFile';

interface UploaderProps {
  videoAdUuid: string;
  awsUrl?: string;
  onUploadStart?: Function;
  onUploadFinish?: Function;
  videoAdFileMetadata: any;
  videoAdCPFilePath?: string;
  videoAdFileOriginalName?: string;
  isEdit: boolean;
  error?: any;
  uploadDisabled: boolean;
  registeredAt?: number;
  videoAdTranscodingData?: any;
}

interface UploaderState {
  videoURL: string | null;
  signedUrl: string | null;
  isUploadFinished: boolean;
  progress: number;
  fileName: string;
  showThanks: boolean;
  showUpload: boolean;
  isMetaLoading: boolean;
  metaData: any;
  registeredAt?: number;
  error?: any;
  selectedProfile?: any;
}

class Uploader extends Component<UploaderProps, UploaderState> {
  
  context: any;

  static contextType = FormContext;

  uploaderRef = createRef<any>();

  fileInput = createRef<any>();

  metaSubscription: any;

  constructor(props: UploaderProps) {
    super(props);
    this.state = {
      signedUrl: null,
      isUploadFinished: true,
      progress: 0,
      videoURL: null,
      fileName: DEFAULT_FILE_NAME,
      showThanks: false,
      showUpload: false,
      isMetaLoading: false,
      metaData: null,
      registeredAt: undefined,
      selectedProfile: {}
    };
  }

  componentWillUnmount() {
    if (this.metaSubscription && !this.metaSubscription.closed) {
      this.metaSubscription.unsubscribe();
    }
  }

  handleUpload = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.setState({ showUpload: false, showThanks: false, metaData: null });
    this.uploaderRef.current.uploadFile();
  };

  handleCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (this.props.isEdit) {
      this.setState({ showUpload: false });
    }
  };

  handleUploadNew = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.fileInput.current.click();
  };

  handleDownload = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const videoAdCPFilePath = this.state.selectedProfile.path || this.props.videoAdCPFilePath;
    if (videoAdCPFilePath) {
      window.open(videoAdCPFilePath.toString());
    }
  };

  handleSignedUrl = ({ signedUrl }: { signedUrl: string }) => {
    this.setState({
      signedUrl
    });
  };

  handleUploadStart = (file: File, next: Function) => {
    if (this.props.onUploadStart) {
      this.props.onUploadStart(this.state.signedUrl);
    }

    this.setState({ isUploadFinished: false, isMetaLoading: true });
    next(file);
  };

  handleUploadFinish = () => {
    if (this.props.onUploadFinish) {
      this.props.onUploadFinish(this.state.signedUrl);
    }
    this.setState({ isUploadFinished: true });
    this.uploaderRef.current.clear();

    this.metaSubscription = pollMetadata(
      `${this.props.awsUrl}${this.props.videoAdUuid}/metadata`,
      this.state.registeredAt || this.props.registeredAt
    ).subscribe((data: any) => {
      if (data.Items[0]) {
        const metaData = data.Items[0];
        this.setState({ registeredAt: metaData.registeredAt });

        if (metaData.error) {
          this.setState({
            error: {
              code: metaData.error.code
            }
          });
        } else {
          this.setState({ showThanks: true });
        }

        const fileData = metaData.stagingFile || metaData.sourceFile;
        this.context.setValue('videoAdFileMetadata', fileData.meta);
        this.context.setValue('videoAdCPFilePath', fileData.path);
        this.context.setValue('videoAdFileOriginalName', fileData.meta.Filename);
        this.setState({ metaData: fileData.meta });
      }

      this.setState({ isMetaLoading: false });
    });
  };

  handleProgress = (progress: number) => this.setState({ progress });

  handleMetaData = (event: any) => {
    event.preventDefault();

    if (event.target.duration > 62) {
      this.setState({ error: { code: 120 } });
    } else {
      this.setState({ error: null });
      this.setState({ showUpload: true });
    }
  };

  handleChange = (event: any) => {
    event.preventDefault();
    if (this.state.videoURL) {
      window.URL.revokeObjectURL(this.state.videoURL as any);
    }
    let videoURL = null;
    const fileName = event.target.files[0].name;
    const fileType = event.target.files[0].type;

    if (fileType.match('video.*') === null) {
      this.setState({ error: { code: 127 } });
      return;
    }
    videoURL = window.URL.createObjectURL(event.target.files[0]);
    this.setState({ error: null });

    this.setState({ videoURL, fileName, showUpload: false });
  };

  handleProfileChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const [profileId, profileName] = event.target.value.split(':');
    let profile = (this.props.videoAdTranscodingData as any)[`profile${profileId}`];

    if (typeof profile === 'undefined') {
      profile = {};
    }

    if (profile.error) {
      if (!this.props.uploadDisabled) {
        this.setState({ error: profile.error });
      }
    } else {
      profile.profileName = profileName;
      this.setState({ selectedProfile: profile, error: null, metaData: profile.meta });
    }
  };

  getFileName = () => {
    const videoAdFileOriginalName =
      this.state.selectedProfile.path || this.props.videoAdFileOriginalName;
    if (videoAdFileOriginalName && this.state.fileName === DEFAULT_FILE_NAME) {
      if (videoAdFileOriginalName.indexOf('http') === -1) {
        return videoAdFileOriginalName;
      }

      const filePath = videoAdFileOriginalName.split('/');
      return `${filePath[filePath.length - 3]}/${filePath[filePath.length - 1]}`;
    }

    return this.state.fileName;
  };

  render() {
    const error = this.state.error || this.props.error;
    let fileName = this.getFileName();
    const maxLength = this.props.isEdit ? 60 : 40;
    if (fileName.length > maxLength) {
      fileName = `...${fileName.substring(fileName.length - maxLength)}`;
    }
    return (
      <Translation>
        {t => (
          <>
            <FieldContainer>
              <UploaderContainer>
                <UploaderWrapper
                  ref={this.uploaderRef}
                  inputRef={this.fileInput}
                  signingUrl=''
                  signingUrlMethod='GET'
                  signingUrlWithCredentials={false}
                  uploadRequestHeaders={{ 'Content-Type': 'multipart/form-data' }}
                  server={this.props.awsUrl}
                  name='uploader'
                  id='video-ad-upload'
                  accept='video/*,.mov,.flv,.vob,.3gp,.mts,.mkv,.wmv'
                  s3path={this.props.videoAdUuid}
                  autoUpload={false}
                  preprocess={this.handleUploadStart}
                  onFinish={this.handleUploadFinish}
                  onProgress={this.handleProgress}
                  onSignedUrl={this.handleSignedUrl}
                  onChange={this.handleChange}
                  scrubFilename={(file: any) => file.replace(/[^\w\d_\-.]+/gi, '_')}
                />
                <FieldRow style={{ flex: 1 }}>
                  <LabelText>{t('videoAd.fileName')}</LabelText>
                  <FileLabel>
                    {this.props.isEdit && (
                      <>
                        {this.state.videoURL ? (
                          <label htmlFor='videoAdFiles'>{fileName}</label>
                        ) : (
                          <StyledSelect onChange={this.handleProfileChange} name='videoAdFiles'>
                            <option value={`original:${t('videoAd.originalLabel')}`}>
                              {t('videoAd.originalLabel')}
                            </option>
                            {this.props.videoAdTranscodingData &&
                              (this.props.videoAdTranscodingData as any).profilesData.map(
                                (val: any) =>
                                  (this.props.videoAdTranscodingData as any)[
                                    `profile${val.profile_id}`
                                  ] && (
                                    <option value={`${val.profile_id}:${val.profile_name}`}>
                                      {val.profile_name}
                                    </option>
                                  )
                              )}
                          </StyledSelect>
                        )}
                        <br />
                      </>
                    )}
                    {!this.props.isEdit && (
                      <label
                        htmlFor='video-ad-upload'
                        style={{ cursor: 'pointer', color: 'blue', width: '100%' }}
                      >
                        {fileName === DEFAULT_FILE_NAME ? t(`videoAd.${fileName}`) : fileName}
                      </label>
                    )}
                    {this.state.videoURL && (
                      // eslint-disable-next-line jsx-a11y/media-has-caption
                      <video
                        src={this.state.videoURL as any}
                        preload='metadata'
                        onLoadedMetadata={this.handleMetaData}
                        onError={this.handleMetaData} // fix for flv, avi files
                        style={{ display: 'none' }}
                      />
                    )}
                  </FileLabel>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {!this.state.isUploadFinished && (
                      <div style={{ fontWeight: 'bold' }}>{this.state.progress}%</div>
                    )}
                    {this.state.isUploadFinished && this.state.isMetaLoading && (
                      <div style={{ fontWeight: 'bold' }}>{t('videoAd.loadingMetadata')}</div>
                    )}
                    {this.state.isMetaLoading && <KitSpinner size='s' />}
                    {!this.props.uploadDisabled && (
                      <>
                        {this.props.isEdit && !this.state.isMetaLoading && (
                          <KitButton variant='secondary' onClick={this.handleUploadNew}>
                            {t('videoAd.upload_new')}
                          </KitButton>
                        )}
                        {this.state.showUpload && (
                          <Modal
                            onAgree={this.handleUpload}
                            onCancel={this.handleCancel}
                            open={this.props.isEdit}
                            content={<KitButton>{t('videoAd.upload')}</KitButton>}
                          >
                            <div style={{ textAlign: 'justify' }}>
                              {t('videoAd.uploadAgreement')}
                            </div>
                          </Modal>
                        )}
                      </>
                    )}
                    {this.props.videoAdCPFilePath && this.props.isEdit && (
                      <KitButton
                        variant='secondary'
                        style={{ marginLeft: `${ThemeConstants.spacing.absolute.s}px` }}
                        onClick={this.handleDownload}
                      >
                        {t('videoAd.download')}
                      </KitButton>
                    )}
                  </div>
                </FieldRow>
              </UploaderContainer>
            </FieldContainer>
            <FieldContainer>
              <FieldRow>
                <LabelText> </LabelText>
                <FileLabel style={{ maxWidth: '75%' }}>
                  {this.props.isEdit && !this.state.videoURL && <p>{fileName}</p>}
                  {error && (
                    <ErrorLabel>
                      {error.code ? t(`videoAd.errors.${error.code}`) : error.message}
                    </ErrorLabel>
                  )}
                </FileLabel>
              </FieldRow>
            </FieldContainer>
            <Metadata data={this.state.metaData || this.props.videoAdFileMetadata} />
            {this.state.showThanks && (
              <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
                {t('videoAd.uploadThanks')}
              </div>
            )}
          </>
        )}
      </Translation>
    );
  }
}

export default Uploader;
