/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { KitSpinner, ThemeConstants } from '@chargepoint/cp-toolkit';
import { PaymentForm } from '@chargepoint/web-common';
import useSession from '../hooks/useGetSession';
import PaymentService from '../services/PaymentService';
import { TransactionStatus } from 'globalpayments-3ds';
import AnalyticsService from '../services/analytics/AnalyticsService';
import { analyticEvents } from '../services/analytics/AnalyticEvents';

const FormContent = styled.div`
  padding: ${ThemeConstants.spacing.absolute.m}px;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

const StyledSpinner = styled.div`
  position: absolute;
  top: 50%;
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
`;

const ErrorText = styled.strong`
  color: red;
`;

export interface Props {
  onClose: Function;
  fleetId: string;
}

const FleetEditPayment: FC<Props> = props => {
  const [searchParams] = useSearchParams();
  const payPalControlObj = {
    payPalToken: searchParams.get('token'),
    paypalResult: searchParams.get('paypalResult'),
  }
  const [isProcessPayment, setIsProcessPayment] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [fleetCurrency, setFleetCurrency] = useState('');
  const { t } = useTranslation();
  const sessionObj = useSession();
  const paymentService = PaymentService.getInstance(sessionObj);

  useEffect(() => {
    (async () => {
      if (props.fleetId) {
        setIsProcessPayment(true);
        fetch(`/backend.php/fleet/getFleet/${props.fleetId}`, {
          method: 'POST'
        })
        .then(r => r.json())
        .then(result => {
          const fleet = result?.content?.fleet;
          if (fleet?.payment_source_currency_iso_code) {
            setFleetCurrency(fleet.payment_source_currency_iso_code);
          } else if (fleet?.currencyIsoCode) {
            setFleetCurrency(fleet.currencyIsoCode);
          } else if (fleet?.companyCurrencyIsoCode) {
            setFleetCurrency(fleet.companyCurrencyIsoCode);
          } else {
            setFleetCurrency(sessionObj.currency_code || sessionObj.currencyCode);
          }
          setIsProcessPayment(false);
        });
      }
    })();
  }, [props.fleetId]);

  useEffect(() => {
    if (payPalControlObj.payPalToken && payPalControlObj.paypalResult === 'success') {
      const request = {
        currency: fleetCurrency,
        paymentType: 'AUTO_TOP',
        token: payPalControlObj.payPalToken,
      };
      setIsProcessPayment(true);
      paymentService.authorizePayPal(request).then(
        (paymentResponse) => {
          setIsProcessPayment(false);
          if (paymentResponse) {
            processPaymentMethod(paymentResponse.paymentId.toString());
          }
        },
        () => {
          setIsProcessPayment(false);
          setError(t('server.error'));
        }
      );
    }

  }, [payPalControlObj]);

  if (!props.fleetId) {
    return null;
  }

  const processPayPal = () => {
    setIsProcessPayment(true);
    setError('');
    const request = {
      amount: 0,
      cancelUrl: `${window.location.href}?paypalResult=cancel`,
      currency: fleetCurrency,
      returnUrl: `${window.location.href}?paypalResult=success&fleetId=${props.fleetId}`,
    };

    paymentService.startPaypalExpressCheckout(request).then(
      (paymentResponse) => {
        if (paymentResponse) {
          if (typeof paymentResponse.redirect_url !== 'undefined') {
            window.location.href = paymentResponse.redirect_url;
          } else if (typeof paymentResponse.errorMessage !== 'undefined') {
            setError(paymentResponse.errorMessage);
          } else {
            window.location.href = sessionObj.paypalExpressCheckout + paymentResponse.token;
          }
        }
      },
      () => {
        setIsProcessPayment(false);
        setError(t('server.error'));
      }
    );
  };

  const authorizeCard = (
    cardNumber: number,
    expirationMonth: number,
    expirationYear: number,
    cvc: number,
    zip: string
  ) => {
    setIsProcessPayment(true);
    setError('');
    // check for feature flag
    if (!sessionObj?.isCC3DSecureEnabled) {
      paymentService.authorizeCreditCard(
        cardNumber,
        expirationMonth,
        expirationYear,
        cvc,
        zip,
        fleetCurrency
      ).then((response) => {
        processPaymentMethod(response.paymentId);
      });
      return;
    }

    paymentService.checkCardVersion(cardNumber, expirationMonth, expirationYear, cvc, zip, fleetCurrency)
      .then((response) => {
        if (response.status === TransactionStatus.ChallengeRequired
            && response.challenge?.response?.data
            && 'transStatus' in response.challenge.response.data) {
          AnalyticsService.getInstance().trackEvent(
            analyticEvents.card3dsChallenge,
            {
              'User Type': 'Driver',
              'Status': response.challenge.response.data.transStatus,
              'Server Transaction ID': response.serverTransactionId,
              'ACS Transaction ID': response.acsTransactionId
            }
          );
        }
        // test cards with statuses can be found at
        // https://developer.globalpay.com/resources/test-card-numbers#3dsecure-2
        if (response.status === TransactionStatus.AuthenticationSuccessful
            || response.status === TransactionStatus.AuthenticationAttemptedButNotSuccessful) {
          // not all cards support 3D secure check
          paymentService.authorizeCreditCard(
            cardNumber,
            expirationMonth,
            expirationYear,
            cvc,
            zip,
            fleetCurrency,
            response.serverTransactionId
          ).then((response) => {
            processPaymentMethod(response.paymentId);
          });
        } else if (response.status === TransactionStatus.ChallengeRequired
            && response.challenge?.response?.data
            && 'transStatus' in response.challenge.response.data
            && response.challenge.response.data.transStatus === 'Y') {
          // response.challenge.response.data contains the result of challenge flow
          // see IChallengeNotificationData in globalpayments-3ds
          paymentService.authorizeCreditCard(
            cardNumber,
            expirationMonth,
            expirationYear,
            cvc,
            zip,
            fleetCurrency,
            response.challenge?.response?.data?.threeDSServerTransID
          ).then((response) => {
            processPaymentMethod(response.paymentId);
          });
        } else {
          setIsProcessPayment(false);
          if ('errorMessage' in response && typeof response.errorMessage === 'string') {
            setError(response.errorMessage);
          } else if (response.status !== TransactionStatus.ChallengeRequired) {
            setError(t('server.authentication_failed'));
          } else if (response.challenge?.response?.data
              && 'transStatus' in response.challenge.response.data
              && response.challenge.response.data.transStatus === 'N') {
            setError(t('server.challenge_canceled'));
          } else {
            setError(t('server.credit_card_not_valid'));
          }
        }
      },
      (error) => {
        setIsProcessPayment(false);
        if ('errorMessage' in error && typeof error.errorMessage === 'string') {
          setError(error.errorMessage);
        } else {
          setError(t('server.credit_card_not_valid'));
        }
      }
    );
  };

  const processPaymentMethod = (paymentId: string) => {
    const formData = new FormData();
    formData.set('reference_id', paymentId);
    formData.set('status', '1');
    if (!props.fleetId) {
      setError(t('fleet_notifications.fleet_id_not_found'));
      return;
    }
    formData.set('fleet_id', props.fleetId);
    formData.set('api_version', '2');

    fetch(`/fleet/process_fleet_payment/${paymentId}`, {
      method: 'POST',
      body: formData,
    }).then((paymentResponse) => {
        setIsProcessPayment(false);
      return paymentResponse.json();
    }).then((responseJSON) => {
      if (!responseJSON.status) {
        setError(responseJSON);
      } else {
        props.onClose();
        // drop the query params, since that will trigger the PayPal flow again.
        window.location.href = `${window.location.origin}${window.location.pathname}`;
      }
    }).catch(() => {
      setError(t('server.error'));
    });
  }

  return (
    <FormContent>
      {error !== '' && <ErrorText>{error}</ErrorText>}
      {isProcessPayment && <StyledSpinner>
        <KitSpinner show={isProcessPayment} />
      </StyledSpinner>}
      <PaymentForm
        t={t}
        onAuthorizeCard={authorizeCard}
        onProcessPayPal={processPayPal}
        isLoading={false}
        userZipCode={''}
        onCancel={() => {props.onClose();}}
      />
    </FormContent>
  );
};

export default FleetEditPayment;


