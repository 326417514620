import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Column } from './styled/Metadata';
import { formatNumber } from './utils';
import { SessionService } from '@chargepoint/cp-toolkit';

interface MetadataProps {
  data: any;
}

export function Metadata(props: MetadataProps) {
  const { data } = props;
  const [locale, setLocale] = useState<string>('en-US');
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const session = await SessionService.getSessionData();
      setLocale(session.prefLangLocale);
    })();
  }, []);

  if (!data) {
    return null;
  }

  return (
    <>
      <Container>
        <Row>
          <Column>
            <p>
              {t('videoAd.length')}
            </p>
            {isNaN(parseFloat(data['Length']))
              ? <p>{data['Length']}</p>
              : <p>{formatNumber(data['Length'], locale)} {t('videoAd.labelSecs')}</p>
            }
          </Column>
          <Column>
            <p>
              {t('videoAd.fileSize')}
            </p>
            {isNaN(parseFloat(data['Filesize']))
              ? <p>{data['Filesize']}</p>
              : <p>{formatNumber(data['Filesize'], locale)} {t('videoAd.labelMB')}</p>
            }
          </Column>
          <Column>
            <p>
              {t('videoAd.aspectRatio')}
            </p>
            <p>{data['Aspect Ratio']}</p>
          </Column>
        </Row>
        <Row>
          <Column>
            <p>
              {t('videoAd.resolution')}
            </p>
            <p>{data['Resolution']}</p>
          </Column>
          <Column>
            <p>
              {t('videoAd.framerate')}
            </p>
            {isNaN(parseFloat(data['Frame Rate']))
              ? <p>{data['Frame Rate']}</p>
              : <p>{formatNumber(data['Frame Rate'], locale)} {t('videoAd.labelFps')}</p>
            }
          </Column>
          <Column>
            <p>
              {t('videoAd.container')}
            </p>
            <p>{data['Container']}</p>
          </Column>
        </Row>
      </Container>
    </>
  );
}
