import * as React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ThemeConstants, ThemeColors, KitSpinner } from '@chargepoint/cp-toolkit';
import { getProviders, getProviderDetail } from './FuelCardActions';

export interface FuelCardProvidersProps {}

const FuelCardProviderContainer = styled.div`
  display: flex;
`;
const ProviderListContainer = styled.div`
  max-width: 400px;
  min-width: 200px;
`;

const ProviderDetailsContainer = styled.div`
  flex: 1 1;
  padding: ${ThemeConstants.spacing.absolute.m}px;
`;

const ProviderListButton = styled.button<{ active?: boolean }>`
  display: block;
  font-size: ${ThemeConstants.fontSize.p}rem;
  padding: ${ThemeConstants.spacing.absolute.sm}px ${ThemeConstants.spacing.absolute.m}px;
  color: ${({ theme }) => theme.page.body.text};
  background-color: ${props =>
    props.active ? ThemeColors.blue_30 : ThemeColors.white};
  border: 1px solid rgba(0, 0, 0, 0.125);
  cursor: pointer;
  & :focus {
    z-index: 1;
  }
`;

const ListGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: ${ThemeConstants.spacing.absolute.m}px;
`;

const StyledPre = styled.pre`
  margin-top: 0;
`;

const SelectedHeader = styled.h3`
  color: ${({ theme }) => theme.page.body.text};
  font-size: ${ThemeConstants.fontSize.h3}rem;
`;

const CenteredDiv = styled.div`
  text-align: center;
`;

const FuelCardProvidersComponent: React.FC<FuelCardProvidersProps> = () => {
  const { t } = useTranslation();
  const [providers, setProviders] = useState(null);
  const [providerDetail, setProviderDetail] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [loading, setLoading] = useState(false);
  const [providerDetailError, setProviderDetailError] = useState('');

  useEffect(() => {
    setLoading(true);
    FuelCardProviders.getProviders().then(resp => {
      setLoading(false);
      if (!resp.response.data) {
        setProviders([]);
      } else {
        if (resp.response.data.length > 0) {
          setSelectedProvider(resp.response.data[0]);
        }
        setProviders(resp.response.data);
      }
    });
  }, []);

  useEffect(() => {
    if (selectedProvider) {
      loadProviderDetail(selectedProvider.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProvider]);

  function loadProviderDetail(code: string) {
    setLoading(true);
    FuelCardProviders.getProviderDetail(code).then(resp => {
      setLoading(false);
      if (resp.status === false) {
        setProviderDetailError(t('fuelCards.errors.providerDetailGeneral') as string);
      } else {
        setProviderDetail(JSON.stringify(resp.response.data, null, 2));
      }
    });
  }

  function listProviders() {
    const providersArr = [];
    providers.forEach(provider => {
      providersArr.push(
        <ProviderListButton
          key={provider.code}
          type='button'
          active={provider.code === selectedProvider.code}
          onClick={() => {
            setSelectedProvider(provider);
          }}
        >
          {provider.name}
        </ProviderListButton>
      );
    });

    return providersArr;
  }

  function getProviderList() {
    if (providers === null || providers.length === 0) {
      return null;
    }
    return <ListGroup>{listProviders()}</ListGroup>;
  }

  function getProviderDetailContainer() {
    const providerDetailContent = providerDetailError
      ? providerDetailError
      : getSelectedProviderDetailContent();
    return <ProviderDetailsContainer>{providerDetailContent}</ProviderDetailsContainer>;
  }
  function getSelectedProviderDetailContent() {
    return (
      <>
        {selectedProvider && (
          <>
            <SelectedHeader>
              {t('fuelCards.fuelCardProviderHeading', { providerName: selectedProvider.name })}
            </SelectedHeader>
            <StyledPre>{providerDetail}</StyledPre>
          </>
        )}
      </>
    );
  }
  return (
    <FuelCardProviderContainer>
      {loading && <KitSpinner withOverlay />}
      {providers !== null && providers.length === 0 && (
        <CenteredDiv>{t('fuelCards.errors.noAvailableProviders')}</CenteredDiv>
      )}
      <ProviderListContainer>{getProviderList()}</ProviderListContainer>
      {getProviderDetailContainer()}
    </FuelCardProviderContainer>
  );
};
const FuelCardProviders = Object.assign(FuelCardProvidersComponent, {
  getProviders,
  getProviderDetail
});

export default FuelCardProviders;
