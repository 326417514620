import React from 'react';
import FuelCardProviders from '../fuel-cards/FuelCardProviders';

const Home: React.FC<any> = props => {
  return (
    <>
      <div>
        <FuelCardProviders />
      </div>
    </>
  );
};

export default Home;
