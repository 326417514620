import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import { KitCard, KitGrid, ThemeConstants } from '@chargepoint/cp-toolkit';
import { SessionContext } from '@chargepoint/common/hooks/SessionContext';
import AnonymousBlurb from '../../components/AnonymousBlurb';
import styled from 'styled-components';
import useDeveloperRoutes from '../../constants/routes';
import DeveloperReceiptWrapper from './DeveloperReceiptWrapper';
import PageHeading from './PageHeading';
import DeveloperFlexBillingWrapper from '../../flex-billing/Developer/DeveloperFlexBillingWrapper';

const { breakpoints, spacing } = ThemeConstants;

const GridContainer = styled(KitGrid)`
  margin: ${spacing.absolute.l}px;
  && {
    > div {
      margin-bottom: ${spacing.absolute.s}px;
    }
  }

  @media all and (max-width: ${breakpoints.md}px) {
    grid-gap: ${spacing.absolute.m}px;
    justify-content: space-around;
  }
`;

const Container = styled.div`
  margin: auto;
  max-width: 800px;
  text-align: center;
`;

const DeveloperDashboard: FC = () => {
  const { session } = useContext(SessionContext);
  const navigate = useNavigate();
  const { developerRoutes } = useDeveloperRoutes();

  if (!session || !session.isLoggedIn) {
    return (
      <Container>
        <AnonymousBlurb />
      </Container>
    );
  }

  const getBasicComponents = () => {
    return developerRoutes.map((route) => {
      return (
        <KitCard
          key={route.path}
          onClick={() => {
            console.log('clicked', route.path);
            navigate(route.path);
          }}
        >
          <KitCard.Header>{route.name}</KitCard.Header>
          <KitCard.Body>{route.description}</KitCard.Body>
        </KitCard>
      );
    });
  };
  const getReceiptComponent = () => {
    return (
      <KitCard>
        <KitCard.Header>Receipts</KitCard.Header>
        <KitCard.Body>
          <DeveloperReceiptWrapper />
        </KitCard.Body>
      </KitCard>
    );
  };

  const getFlexBillingComponent = () => {
    return (
      <KitCard>
        <KitCard.Header>Flex Billing</KitCard.Header>
        <KitCard.Body>
          <DeveloperFlexBillingWrapper />
        </KitCard.Body>
      </KitCard>
    );
  }
  return (
    <Page>
      <PageHeading>
        <h1>Developer Dashboard of NOS React Components</h1>
      </PageHeading>

      <GridContainer>
        {getBasicComponents()} {getReceiptComponent()} {getFlexBillingComponent()}
      </GridContainer>
    </Page>
  );
};

export default DeveloperDashboard;
