import Pagelet from '@chargepoint/common/components/Pagelet';
import React from 'react';
import PaymentMethods from '../../organizations/payment-methods/PaymentMethods';

function PaymentMethodsPagelet() {
  return (
    <Pagelet>
      <PaymentMethods />
    </Pagelet>
  );
}

export default PaymentMethodsPagelet;
