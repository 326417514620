/* eslint-disable class-methods-use-this */

import { sessionObj } from '../Intl/i18n';
class ReCaptchaService {
  private static instance: ReCaptchaService;
  private recaptchaApiKey: string | null = null;

  constructor(recaptchaApiKey?: string) {
    if (sessionObj?.reCaptchaApiKey) {
      this.recaptchaApiKey = sessionObj.reCaptchaApiKey;
    } else {
      this.recaptchaApiKey = recaptchaApiKey;
    }
    this.loadReCaptcha();
  }

  loadReCaptcha(): void {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/enterprise.js?render=${this.recaptchaApiKey}`;
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;

    if (!document.body.contains(script)) {
      document.head.appendChild(script);
    }
  }

  getToken(userAction: string): PromiseLike<string | null> {
    if (!window.grecaptcha) {
      return new Promise(() => {
        return null;
      });
    }

    return window.grecaptcha.enterprise.execute(this.recaptchaApiKey, {
      action: userAction,
    });
  }

  public static getInstance(reCaptchaApiKey?: string): ReCaptchaService {
    if (!ReCaptchaService.instance) {
      ReCaptchaService.instance = new ReCaptchaService(reCaptchaApiKey);
    }
    return ReCaptchaService.instance;
  }
}

export default ReCaptchaService;
