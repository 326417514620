import React from 'react';
import styled from 'styled-components';
import { KitAppLayout, KitPage } from '@chargepoint/cp-toolkit';
import { useTranslation } from "react-i18next";
import Footer from './Footer';
import GlobalCss from './GlobalCss';

const StyledBody = styled(KitAppLayout.Body)`
  display: flex;
  flex-direction: column;
`;

export interface Props {
  className?: string;
  children?: any;
}

const Page: React.FC<Props> = (props) => {
  const { i18n } = useTranslation();

  return (
    <>
      <GlobalCss />
      <KitPage title={''} locale={i18n.language}>
        <KitAppLayout.Header />
        <StyledBody>
          {props.children}
        </StyledBody>
        <KitAppLayout.Footer>
          <Footer />
        </KitAppLayout.Footer>
      </KitPage>
    </>
  );
}

export default Page;
