import { CountryCurrency, FlexCountryConfig } from '../Main/utils';
import { FlxBillDetailsBackendObj } from '../Main/FlexbillingDetails';
import { InviteUserDetailsBackendObj } from '../Main/InviteUserDetails';

export const getFlexBillingCountryConfig = async () => {
  return fetch(
    '/backend.php/organizations/getFlexBillingConfig'
  )
    .then(res => res.json())
    .then(json => {
      if (json.content) {
        for (let key in json.content) {
          if (json.content.hasOwnProperty(key)) {
            json.content[key] = new FlexCountryConfig(json.content[key]);
          }
        }
        return json.content;
      }

      return {};
    });
};

export const getFlexBillingDetails = (id: string, groupId: string, orgId: string): Promise<FlxBillDetailsBackendObj> => {
  const searchParams = new URLSearchParams();
  searchParams.set('fbId', id);
  searchParams.set('groupId', groupId);
  searchParams.set('orgId', orgId);
  return fetch(
    '/organization/getFlexBillingDetails',
    {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        // 'Content-Type': 'application/json'
        'Content-Type': 'application/x-www-form-urlencoded  charset=UTF-8'
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: searchParams // body data type must match "Content-Type" header
    }
  )
    .then(res => res.json())
    .then(res => res.content[0]);
};

export const getRemitOptions = (orgId: string) => {
  const searchParams = new URLSearchParams();
  searchParams.set('organizationId', orgId);
  return fetch(
    '/organization/getOrgRemitList',
    {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        // 'Content-Type': 'application/json'
        'Content-Type': 'application/x-www-form-urlencoded  charset=UTF-8'
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: searchParams // body data type must match "Content-Type" header
    }
  )
    .then(res => res.json())
    .then(res => {
      if (res.content) {
        return res.content;
      } else {
        return [];
      }
    });
};

export const getUserInfoByToken = (token: string): Promise<InviteUserDetailsBackendObj> => {
  const searchParams = new URLSearchParams();
  searchParams.set('invite_token', token);
  return fetch(
    '/index.php/welcome/getUserInfoByToken',
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded  charset=UTF-8'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: searchParams
    }
  )
    .then(res => res.json())
    .then(res => {
      if (res.content) {
        return res.content;
      } else {
        return [];
      }
    });
};


export const getDefaultCurrencyOfCountry = (countryId: number): Promise<CountryCurrency> => {
  const searchParams = new URLSearchParams();
  searchParams.set('countryId', countryId.toString());
  return fetch(
    '/index.php/users/getDefaultCurrencyOfCountry',
    {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded  charset=UTF-8'
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: searchParams
    }
  )
    .then(res => res.json())
    .then(res => {
      if (res.content) {
        const result: CountryCurrency = {
          code: res.content,
          label: res.label
        };
        return result;
      } else {
        return null;
      }
    });
};
