import React, { FC, useState, useEffect } from 'react';
import styles from './phoneNumberPicker.module.scss';
import { useTranslation } from 'react-i18next';
import { AccountService } from '@chargepoint/common/services/AccountService';

/**
 * PhoneNumberPicker functional component is used to show a country code dropdown next to
 * an input box. The combined input is stored as and a CountryPhone object.
 * @param props
 */
export const PhoneNumberPicker: FC<{
  name: string;
  phone: string;
  countryCode: string;
  width: string;
  onUpdate: Function;
}> = props => {
  const [countryPhone, setCountryPhone] = useState({ countryCode: props.countryCode, phone: props.phone });
  const [countries, setCountries] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const { t } = useTranslation();

  const init = () => {
    (async () => {
      const countriesCopy = await AccountService.getAllCountries();
      setCountries(countriesCopy);

      const localCountryPhone = { ...countryPhone };
      if (!countriesCopy.length) {
        return;
      }
      const dataOptions = countriesCopy.map((country, index) => {
        let dataLabel = `${country.name} (+${country.callingCode})`;
        if (Number(localCountryPhone.countryCode) === country.callingCode
          || (index === 0 && (localCountryPhone.countryCode === null || localCountryPhone.countryCode === '' || typeof localCountryPhone.countryCode === 'undefined'))) {
          dataLabel = `+${country.callingCode}`;
          localCountryPhone.countryCode = country.callingCode;
          setCountryPhone({ ...countryPhone, countryCode: country.callingCode });
          setSelectedCountryCode(country.callingCode.toString())
        }
        return (
          <option
            key={country.id}
            data-label={dataLabel}
            value={country.callingCode.toString()}
          >
            {dataLabel}
          </option>
        );
      });

      setOptions(dataOptions);
    })();
  }

  /**
   * initializer function to get all the countries
   */
  useEffect(init, []);

  useEffect(() => {
    setCountryPhone({ countryCode: props.countryCode, phone: props.phone });
  }, [props.phone, props.countryCode]);

  /**
   * function updates the selected option label to show only the number and not the country name.
   */
  const updateOptionLabels = () => {
    if (props.onUpdate) {
      // the parent with the new values
      props.onUpdate(countryPhone);
    }
    const dataOptions = countries.map((country) => {
      let dataLabel = `${country.name} (+${country.callingCode})`;
      let selected = false;
      if (Number(countryPhone.countryCode) === country.callingCode) {
        dataLabel = `+${country.callingCode}`;
        selected = true;
      }
      return (
        <option
          key={country.id}
          data-label={dataLabel}
          value={country.callingCode.toString()}
          selected={selected}
        >
          {dataLabel}
        </option>
      );
    });

    setOptions(dataOptions);
  };

  /**
   * function updates the labels, since there need to be two different displays when the dropdown is open and when it is closed.
   */
  useEffect(updateOptionLabels, [countryPhone]);

  const updateCountryCode = ev => {
    setCountryPhone({ ...countryPhone, countryCode: ev.target.value });
    setSelectedCountryCode(ev.target.value);
  };

  const updatePhone = ev => {
    setCountryPhone({ ...countryPhone, phone: ev.target.value });
  };

  const showDropdown = () => {
    const dataOptions = countries.map(country => {
      const dataLabel = `${country.name} (+${country.callingCode})`;
      return (
        <option
          key={country.id}
          data-label={dataLabel}
          value={country.callingCode.toString()}
        >
          {dataLabel}
        </option>
      );
    });

    setOptions(dataOptions);
  };

  return (
    <div className={styles.phoneNumberPicker} style={{ width: props.width }}>
      <label className={styles.showOnlyScreenReader} htmlFor={props.name + '_country_code'}>
        {t('PhoneNumberPicker.label_country_code') as string}
      </label>
      <select
        name={props.name + '_country_code'}
        id={props.name + '_country_code'}
        onChange={updateCountryCode}
        onMouseDown={showDropdown}
        value = {selectedCountryCode}
      >
        {options}
      </select>
      <input type='text' name={props.name} id={props.name} value={countryPhone.phone} onChange={updatePhone}/>
    </div>
  );
};
