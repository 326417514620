import * as React from 'react';
import styled from 'styled-components';
import {
  ThemeConstants,
  ThemeColors,
  KitTextField,
  KitButton,
  KitSpinner
} from '@chargepoint/cp-toolkit';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { createPaymentMethod, editPaymentMethod } from './PaymentMethodActions';
import ServerMsgSnackBar, { ServerMsgType } from '../../components/ServerMsgSnackBar';

const KitButtonMarginRight = styled(KitButton)`
  && {
    margin-right: ${ThemeConstants.spacing.absolute.sm}px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: ${ThemeConstants.spacing.absolute.xl}px;
`;

const StyledFormRow = styled.div`
  width: 100%;
  padding: 0 0 ${ThemeConstants.spacing.absolute.l}px 0;

  > label {
    font-size: ${ThemeConstants.fontSize.text_14}rem;
    color: ${ThemeColors.gray_50};
    line-height: 1;
    margin-left: ${ThemeConstants.spacing.absolute.s}px;

    & span {
      display: block;
      margin: ${ThemeConstants.spacing.absolute.xs}px ${ThemeConstants.spacing.absolute.s}px 0;
      color: ${({ theme }) => theme.page.body.text};
      font-size: 1rem;
    }
  }
`;

const StyledSnackBar = styled(ServerMsgSnackBar)`
  margin-bottom: ${ThemeConstants.spacing.absolute.m}px;
`;

export interface AddEditPaymentMethodFormProps {
  paymentProviders: Array<{
    id: number;
    name: string;
    type: string;
    localised_payment_provider_type: string;
  }>;
  orgInfo: {
    name: string;
    currency: { currencyIsoCode: string; currencySymbol: string; name: string };
    id: number;
  };
  editMode: boolean;
  updateCallback: Function;
  paymentMethodToEdit?: any;
  cancelCallback: () => void;
}

const AddEditPaymentMethodForm = (props: AddEditPaymentMethodFormProps) => {
  const { orgInfo, paymentProviders, editMode, updateCallback, paymentMethodToEdit } = props;
  const { t } = useTranslation();
  const [showSpinner, setShowSpinner] = useState(false);
  const [serverMessageObj, setServerMessageObj] = useState({
    visible: false,
    message: '',
    msgType: ServerMsgType.Error
  });

  let selectedPaymentProvider = '';
  if (paymentMethodToEdit && paymentProviders && paymentProviders.length > 0) {
    const selectedPaymentProviderObj = paymentProviders.find((paymentProvider) => {
      return paymentProvider.id === paymentMethodToEdit.payment_provider.provider_id;
    });
    const selectedPaymentProviderObjMapped = {
      provider_id: selectedPaymentProviderObj.id,
      provider_type: selectedPaymentProviderObj.type,
      provider_name: selectedPaymentProviderObj.name
    };
    selectedPaymentProvider = JSON.stringify(selectedPaymentProviderObjMapped);
  }

  const { register, errors, trigger, getValues } = useForm({
    defaultValues: {
      paymentType: selectedPaymentProvider
    },
    mode: 'onBlur'
  });

  const getPaymentTypeOptions = () => {
    return paymentProviders.map(({ id, name, type, localised_payment_provider_type }) => {
      const optionValue = {
        provider_id: id,
        provider_type: type,
        provider_name: name
      };
      return (
        <option key={id} value={JSON.stringify(optionValue)}>{localised_payment_provider_type}</option>
      );
    });
  };

  const updateServerMsg = (msg: string = '', msgType: ServerMsgType = ServerMsgType.Error) => {
    const srvMsgObj = { ...serverMessageObj };
    srvMsgObj.message = msg;
    srvMsgObj.visible = (msg)? true: false;
    srvMsgObj.msgType = msgType;
    setServerMessageObj(srvMsgObj);
  };

  const submitPaymentMethod = async e => {
    const result = await trigger();
    let body = null;
    let values = null;

    if (result) {
      values = getValues();
      setShowSpinner(true);
      if (editMode) {
        body = JSON.parse(values.paymentType);
        editPaymentMethod(orgInfo.id.toString(), paymentMethodToEdit.id, body).then(response => {
          setShowSpinner(false);
          if (response.error) {
            if (response.error.message) {
              updateServerMsg(response.error.message);
            } else {
              updateServerMsg(t('fuelCards.errors.paymentMethodEdit') as string);
            }
          } else {
            updateCallback();
          }
        });
      } else {
        body = {
          legal_name: orgInfo.name,
          currency_iso_code: orgInfo.currency.currencyIsoCode,
          payment_provider: JSON.parse(values.paymentType)
        };
        createPaymentMethod(orgInfo.id.toString(), body).then(response => {
          setShowSpinner(false);
          if (response.error) {
            if (response.error.message) {
              updateServerMsg(response.error.message);
            } else {
              updateServerMsg(t('fuelCards.errors.paymentMethodCreate') as string);
            }
          } else {
            updateCallback();
          }
        });
      }
    }
  };

  const clearServerMsg = () => {
    updateServerMsg();
  };

  return (
    <form>
      <StyledSnackBar
        {...serverMessageObj}
        clearMsg={() => {
          clearServerMsg();
        }}
      />
      <StyledFormRow>
        <label>
          {t('legalEntityName')}
          {props.orgInfo && <span>{props.orgInfo.name}</span> }
        </label>
      </StyledFormRow>
      <StyledFormRow>
        <label>
          {t('currency')}
          {props.orgInfo && (
            <span dangerouslySetInnerHTML={{ __html: props.orgInfo.currency.name }} />
          )}
        </label>
      </StyledFormRow>
      <StyledFormRow>
        <label htmlFor='paymentType'>{t('paymentType')}</label>
        <KitTextField
          ref={register({ required: { value: true, message: 'Required' } })}
          name='paymentType'
          select
          required
          error={errors.paymentType ? (t('generalErrors.required') as string) : ''}
        >
          <option value=''>{t('select')}</option>
          {getPaymentTypeOptions()}
        </KitTextField>
      </StyledFormRow>
      <ButtonContainer>
        <KitButtonMarginRight
          onClick={e => {
            submitPaymentMethod(e);
            e.preventDefault();
          }}
        >
          {t('ButtonSet.apply')}
        </KitButtonMarginRight>
        <KitButton variant='secondary' onClick={props.cancelCallback}>
          {t('ButtonSet.cancel')}
        </KitButton>
      </ButtonContainer>
      {showSpinner && <KitSpinner withOverlay />}
    </form>
  );
};

export default AddEditPaymentMethodForm;
