import React, {useContext} from 'react';
import {RemitAccountType} from '../../Main/utils';
import {useTranslation} from 'react-i18next';
import {RemitanceProps} from './remitance';
import {SessionContext} from '@chargepoint/common/hooks/SessionContext';
import styles from '../../Main/main.module.scss';
import {FlexBillingOption} from "../Intro/intro";

const Remitance = (props: RemitanceProps) => {
  const { t } = useTranslation();
  const { session } = useContext(SessionContext);

  const toggleCheckACH = (remitAccountType) => {
    if (props.isNoc !== true) {
      const bankInfo = { ...props.bankInfo, remitAccountType };
      if (remitAccountType === RemitAccountType.ACH) {
        bankInfo.remittanceAddress = '';
      }
      props.onChange('ACHInfo', bankInfo);
    }
  };

  const updateRemitanceAddress = (remittanceAddress) => {
    const bankInfo = { ...props.bankInfo, remittanceAddress };
    props.onChange('ACHInfo', bankInfo);
  };

  const check = (
    <>
      <p>
        <label>{t('remittance_title_remitBy')}</label>
        <label className={styles.largeLabel}>{t('remitance.tile_check')}</label>
        {t('remitance.paragraph_ACH_description')}
        <br/>
        {props.countryConfig && props.isNoc !== true && !session.isFlexBillingHoldMyFeesServiceEnabled && (
          <label>
            <a href='/' onClick={(el) => {
              toggleCheckACH(RemitAccountType.ACH);
              el.preventDefault();
              el.stopPropagation();
            }}>{t('remitance.paragraph_ACH_switch')}</a>
          </label>
        )}
        <br/>
        {t('remitance.paragraph_check_address')}
        <br/>
        <input type='text' value={props.bankInfo.remittanceAddress} onChange={(evt) => {
          updateRemitanceAddress(evt.target.value);
        }}/>
      </p>
    </>
  );

  const ACH = (
    <>
      {((props.countryConfig && props.countryConfig.has_accepts_check
          && !session.isFlexBillingHoldMyFeesServiceEnabled) || session.isFlexBillingMailCheckEnabled) && (<p>
        <label>{t('remittance_title_remitBy')}</label>
        <label className={styles.largeLabel}>{t('remitance.title_ACH')}</label>
        {t('remitance.paragraph_ACH_preferrence')}
        <br/>
        {props.isNoc !== true && (
          <label>
            <a href='/' onClick={(el) => {
              toggleCheckACH(RemitAccountType.Checking);
              el.preventDefault();
              el.stopPropagation();
            }}>{t('remitance.paragraph_switch_to_check')}</a>
          </label>
        )}
      </p>)}
      <p>{props.bankInfo.achAggreement}</p>
    </>
  );

  return (
    <div className={props.hide ? styles.hide : styles.remitance}>
      <div className={styles.row}>
        <div className={styles.formGroup}>
          {
            ((props.bankInfo.remitAccountType === RemitAccountType.Checking
                    && !session.isFlexBillingHoldMyFeesServiceEnabled)
            || props.activateFlexBilling.paymentOption === FlexBillingOption.MAIL_A_CHECK)
              ? check
              : ACH
          }
        </div>
      </div>
    </div>
  );
};

export default Remitance;
