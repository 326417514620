import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  KitDropdownActionList,
  KitSpinner,
  ThemeConstants,
  ThemeColors
} from '@chargepoint/cp-toolkit';
import { useTranslation } from 'react-i18next';
import { DropDownItemEventObject } from '@chargepoint/cp-toolkit/src/components/Dropdowns/KitDropDownItem';
import { SessionContext } from '@chargepoint/common/hooks/SessionContext';

interface Region {
  region_code: string;
  region_name: string;
}

interface RegionsResponse {
  regions: Array<Region>;
  default: string;
}

const RegionSwitcherContainer = styled.div`
  padding-left: ${ThemeConstants.spacing.m}rem;
  border-left: 5px solid ${ThemeColors.blue_gray_50} !important;
  margin-right: 0 !important;
`;

export interface Props {
  className?: string;
}

const RegionSwitcher: React.FC<Props> = () => {
  const { t } = useTranslation();
  const { session } = useContext(SessionContext);
  const [regions, setRegions] = useState<Array<Region>>(null);
  const [defaultRegion, setDefaultRegion] = useState<Region>(null);
  const [regionReload, setRegionReload] = useState(false);

  useEffect(reloadRegions, [session.prefLangLocale]);

  function reloadRegions() {
    setDefaultRegion(null);
    fetch('/index.php/users/get_user_allowed_regions')
      .then(res => {
        return res.json();
      })
      .then((regionData: RegionsResponse) => {
        if (regionData) {
          if (regionData.regions) {
            setRegions(regionData.regions);
          }
          if (regionData.default) {
            setDefaultRegion(
              regionData.regions.find(region => {
                return region.region_code === regionData.default;
              })
            );
          }
        }
      });
  }

  function changeRegion(data: DropDownItemEventObject) {
    setRegionReload(true);
    // force to reload sessionObj on region change
    window.sessionStorage.removeItem('sessionObj');
    fetch(`/index.php/users/login_to_other_entity/${data.value}`)
      .then(res => {
        return res.json();
      })
      .then(regionData => {
        if (regionData && regionData.url) {
          window.location.href = regionData.url + regionData.redirect_url;
        } else if (regionData && regionData.error) {
          console.log(regionData.error);
        }
        setRegionReload(false);
      });
  }

  if (!defaultRegion) {
    return null;
  }

  return (
    <RegionSwitcherContainer>
      {regionReload ? (
        <KitSpinner size='s' />
      ) : (
        <KitDropdownActionList
          label={`${t('regionSwitcher.region')}`}
          toggleLabel={defaultRegion.region_name}
          onSelect={changeRegion}
          items={regions.map((region: Region) => ({
            value: region.region_code,
            label: region.region_name
          }))}
        />
      )}
    </RegionSwitcherContainer>
  );
};

export default RegionSwitcher;
