import React, {useState, useEffect, useContext} from 'react';
import { BankInfoProps } from './bankInfo';
import styles from '../../Main/main.module.scss';
import { flexbillingCacheFactory } from '../../Actions/flexbilling-cache';
import {
  StatesInput,
  CountriesInput
} from '../../utils/statesCountries';
import { useTranslation } from 'react-i18next';
import { AccountType } from '../../Main/utils';
import { SessionContext } from '@chargepoint/common/hooks/SessionContext';

interface TextInputProps {
  elName: string;
  label: string;
  value: any;
  updateObj: any;
  propName: string;
}

const BankInfo = (props: BankInfoProps) => {
  const { t } = useTranslation();
  const [states, setStates] = useState<Array<{ id: number; name: string; originalName: string; }>>([]);
  const [countries, setCountries] = useState<Array<{ id: number; name: string, originalName: string; }>>([]);
  const cacheSubscription = flexbillingCacheFactory.subscribe();
  const selectMe = { id: -1, name: t('select'), originalName: null, code: null };
  const { session } = useContext(SessionContext);

  const loadCountries = () => {
    if (countries.length === 0 || props.reloadCountries) {
      (async () => {
        const countriesCopy = [...await cacheSubscription.getSupportedCountries()];
        countriesCopy.unshift(selectMe);
        setCountries(countriesCopy);
      })();
    }
  }

  const loadStates = () => {
    (async () => {
      let statesCopy;
      if (props.bankInfo.bankAddress.countryId === -1 || typeof props.bankInfo.bankAddress.countryId === 'undefined'
        || props.bankInfo.bankAddress.countryId.toString() === ''
      ) {
        if (countries.length === 0) {
          return; // there are no countries
        }
        statesCopy = [selectMe];
      } else {
        statesCopy = [...await cacheSubscription.getStates(props.bankInfo.bankAddress.countryId.toString())];
        statesCopy.unshift(selectMe);
      }
      setStates(statesCopy);
    })();
  }

  useEffect(loadCountries, []);

  useEffect(loadStates, [props.bankInfo.bankAddress.countryId]);

  useEffect(loadCountries, [props.reloadCountries])

  const updateFormProp = async (obj: any, key: string, value: any) => {
    obj[key] = value;
    props.onChange('ACHInfo', props.bankInfo);
  };

  const getTextFormEl = (textInputProps: TextInputProps) => {
    return (
      <div className={styles.formGroup}>
        <label htmlFor={textInputProps.elName} dangerouslySetInnerHTML={{ __html: textInputProps.label }}>
        </label>
        <input
          type='text'
          name={textInputProps.elName}
          id={textInputProps.elName}
          value={textInputProps.value || ''}
          onChange={evt => {
            updateFormProp(
              textInputProps.updateObj,
              textInputProps.propName,
              evt.target.value
            );
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div className={props.hide ? styles.hide : styles.row}>
        {
          getTextFormEl({
            elName: 'bankName',
            label: t('bankInfo.label_bankName'),
            updateObj: props.bankInfo,
            propName: 'bankName',
            value: props.bankInfo.bankName
          })
        }
      </div>
      <div className={props.hide ? styles.hide : styles.row}>
        {
          getTextFormEl({
            elName: 'bankAddressLine1',
            label: t('bankInfo.label_address1'),
            updateObj: props.bankInfo.bankAddress,
            propName: 'line1',
            value: props.bankInfo.bankAddress.line1
          })
        }
        {
          getTextFormEl({
            elName: 'bankAddressLine2',
            label: t('bankInfo.label_address2'),
            updateObj: props.bankInfo.bankAddress,
            propName: 'line2',
            value: props.bankInfo.bankAddress.line2
          })
        }
      </div>
      <div className={props.hide ? styles.hide : styles.row}>
        <div className={styles.formGroup}>
          <CountriesInput
            name='bankCountry'
            countries={countries}
            labelValue={t('bankInfo.label_country')}
            value={props.bankInfo.bankAddress.countryId}
            onChange={evt => {
              updateFormProp(
                props.bankInfo.bankAddress,
                'countryOriginalName',
                evt.target.selectedOptions[0].dataset.countryoriginalname
              );
              updateFormProp(
                props.bankInfo.bankAddress,
                'countryId',
                evt.target.value
              );
              props.onCountryChange(evt.target.value);
            }}
          />
        </div>
        <div className={styles.formGroup}>
          <StatesInput
            name='bankState'
            states={states}
            labelValue={t('bankInfo.label_state')}
            value={props.bankInfo.bankAddress.stateProvinceId}
            onChange={evt => {
              updateFormProp(
                props.bankInfo.bankAddress,
                'statePovinceDbName',
                evt.target.selectedOptions[0].dataset.stateoriginalname
              );
              updateFormProp(
                props.bankInfo.bankAddress,
                'stateProvinceId',
                evt.target.value
              );
            }}
          />
        </div>
      </div>
      <div className={props.hide ? styles.hide : styles.row}>
        {
          getTextFormEl({
            elName: 'bankCity',
            label: t('bankInfo.label_city'),
            updateObj: props.bankInfo.bankAddress,
            propName: 'city',
            value: props.bankInfo.bankAddress.city
          })
        }
        {
          getTextFormEl({
            elName: 'bankPostalCode',
            label: t('bankInfo.label_postalcode'),
            updateObj: props.bankInfo.bankAddress,
            propName: 'postalCode',
            value: props.bankInfo.bankAddress.postalCode
          })
        }
      </div>
      <div className={props.hide ? styles.hide : styles.row}>
        {props.countryConfig && props.countryConfig.has_bank_routing_number && (
          getTextFormEl({
            elName: 'bankRouting',
            label: props.countryConfig.bank_routing_label,
            updateObj: props.bankInfo,
            propName: 'bankRoutingId',
            value: props.bankInfo.bankRoutingId
          })
        )}
        {props.countryConfig && props.countryConfig.bank_account_label && (
          getTextFormEl({
            elName: 'accountNumber',
            label: props.countryConfig.bank_account_label,
            updateObj: props.bankInfo,
            propName: 'accountNumber',
            value: props.bankInfo.accountNumber
          })
        )}
      </div>
      {props.countryConfig && props.countryConfig.has_swift && (
        <div className={props.hide ? styles.hide : styles.row}>
          {getTextFormEl({
            elName: 'swift',
            label: t('bankInfo.label_swift'),
            updateObj: props.bankInfo,
            propName: 'bankSwiftCode',
            value: props.bankInfo.bankSwiftCode
          })}
        </div>
      )}
      {props.countryConfig && props.countryConfig.has_bank_transit_id && (
        <div className={props.hide ? styles.hide : styles.row}>
          {getTextFormEl({
            elName: 'bankTransitId',
            label: props.countryConfig.bank_transit_id_label,
            updateObj: props.bankInfo,
            propName: 'bankTransitId',
            value: props.bankInfo.bankTransitId
          })}
        </div>
      )}
      {props.countryConfig && props.countryConfig.has_accepts_check && (
        <div className={props.hide ? styles.hide : styles.row}>
          <div className={styles.formGroup}>
            <label>{t('bankInfo.label_accountType')}</label>
          </div>
          <div className={[styles.row, styles.flex].join(' ')}>
            <label className={styles.radioLabel}>
              <input
                type='radio'
                name='accountType'
                value={AccountType.Checking}
                checked={props.bankInfo.accountType === AccountType.Checking}
                onChange={evt => {
                  updateFormProp(
                    props.bankInfo,
                    'accountType',
                    Number(evt.target.value)
                  );
                }}
              />
              {session.instance === 'ca'
                ? t('bankInfo.label_checking_ca')
                : t('bankInfo.label_checking')}
            </label>
            <label className={styles.radioLabel}>
              <input
                type='radio'
                name='accountType'
                value={AccountType.Savings}
                checked={props.bankInfo.accountType === AccountType.Savings}
                onChange={evt => {
                  updateFormProp(
                    props.bankInfo,
                    'accountType',
                    Number(evt.target.value)
                  );
                }}
              />
              {t('bankInfo.label_savings')}
            </label>
          </div>
        </div>
      )}
    </>
  );
};

export default BankInfo;
