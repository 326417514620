import React from 'react';
import styled from 'styled-components';
import PageletCss from './PageletCss';

const PageletWrapper = styled.div`
  min-height: 100%;
  position: relative;
`;

export interface Props {
  className?: string;
  children?: any;
}

const Pagelet: React.FC<Props> = props => {
  return (
    <>
      <PageletCss />
      <PageletWrapper id='pagelet-container'>{props.children}</PageletWrapper>
    </>
  );
};

export default Pagelet;
