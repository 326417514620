import React, { Component } from 'react';
import VideoLink from '../VideoLink';
import PDFLink from '../PDFLink';
import i18n, { sessionObj } from '../../../Intl/i18n';
import { HelpGroupContentProps } from '../HelpGroup/helpgroup';
import styles from './style.module.scss';
import { getBaseLangName } from '@chargepoint/common/util/i18n';
import { SessionContext } from '@chargepoint/common/hooks/SessionContext';

interface HelpLineProps extends HelpGroupContentProps {
  row: number;
}

class HelpLine extends Component<HelpLineProps> {
  context: React.ContextType<typeof SessionContext>;
  state = {
    url: undefined,
    show: true
  }

  constructor(props: HelpLineProps) {
    super(props);
    this.findBestFile = this.findBestFile.bind(this);
    this.showEnglishOnly = this.showEnglishOnly.bind(this);
}

  componentDidMount() {
    const { session } = this.context!;
    const region = sessionObj?.instance || session.instance;

    const file = this.props.pdf
      ? this.findBestFile(this.props.pdf.id, i18n.language)
      : undefined;
    this.setState({
      url: file ?  '/UI/s3docs/docs/help/' + file : undefined,
      show: !this.props.region || this.props.region === region
    });
  }

  // Show file matching the requested lang if possible otherwise revert to showing the English file
  findBestFile(file, lang) {
    let key = file;
    if (
      lang !== 'en-US' &&
      this.props.pdf.versions &&
      this.props.pdf.versions.indexOf(lang) > -1
    ) {
      key += '_' + lang;
    }
    key += '.pdf';

    return key;
  }

  showEnglishOnly() {
    if (getBaseLangName(i18n.language) === 'en') {
      return false;
    } else {
      return (
        !this.props.pdf.versions ||
        this.props.pdf.versions.indexOf(i18n.language) === -1
      );
    }
  }

  render() {
    const cls = this.props.row % 2 ? 'col' : 'colOdd';

    if (!this.state.show) {
      return undefined;
    }

    return (
      <tr className={styles.HelpLine}>
        <td className={cls + ' ' + styles.name}>
          <img src={`https://${process.env.REACT_APP_DOMAIN}/images/icon_plus.gif`} alt={this.props.name} />
          {this.props.name}
        </td>
        <td className={cls}>
          {this.props.video && (
            <VideoLink
              link={this.props.video}
              englishOnly={getBaseLangName(i18n.language) !== 'en'}
            />
          )}
        </td>
        <td className={cls}>
          {this.state.url && (
            <PDFLink url={this.state.url} englishOnly={this.showEnglishOnly()} />
          )}
        </td>
        <td className={cls}>
          {this.props.link && (
            <a href={this.props.link.url}>{this.props.link.name}</a>
          )}
        </td>
      </tr>
    );
  }
}

HelpLine.contextType = SessionContext;

export default HelpLine;
