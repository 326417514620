import { VideoAd } from './types/VideoAd';

export const getVideoAdProfiles = (): Promise<any> => {
  const requestParams = new URLSearchParams();
  requestParams.set('type', 'VIDEO_AD');

  return fetch('/backend.php/videoad/getVideoProfiles', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: requestParams
  }).then(res => {
    return res.json();
  }).then(data => {
    return data.content;
  }).catch(() => {
    return [];
  });
};

export const getVideoAd = (id: string): Promise<VideoAd> => {
  const requestParams = new URLSearchParams();
  requestParams.set('videoAdId', id);

  return fetch('/videoad/editVideoAd', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: requestParams

  }).then(res => {
    return res.json();
  }).then(data => {
    if (data.videoAdFileMetadata) {
      try {
        data.videoAdFileMetadata = JSON.parse(data.videoAdFileMetadata);
      } catch (e) {
        data.videoAdFileMetadata = {};
      }
    }
    // support legacy video ads
    if (data.videoAdUuid == null || data.videoAdUuid === '') {
      data.videoAdCPFilePath = '/index.php/videoad/downloadVideoAd?videoAdId=' + data.videoAdId;
    }
    return data;
  }).catch(() => {
    return {};
  });
};

export const saveVideoAd = (data: any, isFileUploaded: boolean, id?: string): Promise<any> => {
  const url = id ? 'videoad/update' : 'videoad/add';
  const requestParams = new URLSearchParams();

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if (typeof data[key] === 'object') {
        requestParams.append(key, JSON.stringify(data[key]));
      } else {
        requestParams.append(key, data[key]);
      }
    }
  }

  if (isFileUploaded) {
    requestParams.append('videoAdFileUploaded', '1');
  }

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
    body: requestParams
  }).then(res => {
    return res.json();
  }).catch(() => {
    return {};
  });
};

export const getVideoAdTranscodingData = (videoTranscodingService: string, videoAdUuid: string): Promise<any> => {
  return fetch(
    `${videoTranscodingService}${videoAdUuid}/metadata`
  ).then(res => {
    return res.json();
  }).catch(() => {
    return {};
  });
};

export const addTranscodingJob = (videoTranscodingService: string, videoAdUuid: string, profiles: any): Promise<any> => {
  return fetch(`${videoTranscodingService}${videoAdUuid}/transcode`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(profiles)
  });
};
