import React from 'react';
import styles from './servermsg.module.scss';

interface ServerMsgProps {
  error_message: boolean;
  message: string;
}

export const ServerMsg = (props: ServerMsgProps) => {
  let styleClass = '';
  styleClass = (props.error_message) ? styles.errorMsg : styles.successMsg;
  styleClass = 'material-icons ' + styleClass;
  const getFormattedMsg = () => {
    let msgArr;
    let message = '';
    if (props.message) {
      message = props.message.replace(/(\r\n|\n|\r)/gm, '').trim();
    }

    if (/<P>/gi.test(message)) {
      message = message.replace(/<\/P>/gi, '');
    }

    msgArr = message.split('<p>');
    if (msgArr[0] === '') {
      msgArr.shift();
    }
    return msgArr.map(msg => {
      const iconType = props.error_message ? 'highlight_off' : 'check_circle';
      return (<p>
        <i className={styleClass}>{iconType}</i> <span dangerouslySetInnerHTML={{ __html: msg }} />
      </p>);

    });

  };
  return (
    <>
      <div className={styles.msgContainer} role='alert'>
        {getFormattedMsg()}
      </div>

    </>
  );
};
