import React, { useEffect, useRef, useState } from 'react';
import { AccountService } from '@chargepoint/common/services/AccountService';
import { KitEditPanel, KitSpinner, KitTextField } from '@chargepoint/cp-toolkit';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { StyledDiv, Styledlabel } from '../../pages/Account/styled-elements';
import { EditMode } from '../../common/constants';

interface AccountInfoProps {
  userId: string | number;
  details: any;
  disableEdit: boolean;
  updateSession: Function;
}

interface AccountDetailsView {
  firstName: string;
  lastName: string;
  username: string;
  orgName: string;
  accountNumber: string;
  isSSOMigrated: boolean;
}

const getAccountInfo = (details): AccountDetailsView => {
  const { first_name, last_name, username, org_name, account_number, is_sso_migrated } = details;
  return {
    firstName: first_name || '',
    lastName: last_name || '',
    username: username || '-',
    orgName: org_name || '-',
    accountNumber: account_number || '-',
    isSSOMigrated: is_sso_migrated || false
  };
};

const AccountInfo = ({ userId, disableEdit, details, updateSession }: AccountInfoProps) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState(EditMode.READ_ONLY);
  const [showSpinner, setShowSpinner] = useState(false);
  const [accountInfo, setAccountInfo] = useState(getAccountInfo(details) as AccountDetailsView);
  const initialInfo = useRef(getAccountInfo(details as AccountDetailsView));

  const { register, errors, trigger } = useForm({
    mode: 'onBlur'
  });

  useEffect(() => {
    setAccountInfo(getAccountInfo(details) as AccountDetailsView);
    initialInfo.current = getAccountInfo(details) as AccountDetailsView;
  }, [details]);

  const onToggle = () => {
    setMode(mode === EditMode.READ_ONLY ? EditMode.EDIT : EditMode.READ_ONLY);
  };

  const handleCancel = () => {
    setAccountInfo({ ...initialInfo.current });
    setMode(EditMode.READ_ONLY);
  };

  const handleSubmit = async () => {
    const result = await trigger();
    if (result) {
      try {
        setShowSpinner(true);
        const response = await AccountService.updateAccountDetails({
          id: userId,
          firstName: accountInfo.firstName,
          lastName: accountInfo.lastName,
        });
        if (response && response.status === true) {
          initialInfo.current = { ...accountInfo };
          setMode(EditMode.READ_ONLY);
          setShowSpinner(false);
          updateSession();
        } else {
          throw new Error(response.error ? response.error.message : '');
        }
      } catch (error) {
        setShowSpinner(false);
        // TODO handle error from server
      }
    }
  };

  const onChangeFirstName = (e) => {
    setAccountInfo({
      ...accountInfo,
      firstName: e.target.value
    });
  };

  const onChangeLastName = (e) => {
    setAccountInfo({
      ...accountInfo,
      lastName: e.target.value
    });
  };

  return <KitEditPanel
    t={t}
    title={t('common:accountPage.accountInfo')}
    mode={mode}
    onSubmit={handleSubmit}
    onCancel={handleCancel}
    onToggle={onToggle}
  >
    {showSpinner && <KitSpinner size='s' />}
    {mode === EditMode.EDIT ? (
      <>
        <KitTextField
          label={t('common:accountPage.firstName')}
          value={accountInfo.firstName}
          type='text'
          name='firstName'
          onChange={onChangeFirstName}
          error={errors && errors.firstName ? t('generalErrors.required') : ''}
          ref={register({ required: { value: true, message: t('generalErrors.required') } })}
          readOnly={disableEdit}
          required
        />
        <KitTextField
          label={t('common:accountPage.lastName')}
          value={accountInfo.lastName}
          type='text'
          name='lastName'
          onChange={onChangeLastName}
          error={errors && errors.lastName ? t('generalErrors.required') : ''}
          ref={register({ required: { value: true, message: t('generalErrors.required') } })}
          readOnly={disableEdit}
          required
        />
      </>
    ) : (
      <>
        <Styledlabel>{t('common:accountPage.firstName')}</Styledlabel>
        <StyledDiv>{accountInfo.firstName || '-'}</StyledDiv>
        <Styledlabel>{t('common:accountPage.lastName')}</Styledlabel>
        <StyledDiv>{accountInfo.lastName || '-'}</StyledDiv>
      </>
    )}
    <Styledlabel>{t('common:accountPage.username')}</Styledlabel>
    <StyledDiv>{accountInfo.username}</StyledDiv>
    <Styledlabel>{t('common:accountPage.organization')}</Styledlabel>
    <StyledDiv>{accountInfo.orgName}</StyledDiv>
    <Styledlabel>{t('common:accountPage.accountNumber')}</Styledlabel>
    <StyledDiv>{accountInfo.accountNumber}</StyledDiv>
  </KitEditPanel>;
};

export default AccountInfo;
