import React from 'react';
import styled from 'styled-components';
import { ThemeConstants } from '@chargepoint/cp-toolkit';
import { useTranslation } from "react-i18next";


const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.page.footer.bg};
  padding: ${ThemeConstants.spacing.m}rem;
  border-top: 1px solid  ${({ theme }) => theme.page.footer.border};
  color: ${({ theme }) => theme.page.body.text};
  & p {
    font-size: ${ThemeConstants.fontSize.text_12}rem;
  }
`;

export interface Props {
  className?: string;
}

const Footer: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();

  const footerCopyright = t('footer_copyright', {
    year: new Date().toLocaleDateString(i18n.language, { year: 'numeric' }),
    company_name: 'ChargePoint, Inc.',
  });

  return (
    <Container className={props.className}>
      <p>{footerCopyright}</p>
    </Container>
  );
}

export default Footer;
