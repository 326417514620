import styled from 'styled-components';
import { ThemeConstants } from '@chargepoint/cp-toolkit';

export const Select = styled.select`
  background: ${({ disabled, theme }) =>
    disabled ? 'rgb(242, 242, 242)' : theme.page.body.text};
  flex: 1 0 auto;
  border-radius: 0;
  padding: ${ThemeConstants.spacing.absolute.s}px;
  outline: none;
  border: 1px solid;
  border-color: ${({ disabled, theme }) =>
    disabled ? 'rgb(230, 230, 230)' : theme.button.primary.disabled.text};
  background: none;
  font-size: ${ThemeConstants.fontSize.text_14}rem;
  height: 38px;
`;
