import { addressFormatter, getAddressLabelsByCountry } from '../../common/utils';
import { KitFlexColSpaced, KitFlexRowSpaced } from '@chargepoint/cp-toolkit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Field from './field';

interface AddressProps {
  contactInfo: any;
  countryCode: string;
}

const Address = ({ contactInfo, countryCode }: AddressProps): JSX.Element => {
  const { t } = useTranslation();
  const getContactItem = (field, countryCode?) => {
    const label = getAddressLabelsByCountry(field, countryCode);
    return (
      <Field
        key={`field_${field}`}
        field={field}
        value={contactInfo[field]}
        label={t(`common:accountPage.${label}`)}
      />
    );
  };

  return (
    <>
      {addressFormatter(countryCode).map(format => {
        if (typeof format === 'string') {
          return getContactItem(format, countryCode);
        }
        return (
          <KitFlexRowSpaced key={`container_for_${format[0]}`}>
            {format.map(innerFormat => (
              <KitFlexColSpaced key={`wrapper_${innerFormat}`}>
                {getContactItem(innerFormat, countryCode)}
              </KitFlexColSpaced>
            ))}
          </KitFlexRowSpaced>
        );
      })}
    </>
  );
};

export default Address;
