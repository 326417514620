import { useFormContext } from './useFormContext';

interface UseFieldOptions {
  name: string;
  type?: string;
  value?: any;
  multiple?: boolean;
}

export function useField(options: UseFieldOptions) {
  const { values, setValue } = useFormContext();
  const { name, type = 'text' } = options;
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(name, event.target.value);
  };
  const value = values[name] ? values[name] : '';

  return {
    input: {
      name,
      type,
      value,
      onChange,
    },
  };
}
