import * as React from 'react';
import { KitTable, KitDropdownActionList } from '@chargepoint/cp-toolkit';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const StyledTh = styled.th`
  && {
    text-align: left;
  }
`;

const StyledTd = styled.td`
  && {
    line-break: auto;
    white-space: break-spaces;
  }
`;

interface Connection {
  id: number;
  name: string;
  driver_pool_configuration: {driver_pool_id: number};
}

export interface PaymentMethod {
  id: number;
  legal_name: string;
  currency_iso_code: string;
  company_id: number;
  payment_provider: {
    provider_type: string;
    provider_id: number;
    provider_name: string;
    localised_payment_provider_type: string;
  };
  connections: Array<Connection>;
}

export interface PaymentMethodTableProps {
  paymentMethods?: Array<PaymentMethod>;
  deleteMethod: Function;
  editMethod: Function;
}

function PaymentMethodsTable(props: PaymentMethodTableProps) {
  const { paymentMethods, deleteMethod, editMethod } = props;
  const { t } = useTranslation();
  const trs = [];

  const getConnections = connections => {
    return connections
      .map(({ name, id }) => {
        return name ? name : id;
      })
      .join(', ');
  };
  if (paymentMethods && paymentMethods.length > 0) {
    paymentMethods.forEach(paymentMethod => {
      let items = null;
      if (!paymentMethod.connections) {
        items = [
          {
            label: t('edit') as string,
            value: 'edit',
            onSelect: () => {
              editMethod(paymentMethod);
            }
          },
          {
            label: t('delete') as string,
            value: 'delete',
            onSelect: () => {
              let connectionIds = [];
              if (paymentMethod.connections) {
                connectionIds = paymentMethod.connections.map(connection => {
                  return connection.driver_pool_configuration.driver_pool_id;
                });
              }
              deleteMethod(paymentMethod.id, paymentMethod.company_id, connectionIds);
            }
          }
        ];
      }

      trs.push(
        <tr key={paymentMethod.id}>
          <StyledTd>{paymentMethod.payment_provider.localised_payment_provider_type}</StyledTd>
          <StyledTd>{paymentMethod.currency_iso_code}</StyledTd>
          <StyledTd>
            {paymentMethod.connections && getConnections(paymentMethod.connections)}
          </StyledTd>
          <StyledTd>
            {items && <KitDropdownActionList items={items} />}
          </StyledTd>
        </tr>
      );
    });
  }

  return (
    <KitTable>
      <thead>
        <tr>
          <StyledTh>{t('paymentType')}</StyledTh>
          <StyledTh>{t('currency')}</StyledTh>
          <StyledTh>{t('usedBy')}</StyledTh>
          <StyledTh>{t('action')}</StyledTh>
        </tr>
      </thead>
      <tbody>{trs}</tbody>
    </KitTable>
  );
}

export default PaymentMethodsTable;
