import React, { FC, Fragment } from 'react';

export const StatesInput: FC<{
  name: string;
  value: number;
  onChange: any;
  states: Array<{ id: number; name: string, originalName: string }>;
  labelValue: string;
}> = props => {

  let options = null;
  if (props.states && props.states.length > 0) {
    options = props.states.map(state => {
      return (
        <option key={state.id} value={state.id} data-stateoriginalname={state.originalName}>
          {state.name}
        </option>
      );
    });
  }

  return (
    <Fragment>
      <label htmlFor={props.name}>{props.labelValue}</label>
      <select name={props.name} id={props.name} value={props.value} onChange={props.onChange}>
        {options}
      </select>
    </Fragment>
  );
};

export const CountriesInput: FC<{
  name: string;
  value: number;
  onChange: any;
  countries: Array<{ id: number; name: string, originalName: string }>;
  labelValue: string;
}> = props => {
  const options = props.countries.map(country => {
    return (
      <option key={country.id} value={country.id} data-countryoriginalname={country.originalName}>
        {country.name}
      </option>
    );
  });

  return (
    <Fragment>
      <label htmlFor={props.name}>{props.labelValue}</label>
      <select name={props.name} id={props.name} value={props.value} onChange={props.onChange}>
        {options}
      </select>
    </Fragment>
  );
};

export interface Country {
  id: number;
  name: string;
  originalName: string;
  code: string;
}

export interface StateProvince {
  id: number;
  name: string;
  originalName: string;
}
