import React, {useContext, useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import ButtonSet from '../../Components/ButtonSet';
import styles from '../../Main/main.module.scss';
import {FlexBillingOption, IntroProps} from './intro';
import {getRemitOptions} from '../../Actions/flexbilling';
import {SessionContext} from '@chargepoint/common/hooks/SessionContext';

const Intro = (props: IntroProps) => {
  const { isWelcome, isNoc, postPaidPlanActive, activateFlexBilling } = props;

  const { t } = useTranslation();
  const { session } = useContext(SessionContext);

  const [remits, setRemits] = useState([]);
  const [introTxt, setIntroTxt] = useState('Intro.title_configure');
  const [paragraphTxt, setParagraphTxt] = useState('Intro.paragraph_subtitle');

  useEffect(() => {
    (async () => {
      if (!isWelcome && !isNoc && activateFlexBilling.cpOrgId) {
        getRemitOptions(activateFlexBilling.cpOrgId).then(res => {
          res.push({ remittanceId: '-1' });
          setRemits(res);
        });
      }
    })();
  }, [activateFlexBilling.cpOrgId, isWelcome, isNoc]);

  useEffect(() => {
    if (isWelcome) {
        setParagraphTxt('Intro.paragraph_instructions');
    } else if (isNoc) {
      setIntroTxt('Intro.title_approve');
    } else if (session.isFlexBillingHoldMyFeesServiceEnabled) {
      if (activateFlexBilling.paymentOption === FlexBillingOption.OPT_OUT
          || (activateFlexBilling.paymentOption === FlexBillingOption.MAIL_A_CHECK
          && !session.isFlexBillingMailCheckEnabled)) {
          activateFlexBilling.paymentOption = FlexBillingOption.HOLD_MY_FEES;
          props.onChange('setUpFlexLater', activateFlexBilling);
      }
    }
  }, [session, session.isFlexBillingHoldMyFeesServiceEnabled, activateFlexBilling, props, isWelcome, isNoc]);

  const getDefaultOptions = () => {
    return (<>
      <p>{t(paragraphTxt)}</p>
      <div>
        <br />
        <label htmlFor='setFlexbillingNow'>
          <input
            type='radio'
            name='setFlexbillingNow'
            checked={!activateFlexBilling.setUpFlexLater}
            onChange={() => {
              activateFlexBilling.setUpFlexLater = false;
              activateFlexBilling.paymentOption = FlexBillingOption.NOW;
              props.onChange('setUpFlexLater', activateFlexBilling);
            }}
          />
          {t('Intro.label_set_now')}
        </label>
        <br />
        <label htmlFor='setFlexbillingNow'>
          <input
            type='radio'
            name='setFlexbillingNow'
            checked={activateFlexBilling.setUpFlexLater}
            disabled={postPaidPlanActive}
            onChange={() => {
              activateFlexBilling.setUpFlexLater = true;
              activateFlexBilling.paymentOption = FlexBillingOption.OPT_OUT;
              props.onChange('setUpFlexLater', activateFlexBilling);
            }}
          />
          {t('Intro.label_set_later')}
        </label>
      </div>
    </>);
  }

  const getHoldMyFeesOptions = () => {
    return (
      <>
        <p>
          {!isWelcome
            ? <>{t('Intro.paragraph_subtitle')}</>
            : <>{t('Intro.paragraph_welcome')}</>
          }
        </p>
        {isWelcome && <p>
          <Trans i18nKey='Intro.option_now_info_txt' allowObjectInHTMLChildren>
            Through your station dashboard, you can customize the Session Fees (as defined in the MSSA) you charge to
            drivers who use your station. <strong>Not sure what amount to set?</strong> ChargePoint can provide you
            suggestions on how much to charge your drivers based on the station's location and site function (i.e.,
            retail vs. office). Feel free to contact your ChargePoint account executive for further information.
          </Trans>
        </p>}
        <p>
          <strong>{t('Intro.apply_earnings')}:</strong>
          <br/>
          <label htmlFor='applyDues_1'>
            <input
              type='radio'
              name='applyDues'
              id='applyDues_1'
              checked={activateFlexBilling.applyDues === true}
              onChange={() => {
                activateFlexBilling.applyDues = true;
                activateFlexBilling.setUpFlexLater = false;
                activateFlexBilling.paymentOption = FlexBillingOption.NOW;
                props.onChange('setUpFlexLater', activateFlexBilling);
              }}
            />
            <strong>{t('Intro.earnings_towards_plan')}</strong>
          </label>
          <br/>
          <span>
            <Trans i18nKey='Intro.earnings_towards_plan_info' allowObjectInHTMLChildren>
              By selecting this option, you agree to ChargePoint’s <a
              href={props.feeOffsetPolicyUrl} target='_blank' rel='noreferrer'>Fee Offset Policy</a>.
            </Trans>
          </span>
          <br/>
          <label htmlFor='applyDues_0'>
            <input
              type='radio'
              name='applyDues'
              id='applyDues_0'
              checked={activateFlexBilling.applyDues === false}
              onChange={() => {
                activateFlexBilling.applyDues = false;
                activateFlexBilling.setUpFlexLater = false;
                activateFlexBilling.paymentOption = FlexBillingOption.HOLD_MY_FEES;
                props.onChange('setUpFlexLater', activateFlexBilling);
              }}
            />
            <strong>{t('Intro.pay_all_earnings')}</strong>
          </label>
          <br/>
          <span>
            {t('Intro.pay_all_earnings_info')}
          </span>
        </p>
        <span>
          <Trans i18nKey='Intro.choose_payment_option' allowObjectInHTMLChildren>
            In order for us to remit to you your Net Session Fees, you will need to provide us your banking details.
            If you do not have this information at this time, you can fill out this section later in your station
            dashboard. <strong>Would you like to provide your banking details now?</strong>
          </Trans>
        </span>
        <p>
          <label htmlFor={FlexBillingOption.HOLD_MY_FEES}>
            <input
              type='radio'
              name='setFlexbillingNow'
              id={FlexBillingOption.HOLD_MY_FEES}
              checked={activateFlexBilling.paymentOption === FlexBillingOption.HOLD_MY_FEES}
              onChange={() => {
                activateFlexBilling.setUpFlexLater = false;
                activateFlexBilling.paymentOption = FlexBillingOption.HOLD_MY_FEES;
                props.onChange('setUpFlexLater', activateFlexBilling);
              }}
            />
            {t('Intro.option_hold_my_fees')}
          </label>
          <br/>
          <label htmlFor={FlexBillingOption.NOW}>
            <input
              type='radio'
              name='setFlexbillingNow'
              id={FlexBillingOption.NOW}
              checked={activateFlexBilling.paymentOption === FlexBillingOption.NOW}
              onChange={() => {
                activateFlexBilling.setUpFlexLater = false;
                activateFlexBilling.paymentOption = FlexBillingOption.NOW;
                props.onChange('setUpFlexLater', activateFlexBilling);
              }}
            />
            {t('Intro.option_now')}
          </label>
          {session.isFlexBillingMailCheckEnabled && (
            <>
              <br/>
              <label htmlFor={FlexBillingOption.MAIL_A_CHECK}>
                <input
                  type='radio'
                  name='setFlexbillingNow'
                  id={FlexBillingOption.MAIL_A_CHECK}
                  checked={activateFlexBilling.paymentOption === FlexBillingOption.MAIL_A_CHECK}
                  onChange={() => {
                    activateFlexBilling.setUpFlexLater = false;
                    activateFlexBilling.paymentOption = FlexBillingOption.MAIL_A_CHECK;
                    props.onChange('setUpFlexLater', activateFlexBilling);
                  }}
                />
                {t('Intro.option_mail_a_check')}
              </label>
            </>
          )}
        </p>
        {isWelcome && (
          <>
            <span>{t('Intro.option_opt_out_info_txt')}</span>
            <p>
              <label htmlFor={FlexBillingOption.OPT_OUT}>
                <input
                  type='radio'
                  name='setFlexbillingNow'
                  id={FlexBillingOption.OPT_OUT}
                  checked={activateFlexBilling.paymentOption === FlexBillingOption.OPT_OUT}
                  onChange={() => {
                    activateFlexBilling.applyDues = null;
                    activateFlexBilling.setUpFlexLater = true;
                    activateFlexBilling.paymentOption = FlexBillingOption.OPT_OUT;
                    props.onChange('setUpFlexLater', activateFlexBilling);
                  }}
                />
                {t('Intro.option_opt_out')}
              </label>
            </p>
            {activateFlexBilling.paymentOption === FlexBillingOption.OPT_OUT && (
              <p>
                {t('Intro.option_opt_out_reason')}:
                <br/>
                <textarea
                  name='flexBillingOptOutReason'
                  value={activateFlexBilling.optOutReason}
                  cols={60}
                  rows={5}
                  onChange={(e) => {
                    activateFlexBilling.optOutReason = e.target.value;
                    props.onChange('setUpFlexLater', activateFlexBilling);
                  }}
                />
              </p>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <div className={styles.introContainer}>
      <h3>{t(introTxt)}</h3>
      <p className={styles.introParagraph}>
        {isWelcome && !session.isFlexBillingHoldMyFeesServiceEnabled &&
            <strong>{t('Intro.label_instructions')}:</strong>}
        {isWelcome && !session.isFlexBillingHoldMyFeesServiceEnabled && getDefaultOptions()}
        {isWelcome && session.isFlexBillingHoldMyFeesServiceEnabled && getHoldMyFeesOptions()}
      </p>
      {isNoc && (
        <div className={styles.inputContainer}>
          <div>
            <div className={styles.row}>
            <div className={styles.item}>
                  <label htmlFor='activateFlexbilling'>{t('Intro.title_activate') as string}</label>
                </div>
                <div className={styles.item}>
                  <input
                    type='checkbox'
                    name='activateFlexbilling'
                    checked={activateFlexBilling.isActivate}
                    onChange={() => {
                      activateFlexBilling.isActivate = !activateFlexBilling.isActivate;
                      props.onChange('isActivate', activateFlexBilling);
                    }}
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div className={styles.item}>
                  <label htmlFor='remittanceId'>{t('Intro.label_remittanceID')}</label>
                </div>
                <div className={styles.item}>
                  {' '}
                  {activateFlexBilling.canEditVendorId ? (
                    <input
                      name='remittanceId'
                      id='remittanceId'
                      value={activateFlexBilling.remittanceId}
                      onChange={evt => {
                        props.activateFlexBilling.remittanceId = evt.target.value;
                        props.onChange('remittanceId', activateFlexBilling);
                        if (evt.target.value !== '') {
                          activateFlexBilling.isActivate = true;
                          props.onChange('isActivate', activateFlexBilling);
                        }
                      }}
                    />
                  ) : (
                    activateFlexBilling.remittanceId
                  )}
                </div>
              </div>
              {session.isFlexBillingHoldMyFeesServiceEnabled && (
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <label>{t('remittance_title_remitBy')}</label>
                    </div>
                      <div className={styles.item}>
                      <span className={styles.largeLabel}>
                        {activateFlexBilling.paymentOption !== FlexBillingOption.MAIL_A_CHECK
                          ? t('remitance.title_' + activateFlexBilling.paymentOption)
                          : ''}
                      </span>
                    </div>
                  </div>
              )}
              {props.canEditPrice && (
                <>
                  <div className={styles.row}>
                    <div className={styles.item}>
                      <label htmlFor='fixedPrice'>{t('Intro.label_fixed_fee')}</label>
                    </div>
                    <div className={styles.item}>
                      <input
                        name='fixedPrice'
                        id='fixedPrice'
                        value={activateFlexBilling.fixedFee}
                        onChange={evt => {
                          props.activateFlexBilling.fixedFee = evt.target.value;
                          props.onChange('fixedPrice', activateFlexBilling);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.row}>
                    <div className={styles.item}>
                      <label htmlFor='variablePrice'>{t('Intro.label_variable_fee')}</label>
                    </div>
                    <div className={styles.item}>
                      <input
                        name='variablePrice'
                        id='variablePrice'
                        value={activateFlexBilling.variableFee}
                        onChange={evt => {
                          props.activateFlexBilling.variableFee = evt.target.value;
                          props.onChange('variableFee', activateFlexBilling);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.row}>
                    <div className={styles.item}>
                      <label htmlFor='description'>Description</label>
                    </div>
                    <div className={styles.item}>
                      <input
                        name='description'
                        id='description'
                        value={activateFlexBilling.description}
                        onChange={evt => {
                          props.activateFlexBilling.description = evt.target.value;
                          props.onChange('description', activateFlexBilling);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className={styles.row}>
                <div className={styles.item} />
                <div className={styles.item}>
                  <ButtonSet
                    cancelFunction={e => {
                      e.preventDefault();
                      document.dispatchEvent(new CustomEvent('flexbilling-canceled'));
                    }}
                    resetFunction={e => {
                      props.resetForm();
                      e.preventDefault();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={styles.inputContainer}>
          {(!isWelcome || !session.isFlexBillingHoldMyFeesServiceEnabled) && (<>
            <div className={styles.row}>
              <div className={`${styles.item} ${styles.itemNm}`}>
                <label>{t('Intro.label_orgName')}</label>
              </div>
              <div className={`${styles.item} ${styles.itemNm}`}>
                <span className={styles.largeLabel}>{activateFlexBilling.orgName}</span>
              </div>
            </div>

            <div className={styles.row}>
              <div className={`${styles.item} ${styles.itemNm}`}>
                <label>{t('Intro.label_orgID')}</label>
              </div>
              <div className={`${styles.item} ${styles.itemNm}`}>
                <span className={styles.largeLabel}>{activateFlexBilling.companyOrganizationId}</span>
              </div>
            </div>

            <div className={styles.row}>
              <div className={`${styles.item} ${styles.itemNm}`}>
                <label>{t('Intro.label_groupName')}</label>
              </div>
              <div className={`${styles.item} ${styles.itemNm}`}>
                <span className={styles.largeLabel}>
                  {activateFlexBilling.groupName
                    ? activateFlexBilling.groupName
                    : t('Intro.title_organization_default')}
                </span>
              </div>
            </div>
          </>)}

          {!isWelcome && !isNoc && (<>
            <div className={styles.row}>
              <div className={`${styles.item} ${styles.itemNm}`}>
                <label htmlFor='remittanceId'>{t('Intro.label_remitTo')}</label>
              </div>

              <div className={`${styles.item} ${styles.itemNm}`}>
                <select
                  name='remittanceId'
                  id='remittanceId'
                  onChange={evt => {
                    props.activateFlexBilling.remittanceId = evt.target.value;
                    props.onChange('remittanceId', activateFlexBilling);
                  }}
                  value={activateFlexBilling.remittanceId || '-1'}
                >
                  {remits.map(remit => {
                    return (
                      <option key={remit.remittanceId} value={remit.remittanceId}>
                        {remit.remittanceId === '-1'
                          ? t('Intro.dropdown_createNew')
                          : remit.remittanceId}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </>)}
        </div>
        {!isWelcome && !isNoc && session.isFlexBillingHoldMyFeesServiceEnabled && <p>{getHoldMyFeesOptions()}</p>}
        {!activateFlexBilling.setUpFlexLater
            && activateFlexBilling.paymentOption !== FlexBillingOption.HOLD_MY_FEES && (
          <>
            <strong>
              <Trans i18nKey='Intro.fill_required' allowObjectInHTMLChildren>
                Please fill out all the required fields (<span className={styles.required}></span>) below
              </Trans>
            </strong>
          </>
        )}
      </div>
  );
};

export default Intro;
