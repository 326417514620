import React from 'react';
import FuelCardProviders from '../../fuel-cards/FuelCardProviders';

import Page from '../../components/Page';

const FuelCardProvidersPage: React.FC = props => {
  return (
    <Page>
      <FuelCardProviders />
    </Page>
  );
};

export default FuelCardProvidersPage;
