import { ThemeConstants } from '@chargepoint/cp-toolkit';

export enum EditMode {
  EDIT = 'edit',
  READ_ONLY = 'read-only'
}

export enum RequestType {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
  POST = 'POST'
}

export const UILayout = {
  footerHeight: 4,
  hPageMargin: ThemeConstants.spacing.absolute.m * 3
};

export enum NotificationLevel {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success'
}

export const ADMIN_URL = '/r/admin';
export const ADMIN_ACCOUNT_URL = '/r/admin/account';

export const userNavItemMapping = {
  fleet: 'Fleet',
  station: 'Station',
  admin_tools: 'Admin Tools',
  installer: 'Installer'
};

export enum NOTIFICATION_TYPE {
  GENERAL = 'general',
  VALET = 'valet',
  FLEET = 'fleet',
  NOTIFY_BY = 'notifyBy'
}

export const TRAINING_VIDEOS = 'training_videos';
