import React, { Component } from 'react';
import ReleaseNotesLine from '../../Components/ReleaseNotesLine'
import { Translation } from 'react-i18next'
import i18n from '../../../Intl/i18n';
import { getBaseLangName } from '@chargepoint/common/util/i18n';
import 'whatwg-fetch';
import EnglishOnly from '../../Components/EnglishOnly';
import styles from './style.module.scss';
import { releaseNotesConfig } from '../../../data/releasenotes';


class ReleaseNotes extends Component {
  render() {
    return (
      <Translation>
        {t => (
          <div className={styles.ReleaseNotes}>
            <table>
              <tr>
                <th>{t('header_release_date')}</th>
                <th>{t('header_version')}</th>
                <th>
                  {t('header_release_notes')}
                  {getBaseLangName(i18n.language) !== 'en' && <EnglishOnly />}
                </th>
                <th>{t('header_highlights')}</th>
              </tr>
              {
                releaseNotesConfig.map((section, i) => {
                  return (<ReleaseNotesLine key={i} row={i++} name={section.name} version={section.version} pdf={section.pdf}
                                            overview={section.overview} highlights={section.highlights} />);
                })
              }
            </table>
          </div>
        )}
      </Translation>
    );
  }
}

export default ReleaseNotes;
