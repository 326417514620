export interface IEventProperties {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const analyticEvents = {
  card3dsInitialization: '3DS - Initialization',
  card3dsCheckVersion: '3DS - Check Version',
  card3dsAuthenticate: '3DS - Authenticate',
  card3dsChallenge: '3DS - Challenge',
};
