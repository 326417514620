import React, { Component } from 'react';
import { Translation } from 'react-i18next';

interface HelpIndexProps {
  row: number,
  title: string
}

class HelpIndex extends Component<HelpIndexProps> {
  render() {
    return (
      <Translation>
        {t => (
          <li><a href={window.location.pathname + '#q' + this.props.row}>{t(this.props.title)}</a></li>
        )}
      </Translation>
    );
  }
}

export default HelpIndex;
