interface SelectOptionObject {
  label: string | number;
  value: string | number;
}

export function normalizeSelectOption(
  option: string | number | object
): SelectOptionObject {
  return typeof option === 'object'
    ? (option as SelectOptionObject)
    : { label: option, value: option };
}
