import React, {useState, useEffect, useContext} from 'react';
import Pagelet from '@chargepoint/common/components/Pagelet';
import { EMVData, ReceiptData, ReceiptDetails } from '@chargepoint/web-common';
import {
  NosBackendService,
  CORPORATE_SUPPORT_URL,
  DEFAULT_CORPORATE_URL,
  CORPORATE_DRIVER_FAQ_URL,
  RECEIPT_EMV_DATA_ROLES
} from '../services/NosBackendService';
import { Trans, useTranslation } from 'react-i18next';
import { AccountService } from '@chargepoint/common/services/AccountService';
import { KitSpinner } from '@chargepoint/cp-toolkit';
import { SessionContext } from '@chargepoint/common/hooks/SessionContext';

export interface Props {
  className?: string;
  vehicleChargeId?: string;
}

const AdminReceiptPagelet: React.FC<Props> = (props) => {
  const { t, i18n } = useTranslation();
  const { session } = useContext( SessionContext );
  const [vehicleChargeId, setVehicleChargeId] = useState(null);
  const [receiptData, setReceiptData] = useState<ReceiptData | null>(null);
  const [emvData, setEMVData] = useState<EMVData | null>(null);

  const [supportLink, setSupportLink] = useState(DEFAULT_CORPORATE_URL + CORPORATE_SUPPORT_URL);
  const [faqLink, setFaqLink] = useState(DEFAULT_CORPORATE_URL + CORPORATE_DRIVER_FAQ_URL);

  useEffect(() => {
    NosBackendService.getLocaleCorporateUrl(i18n.language.toLowerCase()).then(url => {
      if (url) {
        setSupportLink(url + CORPORATE_SUPPORT_URL);
        setFaqLink(url + CORPORATE_DRIVER_FAQ_URL);
      }
    });
  }, [i18n.language]);

  useEffect(() => {
    const reactData = sessionStorage.getItem('reactData');
    const reactDataObj = JSON.parse(reactData);
    if (reactDataObj && reactDataObj.vehicleChargeId) {
      setVehicleChargeId(reactDataObj.vehicleChargeId);
    }

    const handleOpenDialog = (e: CustomEvent) => {
      setVehicleChargeId(e.detail.vehicle_charge_id);
    };
    document.addEventListener(
      'show-react-receipt-dialog-event',
      handleOpenDialog
    );

    return () => {
      window.removeEventListener(
        'show-react-receipt-dialog-event',
        handleOpenDialog
      );
    };
  }, []);

  useEffect(() => {
    if (props.vehicleChargeId) {
      setVehicleChargeId(props.vehicleChargeId);
    }
  }, [props.vehicleChargeId]);

  useEffect(() => {
    (async () => {
      if (!vehicleChargeId) {
        return null;
      }

      const result = await AccountService.getReceiptData(vehicleChargeId);
      document.dispatchEvent(
        new CustomEvent('update-ng-title', {
          detail: {
            title:
              result.showInvoice === true
                ? t('common:receipt.invoice')
                : t('common:receipt.receipt'),
          },
        })
      ); // browser event to update the title of the dialog in ng2
      if (result.session_type === 'CONTACTLESS' && RECEIPT_EMV_DATA_ROLES.indexOf(session.userInfo.userRole) !== -1) {
        const emvData = await AccountService.getReceiptEMVData(vehicleChargeId);
        setEMVData(emvData);
      } else {
        setEMVData(null);
      }
      setReceiptData(result);
    })();
  }, [vehicleChargeId, t, session.userInfo.userRole]);

  return (
    <Pagelet className={props.className}>
      {!receiptData ? (
        <KitSpinner />
      ) : (
        <ReceiptDetails
          t={t}
          corpSupportUrl={supportLink}
          corporateFAQUrl={faqLink}
          nosServerEndpoint={window.location.host}
          accountEndPoint={AccountService.getAccountApiEndpoint()}
          chargingSessionData={receiptData}
          emvData={emvData}
          locale={i18n.language}
          Trans={Trans}
          isLoggedIn={session.isLoggedIn}
          />
      )}
    </Pagelet>
  );
};

export default AdminReceiptPagelet;
