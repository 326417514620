/* eslint-disable class-methods-use-this */
import mixpanel, { Dict } from 'mixpanel-browser';
import { IEventProperties } from './AnalyticEvents';
import { SessionState } from '@chargepoint/common/hooks/SessionContext';

class AnalyticsService {
  private static instance: AnalyticsService;

  public static getInstance(sessionObj?: SessionState): AnalyticsService {
    if (!AnalyticsService.instance) {
      mixpanel.init(sessionObj.mixPanelTrackingID, {
        debug: false,
      });

      AnalyticsService.instance = new AnalyticsService();
      AnalyticsService.instance.identify(sessionObj?.userInfo?.userId.toString());
    }
    return AnalyticsService.instance;
  }

  trackEvent(name: string, properties?: IEventProperties): void {
    mixpanel.track(name, properties as Dict);
  }

  identify(userId: string): void {
    mixpanel.identify(userId);
  }
}

export default AnalyticsService;
