import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { KitButton, KitSpinner, ThemeConstants, SessionService } from '@chargepoint/cp-toolkit';
import { v4 as uuid } from 'uuid';
import { TextInput, Textarea, Select, OrgSelection } from './Resource';
import {
  addTranscodingJob,
  getVideoAd,
  getVideoAdProfiles,
  saveVideoAd,
  getVideoAdTranscodingData
} from './Resource';
import { VideoAd } from './Resource/types/VideoAd';
import { FormProvider } from './contexts/FormContext';
import { FieldContainer, FieldRow, FileLabel, FormFooter, LabelText } from './Resource/styled';
import Uploader from './Resource/Uploader';

const statuses = [
  { label: 'encodingFailedLabel', value: 'ENCODING_FAILED' },
  { label: 'encodingLabel', value: 'ENCODING' },
  { label: 'pendingLabel', value: 'PENDING' },
  { label: 'approvedLabel', value: 'APPROVED' },
  { label: 'deniedLabel', value: 'DENIED' }
];

const UploaderWrapper = styled.div`
  font-family: ${({ theme }) => theme.font.fontFamily};
  font-size: ${ThemeConstants.baseFontSize}px;
`;

interface VideoUploaderProps {
  onClose: Function;
  onSubmit: Function;
}
const VideoUploaderWrapper: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true);

  const handleSubmit = () => {
    window.frames[0].postMessage({ react: 'reload-video-ads' }, '*');
    document.dispatchEvent(new CustomEvent('close-video-add-modal'));
  };

  const handleClose = () => {
    setIsOpen(false);
    document.dispatchEvent(new CustomEvent('close-video-add-modal'));
  };

  return (
    <UploaderWrapper>
      {isOpen && <VideoUploader onClose={handleClose} onSubmit={handleSubmit} />}
    </UploaderWrapper>
  );
};

const UploaderContainer = styled.div`
  font-family: ${({ theme }) => theme.font.fontFamily};
  font-size: ${ThemeConstants.fontSize.text_14}rem;
  color: ${({ theme }) => theme.page.body.text};
  min-height: 360px;
`;

export function VideoUploader(props: VideoUploaderProps) {
  const { onClose, onSubmit } = props;
  const [videoAdId, setVideoAdId] = useState<string>('');
  const [videoAd, setVideoAd] = useState<VideoAd>({} as VideoAd);
  const [videoAdTranscodingData, setVideoAdTranscodingData] = useState<any>();
  const [videoTranscodingService, setVideoTranscodingService] = useState<string>();
  const [profiles, setProfiles] = useState();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const { t } = useTranslation();

  const init = () => {
    (async () => {
      const session = await SessionService.getSessionData();
      setVideoTranscodingService(session.videoTranscodingService);
      const list = await getVideoAdProfiles();
      setProfiles(list);
      const videoId = window.sessionStorage.getItem('videoAdId') as string;
      let result = {} as VideoAd;

      if (videoId) {
        setVideoAdId(videoId);
        window.sessionStorage.removeItem('videoAdId');
        result = await getVideoAd(videoId);
        setVideoAd(result);

        if (result.videoAdStatus === 'ENCODING_FAILED' && isDisabled(result.videoAdfileDisabled)) {
          setErrorMessage({
            message: t('videoAd.encodingFailedMgs')
          });
        }

        if (result.videoAdUuid && session.videoTranscodingService) {
          const data = await getVideoAdTranscodingData(
            session.videoTranscodingService,
            result.videoAdUuid
          );
          if (data.Items) {
            setVideoAdTranscodingData(data.Items[0]);
          }
        }
      }

      if (!result.videoAdUuid) {
        result.videoAdUuid = uuid();
      }

      setVideoAd(result);
      setShowLoader(false);
    })();
  };

  useEffect(init, []);

  const isDisabled = (value: any): boolean => {
    return value === 'DISABLED';
  };

  const handleUploadFinish = () => {
    setIsFileUploaded(true);
  };

  const handleCancel = event => {
    event.preventDefault();
    onClose();
  };

  const handleSubmit = async (values: any) => {
    setShowLoader(true);
    const result = await saveVideoAd(values, isFileUploaded, videoAdId);

    if (result.status === 1) {
      if (values.videoAdUuid && isFileUploaded && videoTranscodingService) {
        await addTranscodingJob(videoTranscodingService, values.videoAdUuid, profiles);
      }

      onSubmit();
    } else {
      let errorTxt = '';
      if (result.error) {
        errorTxt = result.error;
      } else {
        for (const key in result.response) {
          if (result.response.hasOwnProperty(key)) {
            errorTxt = errorTxt + result.response[key] + '\n';
          }
        }
      }

      setErrorMessage({
        message: errorTxt
      });
    }

    setShowLoader(false);
  };

  const getStatusLabel = (status: string) => {
    const result = statuses.filter(obj => obj.value === status);
    return `videoAd.${result[0].label}`;
  };

  return (
    <>
      {showLoader && <KitSpinner withOverlay />}
      <UploaderContainer>
        {videoAd.videoAdUuid && (
          <FormProvider initialValues={videoAd} onSubmit={handleSubmit}>
            <OrgSelection
              name='videoAdCompanyId'
              label={t('videoAd.organizationLabel')}
              isDisabled={isDisabled(videoAd.orgNameDisabled)}
            />
            <TextInput
              name='videoAdName'
              label={t('videoAd.adNameLabel')}
              disabled={isDisabled(videoAd.videoAdNameDisabled)}
            />
            <Textarea
              name='videoAdDescription'
              label={t('videoAd.descriptionLabel')}
              disabled={isDisabled(videoAd.videoAdDescriptionDisabled)}
            />
            {videoAdId && (
              <>
                {isDisabled(videoAd.videoAdStatusDisabled) && (
                  <FieldContainer>
                    <FieldRow>
                      <LabelText>{t('videoAd.statusLabel')}</LabelText>
                      <FileLabel>{t(getStatusLabel(videoAd.videoAdStatus))}</FileLabel>
                    </FieldRow>
                  </FieldContainer>
                )}
                {!isDisabled(videoAd.videoAdStatusDisabled) && (
                  <Select
                    name='videoAdStatus'
                    label={t('videoAd.statusLabel')}
                    disabled={isDisabled(videoAd.videoAdStatusDisabled)}
                    options={statuses}
                  />
                )}
              </>
            )}
            <Uploader
              registeredAt={videoAdTranscodingData ? videoAdTranscodingData.registeredAt : null}
              videoAdUuid={videoAd.videoAdUuid}
              videoAdTranscodingData={videoAdTranscodingData}
              videoAdFileMetadata={videoAd.videoAdFileMetadata}
              onUploadFinish={handleUploadFinish}
              awsUrl={videoTranscodingService}
              videoAdCPFilePath={videoAd.videoAdCPFilePath}
              videoAdFileOriginalName={videoAd.videoAdFileOriginalName}
              isEdit={Boolean(videoAdId)}
              error={errorMessage}
              uploadDisabled={isDisabled(videoAd.videoAdfileDisabled)}
            />
            <FormFooter>
              <KitButton variant='secondary' onClick={handleCancel}>
                {t('videoAd.cancel')}
              </KitButton>
              <KitButton
                disabled={
                  isDisabled(videoAd.videoAdSaveDisabled) || (!videoAdId && !isFileUploaded)
                }
              >
                {t('videoAd.submit')}
              </KitButton>
            </FormFooter>
          </FormProvider>
        )}
      </UploaderContainer>
    </>
  );
}

export default VideoUploaderWrapper;
