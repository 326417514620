import { Country, StateProvince } from '../utils/statesCountries';
import { AccountService } from '@chargepoint/common/services/AccountService';

interface StateProvinceCache {
  states: Map<string, Promise<StateProvince[]>>;
}

export class FlexbillingCache {
  supportedCountries: Promise<Country[]>;
  stateCache: StateProvinceCache = { states: new Map() };


  async getSupportedCountries(extraCountryId?: string) {
    if (!this.supportedCountries) {
      this.supportedCountries = AccountService.getCountries(extraCountryId);
    }
    return this.supportedCountries;
  }

  async getStates(countryId: string) {
    if (Number(countryId) < 0) {
      return [];
    }
    if (!this.stateCache.states.has(countryId)) {
      this.stateCache.states.set(countryId, AccountService.getStates(countryId));
    }
    return this.stateCache.states.get(countryId);
  }

  clearCountryCache() {
    this.supportedCountries = null;
  }
}

export const flexbillingCacheFactory = (() => {
  let instance: FlexbillingCache = null;
  let subscribersCount = 0;
  return {
    subscribe: () => {
      if (!instance) {
        instance = new FlexbillingCache();
        subscribersCount++;
      }
      return instance;
    },
    unsubscribe: () => {
      subscribersCount--;
      if (subscribersCount === 0) {
        instance = null;
      }
    }
  };
})();
