import React, { useEffect, useRef, useState } from 'react';
import { AccountService } from '@chargepoint/common/services/AccountService';
import {
  KitEditPanel,
  KitFlexRowSpaced,
  KitSpinner,
  KitTextField,
  KitPhone,
  KitToast,
  useToast,
  KitToastTypeOptions
} from '@chargepoint/cp-toolkit';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { StyledDiv, Styledlabel } from '../../pages/Account/styled-elements';
import { EditMode } from '../../common/constants';
import Address from './address';
import Field from './field';
import { addressFormatter, getAddressLabelsByCountry } from '../../common/utils';
import { ContactInformationProps, ContactInfoView, Country, Language, State } from './interfaces';

const getContactInfo = (details): ContactInfoView => {
  const {
    email,
    cell_phone,
    cell_phone_code,
    phone_code,
    phone,
    fax_code,
    fax,
    pref_lang,
    address1,
    address2,
    zipcode,
    city,
    country_id,
    state_id
  } = details;
  return {
    email,
    prefLang: pref_lang,
    address1,
    address2,
    phone,
    phoneCode: phone_code,
    cellPhoneCode: cell_phone_code,
    cellPhone: cell_phone,
    zipcode,
    city,
    fax,
    faxCode: fax_code,
    countryId: country_id,
    stateId: state_id
  };
};

const KitFlexRowSpacedStyled = styled(KitFlexRowSpaced)`
  gap: 10px;
`;

const ContactInformation = ({
  userId,
  disableEdit,
  refreshSession,
  countries = []
}: ContactInformationProps) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState(EditMode.READ_ONLY);
  const [showSpinner, setShowSpinner] = useState(false);
  const [states, setStates] = useState<State[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [countryCode, setCountryCode] = useState<string>('');
  const initialInfo = useRef(getContactInfo({} as ContactInfoView));
  const defaultCountryCode = useRef('');
  const { register, errors, trigger, setError } = useForm({
    mode: 'onBlur'
  });

  const [contactInfo, setContactInfo] = useState(getContactInfo({}) as ContactInfoView);

  const onToggle = () => {
    setMode(mode === EditMode.READ_ONLY ? EditMode.EDIT : EditMode.READ_ONLY);
  };

  const loadStates = async (countryId: number | string) => {
    if (!countryId) {
      return [];
    }
    try {
      return await AccountService.getStates(countryId);
    } catch (error) {
      return [];
    }
  };

  const handleCancel = async () => {
    setCountryCode(defaultCountryCode.current);
    let initialContactInfo = { ...initialInfo.current };
    if (initialInfo.current.countryId) {
      const statesInfo = await loadStates(initialInfo.current.countryId);
      setStates(statesInfo);
      if (initialInfo.current.stateId) {
        initialContactInfo = {
          ...initialContactInfo,
          state: getItemField(initialInfo.current.stateId, 'name', statesInfo)
        };
      }
    } else {
      setStates([]);
    }
    setContactInfo(initialContactInfo);
    setMode(EditMode.READ_ONLY);
  };

  const getItemField = (
    id: string | number,
    field: string,
    items: (Country | State | Language)[],
    matchField = 'id'
  ): string => {
    if (!id || !field || !items) {
      return undefined;
    }
    const itemInfo = items.find(item => item[matchField].toString() === id.toString());
    return itemInfo ? itemInfo[field] : undefined;
  };

  const onChangeEmail = e => onChangeField('email', e.target.value);
  const onChangeAddress1 = e => onChangeField('address1', e.target.value);
  const onChangeAddress2 = e => onChangeField('address2', e.target.value);
  const updatePhone = value => onChangeField('phone', value);
  const updatePhoneCode = value => onChangeField('phoneCode', value);
  const updateCellPhone = value => onChangeField('cellPhone', value);
  const updateFax = value => onChangeField('fax', value);
  const updateFaxCode = value => onChangeField('faxCode', value);
  const updateCellPhoneCode = value => onChangeField('cellPhoneCode', value);
  const onChangeZip = e => onChangeField('zipcode', e.target.value);
  const onChangeCity = e => onChangeField('city', e.target.value);

  const onLangChange = e => {
    const prefLang = e.target.value;
    setContactInfo({
      ...contactInfo,
      prefLang,
      language: getItemField(prefLang, 'lang_local_name', languages, 'langId')
    });
  };

  const onChangeCountry = async e => {
    const countryId = e.target.value;
    setContactInfo({
      ...contactInfo,
      countryId,
      stateId: undefined,
      country: getItemField(countryId, 'name', countries)
    });
    setStates([]);
    setCountryCode(getItemField(countryId, 'code', countries));
    const statesInfo = await loadStates(countryId);
    setStates(statesInfo);
  };

  const onChangeState = e => {
    const stateId = e.target.value;
    setContactInfo({
      ...contactInfo,
      stateId,
      state: getItemField(stateId, 'name', states)
    });
  };

  const onChangeField = (field, value) => {
    setContactInfo({
      ...contactInfo,
      [field]: value
    });
  };

  const updateError = (field: string, message: string, type = 'server') => {
    setError(field, {
      type,
      message
    });
  };

  const handleSubmit = async () => {
    const result = await trigger();
    if (!result) {
      return;
    }
    try {
      setShowSpinner(true);
      const response = await AccountService.updateContactDetails({ ...contactInfo, userId });
      if (response && response.status === true) {
        const shouldRefreshLangCountryCache = initialInfo.current.prefLang !== contactInfo.prefLang;
        initialInfo.current = { ...contactInfo };
        if (shouldRefreshLangCountryCache) {
          const sessionData = await AccountService.refreshLangCache();
          if (sessionData && sessionData.hasOwnProperty('userID')) {
            refreshSession(sessionData);
          }
        }
        setMode(EditMode.READ_ONLY);
      } else if (response.error && response.error.message) {
        const { message } = response.error;
        if (typeof message === 'string') {
          updateError('email', message);
          return;
        }
        Object.keys(message).forEach((key: string) => {
          const regexp = /notify_info\[(.*?)\]/m;
          if (regexp.test(key)) {
            const groups = key.match(regexp);
            updateError(groups[1], message[key]);
            return;
          }
          updateError(key, message[key]);
        });
      }
    } catch (error) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useToast({
        t,
        toastType: KitToastTypeOptions.ERROR,
        message: (error && error.message) || t('common:accountPage.serverError')
      });
    } finally {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (!userId || !countries) {
        return;
      }
      try {
        setShowSpinner(true);
        const [details, availableLanguages] = await Promise.all([
          AccountService.getUserContactDetails(userId),
          AccountService.getAvailableLanguages()
        ]);
        setShowSpinner(false);
        const contactDetails = getContactInfo(details);
        let fullInfo = {
          ...contactDetails,
          language: getItemField(
            contactDetails.prefLang,
            'lang_local_name',
            availableLanguages,
            'langId'
          ),
          country: getItemField(contactDetails.countryId, 'name', countries)
        };
        setLanguages(availableLanguages);
        const cCode = getItemField(contactDetails.countryId, 'code', countries);
        setCountryCode(cCode);
        if (contactDetails.countryId) {
          const statesInfo = await loadStates(contactDetails.countryId);
          setStates(statesInfo);
          if (contactDetails.stateId) {
            fullInfo = {
              ...fullInfo,
              state: getItemField(contactDetails.stateId, 'name', statesInfo)
            };
          }
        }
        setContactInfo(fullInfo);
        initialInfo.current = fullInfo;
        defaultCountryCode.current = cCode;
      } catch (error) {}
    })();
  }, [userId, countries]);

  return (
    <KitEditPanel
      t={t}
      title={t('common:accountPage.contactInfo')}
      mode={mode}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      onToggle={onToggle}
    >
      {showSpinner && <KitSpinner size='s' />}
      {mode === EditMode.EDIT ? (
        <>
          <KitToast position='top-right' duration={2000} reverseOrder />
          <KitTextField
            label={t('common:accountPage.email')}
            value={contactInfo.email}
            type='email'
            name='email'
            onChange={onChangeEmail}
            error={errors && errors.email ? errors.email.message : ''}
            ref={register({ required: { value: true, message: t('generalErrors.required') } })}
            readOnly={disableEdit}
            required
          />
          <KitPhone
            label={t('common:accountPage.phone')}
            code={contactInfo.phoneCode}
            phone={contactInfo.phone}
            updatePhone={updatePhone}
            updateCode={updatePhoneCode}
            errors={errors}
            countries={countries}
            readOnly={disableEdit}
            t={t}
            required
          />
          <KitPhone
            label={t('common:accountPage.cellPhone')}
            code={contactInfo.cellPhoneCode}
            phone={contactInfo.cellPhone}
            updatePhone={updateCellPhone}
            updateCode={updateCellPhoneCode}
            errors={{ phone: errors.cell_phone }}
            countries={countries}
            readOnly={disableEdit}
            t={t}
          />
          <KitPhone
            label={t('common:accountPage.fax')}
            code={contactInfo.faxCode}
            phone={contactInfo.fax}
            updatePhone={updateFax}
            updateCode={updateFaxCode}
            countries={countries}
            errors={{ phone: errors.fax }}
            readOnly={disableEdit}
            t={t}
          />
          <KitTextField
            error={errors.prefLang ? t('generalErrors.required') : ''}
            ref={register({ required: { value: true, message: t('generalErrors.required') } })}
            name='prefLang'
            select
            required
            onChange={onLangChange}
            value={contactInfo.prefLang ? contactInfo.prefLang.toString() : undefined}
            label={t('common:accountPage.preferredLanguage')}
          >
            {languages.map(language => (
              <option key={language.langId} value={language.langId}>
                {language.lang_local_name}
              </option>
            ))}
          </KitTextField>
          <KitTextField
            ref={register({ required: { value: true, message: t('generalErrors.required') } })}
            name='countryId'
            select
            required
            value={contactInfo.countryId ? contactInfo.countryId.toString() : undefined}
            onChange={onChangeCountry}
            error={errors.countryId ? t('generalErrors.required') : ''}
            label={t('common:accountPage.country')}
            readOnly={disableEdit}
          >
            <option value='' key='selectCountry'>
              {t('select')}
            </option>
            {countries.map(country => (
              <option key={country.id} value={country.id}>
                {country.name}
              </option>
            ))}
          </KitTextField>
          <KitTextField
            label={t(`common:accountPage.${getAddressLabelsByCountry('address1', countryCode)}`)}
            value={contactInfo.address1}
            type='text'
            name='address1'
            onChange={onChangeAddress1}
            error={errors && errors.address1 ? t('generalErrors.required') : ''}
            ref={register({ required: { value: true, message: t('generalErrors.required') } })}
            readOnly={disableEdit}
            required
          />
          <KitTextField
            label={t(`common:accountPage.${getAddressLabelsByCountry('address2', countryCode)}`)}
            value={contactInfo.address2}
            type='text'
            name='address2'
            onChange={onChangeAddress2}
            readOnly={disableEdit}
          />
          <KitTextField
            label={t('common:accountPage.city')}
            required
            value={contactInfo.city}
            error={errors.city ? errors.city.message : ''}
            ref={register({ required: { value: true, message: t('generalErrors.required') } })}
            type='text'
            name='city'
            onChange={onChangeCity}
            readOnly={disableEdit}
          />
          <KitFlexRowSpacedStyled>
            {addressFormatter(countryCode)
              .flat()
              .includes('state') ? (
              <KitTextField
                name='stateId'
                select
                readOnly={!states.length || disableEdit}
                required={Boolean(states.length)}
                onChange={onChangeState}
                error={
                  Boolean(states.length) && errors && errors.stateId ? errors.stateId.message : ''
                }
                ref={register({
                  required: { value: Boolean(states.length), message: t('generalErrors.required') }
                })}
                value={contactInfo.stateId ? contactInfo.stateId.toString() : undefined}
                label={t(`common:accountPage.${getAddressLabelsByCountry('state', countryCode)}`)}
              >
                <option value=''>{t('select')}</option>
                {states.map(state => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
              </KitTextField>
            ) : null}
            <KitTextField
              label={t(`common:accountPage.${getAddressLabelsByCountry('zipcode', countryCode)}`)}
              error={errors && errors.zipcode ? errors.zipcode.message : ''}
              ref={register({ required: { value: true, message: t('generalErrors.required') } })}
              required
              value={contactInfo.zipcode}
              type='text'
              name='zipcode'
              onChange={onChangeZip}
              readOnly={disableEdit}
            />
          </KitFlexRowSpacedStyled>
        </>
      ) : (
        <>
          <Field field='email' value={contactInfo.email} label={t('common:accountPage.email')} />
          <Field
            field='phone'
            value={
              contactInfo.phoneCode && contactInfo.phone
                ? `+${contactInfo.phoneCode} ${contactInfo.phone}`
                : '-'
            }
            label={t('common:accountPage.phone')}
          />
          <Field
            field='cellPhone'
            value={
              contactInfo.cellPhoneCode && contactInfo.cellPhone
                ? `+${contactInfo.cellPhoneCode} ${contactInfo.cellPhone}`
                : '-'
            }
            label={t('common:accountPage.cellPhone')}
          />
          <Field
            field='fax'
            value={
              contactInfo.faxCode && contactInfo.fax
                ? `+${contactInfo.faxCode} ${contactInfo.fax}`
                : '-'
            }
            label={t('common:accountPage.fax')}
          />
          <Styledlabel>{t('common:accountPage.preferredLanguage')}</Styledlabel>
          <StyledDiv>{contactInfo.language}</StyledDiv>
          <Address countryCode={countryCode} contactInfo={contactInfo} />
        </>
      )}
    </KitEditPanel>
  );
};

export default ContactInformation;
