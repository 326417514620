import React, { useEffect, useState } from 'react';
import { AccountService } from '@chargepoint/common/services/AccountService';
import { KitCard, KitSpinner } from '@chargepoint/cp-toolkit';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { StyledDiv, Styledlabel } from '../../pages/Account/styled-elements';

interface PrivilegesProps {
  userId: string | number;
}

const KitCardBodyStyled = styled(KitCard.Body)`
  position: relative;
`;

const Privileges = ({ userId }: PrivilegesProps) => {
  const { t } = useTranslation();
  const [role, setRole] = useState();
  const [permissions, setPermissions] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    (async () => {
      if (!userId) {
        return;
      }
      try {
        setShowSpinner(true);
        const userAccess = await AccountService.getUserAccessDetails(userId);
        setShowSpinner(false);
        setRole(userAccess.role);
        setPermissions(userAccess.access.join(', '));
      } catch (error) {
        // TODO BE error handling
      }
    })();
  }, [userId]);

  return (
    <KitCard>
      <KitCard.Header>{t('common:accountPage.privileges')}</KitCard.Header>
      <KitCardBodyStyled>
        {showSpinner && <KitSpinner size='s' />}
        <Styledlabel>{t('common:accountPage.role')}</Styledlabel>
        <StyledDiv>{role}</StyledDiv>
        <Styledlabel>{t('common:accountPage.permissions')}</Styledlabel>
        <StyledDiv>{permissions}</StyledDiv>
      </KitCardBodyStyled>
    </KitCard>
  );
};

export default Privileges;
