import React, { Component } from 'react';
import styles from './style.module.scss';


class EnglishOnly extends Component {
  render() {
    return (
      <span className={styles.EnglishOnly}>&nbsp;(EN)</span>
    );
  }
}

export default EnglishOnly;
