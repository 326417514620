import styled from 'styled-components';
import { ThemeConstants } from '@chargepoint/cp-toolkit';

export const Textarea = styled.textarea`
  background: ${({ readOnly, disabled, theme }) =>
    disabled || readOnly ? 'rgb(242, 242, 242)' : theme.page.body.bg};
  padding: ${ThemeConstants.spacing.absolute.s}px;
  outline: none;
  flex: 1 0 auto;
  border: 1px solid;
  border-color: ${({ readOnly, disabled, theme }) =>
    disabled || readOnly ? 'rgb(230, 230, 230)' : theme.button.primary.disabled};
  height: 50px;
  font-size: ${ThemeConstants.fontSize.text_14}rem;
  font-family: ${({ theme }) => theme.font.fontFamily};
`;
