import React, { Suspense, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { cpLightTheme, KitSpinner } from '@chargepoint/cp-toolkit';
import { SessionProvider, SessionContext } from '@chargepoint/common/hooks/SessionContext';
import { getLocaleName } from '@chargepoint/common/util/i18n';
import ReactModal from 'react-modal';
import { ThemeProvider } from 'styled-components';
import { RouteLayoutContextProvider } from './hooks/RouteLayoutContext';
import i18n from './Intl/i18n';
import PaymentMethodsPagelet from './pages/Organizations/PaymentMethodsPagelet';

if (document.getElementById('php-react-app-root')) {
  ReactModal.setAppElement('#php-react-app-root');
}

function LocalizationController() {
  const { session, updateSession } = useContext(SessionContext);
  const init = () => {
    document.addEventListener('reload-react-locale', () => {
      updateSession();
    });
    return () => {
      document.removeEventListener('reload-react-locale', () => {
        updateSession();
      });
    };
  };

  if (window.sessionStorage.getItem('react-reset') === 'locale') {
    window.sessionStorage.removeItem('react-reset');
    updateSession();
  }

  useEffect(init, []);

  useEffect(() => {
    let langLocale = '';
    if (session.prefLangLocale) {
      langLocale = session.prefLangLocale;
    }

    langLocale = getLocaleName(langLocale);
    if (i18n.language !== langLocale) {
      i18n.changeLanguage(langLocale);
    }
  }, [session.prefLangLocale]);

  return null;
}

function App() {
  return (
    <ThemeProvider theme={cpLightTheme}>
      <SessionProvider>
        <LocalizationController />
        <RouteLayoutContextProvider>
          <Suspense fallback={<KitSpinner withOverlay />}>
            <Router>
              <Routes>
                <Route
                  path='/index.php/organization/Vieworganization/:orgId'
                  element={<PaymentMethodsPagelet />}
                />
                </Routes>
            </Router>
          </Suspense>
        </RouteLayoutContextProvider>
      </SessionProvider>
    </ThemeProvider>
  );
}

export default App;
