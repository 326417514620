import React, { Component, Fragment } from 'react';
import EnglishOnly from '../EnglishOnly'
import { Translation } from 'react-i18next';
import styles from './style.module.scss';


interface VideoLinkProps {
  englishOnly?: boolean,
  link: string
}

class VideoLink extends Component<VideoLinkProps> {
  render() {
    return (
      <Translation>
        {t => (
          <Fragment>
            <a className={styles.VideoLink} href={this.props.link} target='_blank' rel='noopener noreferrer'>
              {t('link_video')}
              <img src={`https://${process.env.REACT_APP_DOMAIN}/images/icon_mov.gif`} alt={t('link_video')}/>
            </a>
            {this.props.englishOnly && <EnglishOnly />}
          </Fragment>
        )}
      </Translation>
    );
  }
}

export default VideoLink;
