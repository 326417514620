import React, { Fragment } from 'react';
import { PhoneNumberPicker } from '../../Components/PhoneNumberPicker';
import styles from '../../Main/main.module.scss';
import { useTranslation } from 'react-i18next';
import { PaymentInfoSectionProps } from './paymentinfo';

const PaymentInfoSection = (props: PaymentInfoSectionProps) => {
  const { t } = useTranslation();
  const updateFormProp = async (obj: any, key: string, value: any) => {
    obj[key] = value;
    props.onChange('paymentInfo', props.paymentInfo);
  };
  const updatePhoneNumberPicker = (countryPhone: { countryCode: string; phone: string; }) => {
    updateFormProp(props.paymentInfo.accountReceivableContact, 'phone', countryPhone.phone);
    updateFormProp(props.paymentInfo.accountReceivableContact, 'countryDialCode', countryPhone.countryCode);
  };
  const currency = props.paymentInfo.currency ? props.paymentInfo.currency : t('currencyFullName.' + props.paymentInfo.currencyCode);

  return (
    <Fragment>
      <h3 className={props.hide ? styles.hide : styles.required}>{t('paymentInfo.title_paymentinfo')}</h3>
      <div className={props.hide ? styles.hide : 'flex-form-section'}>
        <div className={styles.row}>
          <div className={styles.formGroup}>
            <p>
              <label>
                {t('paymentInfo.paragraph_currency')}
              </label>
              <label className={styles.largeLabel}>{currency}</label>
            </p>
          </div>
        </div>
      </div>

      <div className={props.hide ? styles.hide : 'flex-form-section'}>
        <div className={styles.row}>
          <div className={styles.formGroup}>
            <label htmlFor='accountReceivableName'>
              {t('paymentInfo.label_contact_name')}
            </label>
            <input
              type='text'
              name='accountReceivableName'
              id='accountReceivableName'
              value={props.paymentInfo.accountReceivableContact.name}
              onChange={evt => {
                updateFormProp(
                  props.paymentInfo.accountReceivableContact,
                  'name',
                  evt.target.value
                );
              }}
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor='accountReceivableEmail'>
              {t('paymentInfo.label_contact_email')}
            </label>
            <input
              type='text'
              name='accountReceivableEmail'
              id='accountReceivableEmail'
              value={props.paymentInfo.accountReceivableContact.email}
              onChange={evt => {
                updateFormProp(
                  props.paymentInfo.accountReceivableContact,
                  'email',
                  evt.target.value
                );
              }}
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.formGroup}>
            <label htmlFor='accountContactPhone'>
              {t('paymentInfo.label_contactphone')}
            </label>
            <PhoneNumberPicker
              name='accountContactPhone'
              phone={props.paymentInfo.accountReceivableContact.phone}
              countryCode={props.paymentInfo.accountReceivableContact.countryDialCode}
              width='250px'
              onUpdate={updatePhoneNumberPicker}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PaymentInfoSection;
