import {
  KitModal,
  KitForm,
  KitButton,
  KitButtonBar,
  KitModalSize,
} from '@chargepoint/cp-toolkit';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AdminReceiptPagelet from '../../receipts/AdminReceiptPagelet';

const StyledKitFormGroup = styled(KitForm.Group)`
  padding: 16px;
`

const DeveloperFlexBillingWrapper = () => {
  const [orgId, setOrgId] = useState('');
  const [showForm, setShowForm] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div>
      <p>FlexBilling</p>
      <StyledKitFormGroup>
      <KitForm.Input
        id="OrgId"
        label="Org ID"
        value={orgId}
        onChange={(e) => { 
          navigate(`/r/admin/organization/Vieworganization/${e.target.value}`);
          setOrgId(e.target.value)
        }}
      />
      </StyledKitFormGroup>
      <KitButtonBar
        primary={
          <KitButton onClick={() => setShowForm(true)}>
            Show Form
          </KitButton>
        }
        secondary={''}
      />
      <KitModal
        show={showForm}
        onHide={() => setShowForm(false)}
        size={KitModalSize.md}
        closeOnClickOutside
      >
        <KitModal.Header closeButton t={t}>
          <KitModal.Title>Receipt</KitModal.Title>
        </KitModal.Header>
        <KitModal.Body>
          <AdminReceiptPagelet vehicleChargeId={orgId} />
        </KitModal.Body>
      </KitModal>
    </div>
  );
};

export default DeveloperFlexBillingWrapper;
